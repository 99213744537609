import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleRight, faTrash } from '@fortawesome/free-solid-svg-icons'
import { clone, equals } from 'ramda'
import React, { useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'

import {
  addOrUpdateMatchInfo,
  MinimalPlaylistUpdateInfo,
} from '../../../actions/social/leaderboard-actions'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectBlasterPeer } from '../../../selectors/blaster-peer-selectors'
import { selectMatchTracks } from '../../../selectors/match-selectors'
import { MatchInfo } from '../../../types'
import { getTrackArtistAndTitle } from '../../../util/track-utils'
import PlaylistModal from '../PlaylistModal'

type Props = {
  currUserId: string
  matchOwner: string
  workingMatchSlug: string
  matchInfo: MatchInfo
  isOwnedMatch: boolean
}
type ManagedTrackSetInfo = {
  id: string
  setSlug: string
  title: string
  setIndex: number
  tracks: ManagedTrackInfo[]
  isExpanded: boolean
}
type ManagedTrackInfo = {
  id: string
  title: string
  artist: string
  setIndex: number
  trackSlug: string
}
export const MatchTracks = ({
  isOwnedMatch,
  currUserId,
  matchOwner,
  matchInfo,
  workingMatchSlug,
}: Props) => {
  const dispatch = useAppDispatch()
  const isOwner = currUserId === matchOwner
  const compoundMatchSlug = `${currUserId}/${workingMatchSlug}`
  const matchTracksBySet = useAppSelector(selectMatchTracks(compoundMatchSlug))
  const { allTracksPlaylistSlug } = useAppSelector(selectBlasterPeer(matchOwner))
  const [managedTrackSets, setManagedTrackSets] = useState<ManagedTrackSetInfo[]>([])
  const [isTrackSetsInited, setIsTrackSetsInited] = useState(false)
  const [trackSetUpdates, setTrackSetUpdates] = useState<MinimalPlaylistUpdateInfo[] | null>(null)
  const [selectedTracksetInfo, setSelectedTracksetInfo] = useState<{
    slug: string
    title: string
    tracks: string[]
    index: number
  } | null>(null)
  const [isDirty, setIsDirty] = useState(false)
  const onCloseTrackSetModal = (isUpdated?: boolean) => {
    setIsTrackSetsInited(!isUpdated)
    setSelectedTracksetInfo(null)
  }

  useEffect(() => {
    if (isTrackSetsInited) {
      return
    }
    const managedSets: ManagedTrackSetInfo[] = []
    matchTracksBySet.forEach((setInfo, index) => {
      const { slug: setSlug, title: setTitle, tracks } = setInfo
      const trackItems = tracks.map((trackInfo) => {
        const { slug } = trackInfo
        const { title, artist } = getTrackArtistAndTitle(trackInfo)
        return {
          id: `track-${setSlug}-${slug}`,
          title,
          artist,
          setIndex: index,
          trackSlug: slug,
        }
      })
      const setItem = {
        id: `set-${setSlug}`,
        title: setTitle,
        setIndex: index,
        setSlug,
        tracks: trackItems,
        isExpanded: false,
      }
      managedSets.push(setItem)
    })
    setManagedTrackSets(managedSets)
    setIsTrackSetsInited(true)
    setTrackSetUpdates(null)
  }, [matchTracksBySet, isTrackSetsInited, isOwnedMatch])

  useEffect(() => {
    if (!isTrackSetsInited) {
      return
    }
    const newPlaylistInfo: MinimalPlaylistUpdateInfo[] = []
    managedTrackSets.forEach((trackset) => {
      const { tracks, title, setSlug } = trackset
      const trackOrder = tracks.map(({ trackSlug }) => trackSlug)
      newPlaylistInfo.push({
        slug: setSlug,
        title,
        trackOrder,
      })
    })

    setTrackSetUpdates((currPlaylistUpdates) => {
      const newIsDirty = !!currPlaylistUpdates && !equals(currPlaylistUpdates, newPlaylistInfo)
      setIsDirty(newIsDirty)
      return newIsDirty || !currPlaylistUpdates ? newPlaylistInfo : currPlaylistUpdates
    })
  }, [isTrackSetsInited, managedTrackSets])

  const getTrackSets = () => {
    const getSet = (setInfo: ManagedTrackSetInfo, setIndex: number) => {
      const { id, title, tracks, setSlug, isExpanded } = setInfo
      const onToggleExpanded = (event: React.MouseEvent<HTMLButtonElement>) => {
        setManagedTrackSets((currManagedSets: ManagedTrackSetInfo[]) => {
          const updatedSets = clone(currManagedSets)
          updatedSets[setIndex].isExpanded = !isExpanded
          return updatedSets
        })
        event.preventDefault() // otherwise checkbox will get onChange, too!
      }
      const onSetClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const tracks = matchTracksBySet[setIndex].tracks.map(({ slug }) => slug)
        setSelectedTracksetInfo({ slug: setSlug, index: setIndex, tracks, title })
        event.preventDefault() // otherwise list will get onChange
      }
      const setTracks = (newTracks: ManagedTrackInfo[]) => {
        setManagedTrackSets((prevSets) => {
          const newSets = [...prevSets]
          newSets[setIndex].tracks = newTracks
          return newSets
        })
      }
      const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setManagedTrackSets((currManagedSets: ManagedTrackSetInfo[]) => {
          const updatedSets = clone(currManagedSets)
          updatedSets.splice(setIndex, 1)
          return updatedSets
        })
        event.preventDefault()
      }
      const allowDelete = setSlug !== allTracksPlaylistSlug
      const getTrack = (roundTrackInfo: ManagedTrackInfo) => {
        const { id, title, artist, trackSlug, setIndex } = roundTrackInfo
        const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setManagedTrackSets((currManagedSets: ManagedTrackSetInfo[]) => {
            const { tracks } = currManagedSets[setIndex]
            const trackIndex = tracks.findIndex(
              ({ trackSlug: existingTrackSlug }) => existingTrackSlug === trackSlug
            )
            if (trackIndex >= 0) {
              tracks.splice(trackIndex, 1)
            }
            const updatedSets = clone(currManagedSets)
            updatedSets[setIndex].tracks = tracks
            return updatedSets
          })
          event.stopPropagation()
        }
        return (
          <div key={id} data-id={id} className="sortable-list-item handle">
            <label className="handle track">
              <div>{title}</div>
              <div className="artist">{artist}</div>
            </label>
            <button
              className="trash"
              onClick={onDeleteClick}
              title="remove from Set"
              disabled={!allowDelete}
            >
              <FontAwesomeIcon size="xs" icon={faTrash as IconProp} />
            </button>
          </div>
        )
      }
      const expander = isExpanded ? faAngleDown : faAngleRight
      return (
        <div key={id} data-id={id} className="sortable-list-item handle playlistDisplay2">
          <div className="playlistTitle">
            <button className="trash" onClick={onToggleExpanded}>
              <FontAwesomeIcon icon={expander as IconProp} />
            </button>
            <div onClick={onSetClick}>{title}</div>
            <button
              className="trash"
              onClick={onDeleteClick}
              title="remove from Match"
              disabled={!allowDelete}
            >
              <FontAwesomeIcon size="xs" icon={faTrash as IconProp} />
            </button>
          </div>
          {isExpanded && (
            <ReactSortable
              className="trackDisplay2"
              animation="150"
              list={managedTrackSets[setIndex].tracks}
              setList={setTracks}
              group="nested"
            >
              {tracks.map(getTrack)}
            </ReactSortable>
          )}
        </div>
      )
    }
    const setList = (updatedSets: ManagedTrackSetInfo[]) => {
      setManagedTrackSets(updatedSets)
    }
    return (
      <>
        <div>
          <label>Sets & Tracks:</label>
          <span className="help">(drag to re-order)</span>
        </div>
        {getButtons()}
        <ReactSortable
          className="playlistDisplay"
          animation="150"
          list={managedTrackSets}
          setList={setList}
          group="nested"
        >
          {managedTrackSets.map(getSet)}
        </ReactSortable>
      </>
    )
  }

  const onNewTracksetClick = () => {
    setSelectedTracksetInfo({ slug: '', title: 'Round 0', tracks: [], index: 0 })
  }
  const onAddOrUpdateMatch = () => {
    if (trackSetUpdates) {
      dispatch(addOrUpdateMatchInfo({ matchInfo, playlistInfo: trackSetUpdates }))
      setIsDirty(false)
    }
  }
  const getButtons = () => {
    return (
      <div className="buttons">
        <button onClick={onNewTracksetClick}>New Set...</button>
        <button onClick={onAddOrUpdateMatch} disabled={!isDirty}>
          Update
        </button>
      </div>
    )
  }
  const getPlaylistModal = () => {
    if (!selectedTracksetInfo) {
      return false
    }
    const { slug, title, tracks } = selectedTracksetInfo
    return (
      <PlaylistModal
        matchSlug={workingMatchSlug}
        playlistSlug={slug}
        playlistTitle={title}
        workingTrackOrder={tracks}
        onClose={onCloseTrackSetModal}
      />
    )
  }

  return (
    <>
      {isOwner && getTrackSets()}
      {getPlaylistModal()}
    </>
  )
}
