import { createAsyncThunk } from '@reduxjs/toolkit'

import currentPlaySlice from '../../reducers/currentPlaySlice'
import player from '../../services/Player'
import { AppDispatch, RootState } from '../../reducers'
const imageUrlChanged = createAsyncThunk<void, string, { state: RootState; dispatch: AppDispatch }>(
  'load/imageUrlChanged',
  (newImageURL, { dispatch }) => {
    //   // TODO: validate URL, allow clearing...
    //   dispatch(setAudio({ audioUrl: newAudioURL })).then(() => {
    //     dispatch(clearLyricTiming())
    //   })
    //   dispatch(localAudioSwitched({ remoteURL: newAudioURL }))
    //   // this.dirtyAudio();
  }
)

type DesktopInfo = {
  fileBuffer: Buffer
  filePath: string
  fileName: string
}
const loadLocalImage = createAsyncThunk<
  void,
  { desktopInfo?: DesktopInfo; imageFile: File },
  { state: RootState; dispatch: AppDispatch }
>('load/localImage', ({ desktopInfo, imageFile }, { dispatch }) => {
  const setAndUpdate = async ({
    dataURL,
    fileName,
    filePath,
  }: {
    dataURL: ArrayBuffer | string | null
    fileName: string
    filePath?: string
  }) => {
    dispatch(currentPlaySlice.actions.setLocalImagePath(fileName))
    dispatch(currentPlaySlice.actions.setLocalImageData(dataURL))
    dispatch(currentPlaySlice.actions.setIsImageDirty(true))
    dispatch(currentPlaySlice.actions.setIsInfoDirty(true))
  }

  if (desktopInfo) {
    const { fileBuffer, filePath, fileName } = desktopInfo
    setAndUpdate({ dataURL: fileBuffer, fileName, filePath })
    // app.audioFileInput.value = filePath; // TODO
  } else {
    if (!imageFile) {
      return // none selected // TODO: is this a way to clear?
    }
    const reader = new FileReader()
    player.userInputImageFile = imageFile
    reader.onload = function (event: ProgressEvent<FileReader>) {
      if (event.target) {
        const dataURL = event.target.result
        setAndUpdate({ dataURL, fileName: imageFile.name })
      }
    }
    reader.readAsDataURL(imageFile)
  }
})

export { imageUrlChanged, loadLocalImage }
