import { Line, Section, Word } from '../../types'

class LyricVisitorBase {
  startTrack() {}
  endTrack() {}
  startSection(section: Section) {}
  endSection(section: Section) {}
  startLine(line: Line, section: Section) {}
  endLine(line: Line, section: Section) {}
  visitWord(word: Word, line: Line, section: Section) {}
}

class LyricVisitorBaseSimple {
  visitTrack(isStart: boolean) {}
  visitSection(section: Section) {}
  visitLine(line: Line, section: Section) {}
  visitWord(word: Word, line: Line, section: Section) {}

  traverse(sections: Section[]) {
    this.visitTrack(true)

    sections.forEach((section) => {
      this.visitSection(section)

      section.lines.forEach((line: Line) => {
        this.visitLine(line, section)

        line.words.forEach((word) => {
          this.visitWord(word, line, section)
        })
      })
    })

    this.visitTrack(false)
  }
}

export default LyricVisitorBase
export { LyricVisitorBaseSimple }
