import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './index'

export interface SoundStatus {
  soundId: string
  isPlaying: boolean
  isInitialized: boolean
}
export interface SoundStatusMap {
  [key: string]: SoundStatus
}
const initialState: SoundStatusMap = {}

const soundStatusSlice = createSlice({
  name: 'soundStatus',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<string>) => {
      const soundId = action.payload
      const newStatus = {
        soundId,
        isInitialized: true,
        isPlaying: false,
      }
      return {
        ...state,
        [soundId]: newStatus,
      }
    },
    play: (state, action: PayloadAction<string>) => {
      const soundId = action.payload
      const currStatus = state[soundId]
      if (!currStatus) {
        return state
      }
      return {
        ...state,
        [soundId]: {
          ...currStatus,
          isPlaying: true,
        },
      }
    },
    stop: (state, action: PayloadAction<string>) => {
      const soundId = action.payload
      const currStatus = state[soundId]
      if (!currStatus) {
        return state
      }
      return {
        ...state,
        [soundId]: {
          ...currStatus,
          isPlaying: false,
        },
      }
    },
  },
})

export const selectSoundStatus = (state: RootState) => state.soundStatus

export default soundStatusSlice
