import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../reducers'
import sessionSlice from '../reducers/sessionSlice'
import settingsSlice from '../reducers/settingsSlice'
import player from '../services/Player'
import { ModeType } from '../types'

const switchUserMode = createAsyncThunk<
  void,
  ModeType,
  { state: RootState; dispatch: AppDispatch }
>('playAction/switchUserMode', (newMode, { dispatch, getState }) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(sessionSlice.actions.setMode(newMode))
    dispatch(settingsSlice.actions.setIsShowLyrics(true))
    dispatch(settingsSlice.actions.setIsVisualPulse(newMode === 'viz'))
    dispatch(settingsSlice.actions.setIsAudioPulse(false))
    dispatch(sessionSlice.actions.setIsShowSettings(false))
    player.switchMode(newMode)
    resolve()
  })
})

export default switchUserMode
