import { NODE_ASSETBASE } from '../constants/environment'
import { MinimalTrackInfo } from '../types'

let _isMobile: boolean | undefined
const isMobile = (): boolean => {
  if (_isMobile === undefined) {
    const isAndroid = !!navigator.userAgent.match(/android/i)
    const isiPhoneOriPod = !!navigator.platform.match(/i(Phone|Pod)/i)
    const isiPad = !!navigator.platform.match(/iPad/i)
    _isMobile = isAndroid || isiPhoneOriPod || isiPad
  }
  return _isMobile
}

const isDesktop = () => {
  return window.process && window.process.versions.electron
}

const userAssetsBase = () => {
  return `${NODE_ASSETBASE}users`
}

const audioAssetsBase = () => {
  return `${NODE_ASSETBASE}tracks`
}

const imageAssetsBase = () => {
  return `${NODE_ASSETBASE}images`
}

const getSummaryTitle = ({ artist, title }: { artist: string; title: string }) => {
  if (artist && title) {
    return `${artist} | ${title}`
  }
  return title || artist || '[unknown]'
}

const getTrackArtistAndTitle = (trackInfo?: MinimalTrackInfo) => {
  const { artist: trackArtist, title: trackTitle } = trackInfo || { artist: '', title: '' }
  let title = trackTitle
  let artist = trackArtist
  if (!artist?.length) {
    const [titleArtist = '[]', titleTitle = '[]'] = trackTitle.split(' | ')
    artist = titleArtist
    title = titleTitle
  }
  return { artist, title }
}

const getSpotifyUriFromTrackInfo = ({ links = '' }) => {
  const captureUriRegexp = /https:\/\/open.spotify.com\/track\/(.*)/i
  const linksArray = links.split(' ') || []
  for (let i = 0; i < linksArray.length; i++) {
    const matches = linksArray[i].match(captureUriRegexp)
    if (matches && matches.length > 1) {
      return `spotify:track:${matches[1]}`
    }
  }
}

const getExtensionForFileType = (fileType: string) => {
  let extension
  switch (fileType) {
    case 'text/plain':
      extension = 'txt'
      break
    case 'text/json':
      extension = 'json'
      break
    case 'text/lrc':
      extension = 'lrc'
      break
    case 'audio/mpeg':
    case 'audio/mp3':
      extension = 'mp3'
      break
    case 'audio/x-m4a':
      extension = 'm4a'
      break
    case 'image/png':
      extension = 'png'
      break
    default:
      if (fileType) {
        console.warn(`getExtensionForFileType: ${fileType} unsupported`)
      }
      extension = ''
  }
  return extension
}

const utcDateAsPath = (date = new Date()) => {
  const year = date.getUTCFullYear()
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = (date.getUTCDate() + 0).toString().padStart(2, '0')
  return `${year}/${month}/${day}`
}

const ownerForPath = (owner: string) => owner.split('@')[1]

const constructAudioUrl = ({
  timestamp,
  owner,
  slug,
  type,
}: {
  timestamp: number
  owner: string
  slug: string
  type: string
}) => {
  return `${audioAssetsBase()}/${utcDateAsPath(new Date(timestamp))}/${ownerForPath(
    owner
  )}-${slug}.${type}`
}

const constructImageUrl = ({
  timestamp,
  owner,
  slug,
  type,
}: {
  timestamp: number
  owner: string
  slug: string
  type: string
}) => {
  return `${imageAssetsBase()}/${utcDateAsPath(new Date(timestamp))}/${ownerForPath(
    owner
  )}-${slug}.${type}`
}

export {
  constructAudioUrl,
  constructImageUrl,
  getExtensionForFileType,
  getSummaryTitle,
  getSpotifyUriFromTrackInfo,
  getTrackArtistAndTitle,
  isDesktop,
  isMobile,
  ownerForPath,
  userAssetsBase,
  utcDateAsPath,
}
