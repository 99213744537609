import * as Tone from 'tone'

class NullAudioPlayer {
  get trackDuration() {
    return 0
  }
  get volume() {
    return 0
  }
  set volume(value) {}
  start(startAt: number) {}
  stop() {}
  seek(seconds: number) {}
}

class ToneAudioPlayer extends NullAudioPlayer {
  output: Tone.Gain
  player: Tone.Player
  constructor(buffer: Tone.ToneAudioBuffer) {
    super()
    this.output = new Tone.Gain().toDestination()
    this.player = new Tone.Player(buffer).connect(this.output)
  }
  get volume() {
    return Math.round(this.output.gain.value * 10)
  }
  set volume(value) {
    const normalizedValue = Math.max(0, Math.min(value, 10))
    this.output.gain.value = normalizedValue / 10
  }
  get trackDuration() {
    return this.player.buffer ? this.player.buffer.duration : 0
  }
  start(startAt: number) {
    if (this.player.buffer) {
      this.player.start(Tone.now(), startAt)
    }
    return Promise.resolve()
  }
  stop() {
    this.player.stop()
    return Promise.resolve()
  }
  seek(seconds: number) {
    this.player.seek(seconds)
    return Promise.resolve()
  }
}

class SpotifyAudioPlayer extends NullAudioPlayer {
  player: Spotify.Player
  _trackDuration: number

  constructor(spotifyPlayer: Spotify.Player) {
    super()
    this.player = spotifyPlayer
    this._trackDuration = 0
  }
  get trackDuration() {
    return this._trackDuration
  }
  set trackDuration(duration) {
    this._trackDuration = duration
  }
  start(startAt: number) {
    return this.player.resume()
  }
  stop() {
    return this.player.pause()
  }
  seek(seconds: number) {
    return this.player.seek(seconds * 1000)
  }
}

export { NullAudioPlayer, ToneAudioPlayer, SpotifyAudioPlayer }
