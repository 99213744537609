import Gamepad, { MESSAGES } from './Gamepad'

const isGamepadSupported = () =>
  navigator.getGamepads && typeof navigator.getGamepads === 'function'

class GamepadService {
  _gamepads: Gamepad[]
  axeThreshold = [1.0] // this is an array so it can be expanded without breaking in the future
  isReady = isGamepadSupported()
  onConnect = function (gamepad: Gamepad) {}
  onDisconnect = function (gamepadIndex: number) {}
  onBeforeCycle = function () {}
  onAfterCycle = function () {}
  constructor() {
    this._gamepads = []
    this.init()
  }
  get gamepads() {
    return this._gamepads
  }
  // setProperty(property: string, value: string | number) {
  //   const properties = ['axeThreshold']
  //   if (properties.indexOf(property) >= 0) {
  //     if (property === 'axeThreshold' && (!parseFloat(value) || value < 0.0 || value > 1.0)) {
  //       console.error(MESSAGES.INVALID_VALUE_NUMBER)
  //       return
  //     }
  //
  //     this[property] = value
  //
  //     if (property === 'axeThreshold') {
  //       const gps = this.getGamepads()
  //       const ids = Object.keys(gps)
  //       for (let x = 0; x < ids.length; x++) {
  //         gps[ids[x]].set('axeThreshold', this.axeThreshold)
  //       }
  //     }
  //   } else {
  //     console.error(MESSAGES.INVALID_PROPERTY)
  //   }
  // }
  checkStatus() {
    const requestAnimationFrame = window.requestAnimationFrame
    this.onBeforeCycle()
    this.gamepads.forEach((gamepad: Gamepad) => {
      gamepad.checkStatus()
    })
    this.onAfterCycle()

    if (this.gamepads.length > 0) {
      requestAnimationFrame(this.checkStatus.bind(this))
    }
  }
  init() {
    window.addEventListener('gamepadconnected', (e) => {
      const rawGamepad = e.gamepad
      console.log(MESSAGES.ON)
      if (rawGamepad) {
        const gamepad = new Gamepad(rawGamepad)
        // gamepad.set('axeThreshold', this.axeThreshold)
        this.onConnect(gamepad)
        this._gamepads[rawGamepad.index] = gamepad
      }
      if (this.gamepads.length >= 1) {
        this.checkStatus()
      }
    })
    window.addEventListener('gamepaddisconnected', (e) => {
      const rawGamepad = e.gamepad
      console.log(MESSAGES.OFF)
      if (rawGamepad) {
        this.onDisconnect(rawGamepad.index)
        delete this._gamepads[rawGamepad.index]
      }
    })
  }
  on(eventName: string, callback: any) {
    switch (eventName) {
      case 'connect':
        this.onConnect = callback
        break
      case 'disconnect':
        this.onDisconnect = callback
        break
      case 'beforeCycle':
      case 'beforecycle':
        this.onBeforeCycle = callback
        break
      case 'afterCycle':
      case 'aftercycle':
        this.onAfterCycle = callback
        break
      default:
        console.error(MESSAGES.UNKNOWN_EVENT)
        break
    }
    return this
  }
  off(eventName: string) {
    switch (eventName) {
      case 'connect':
        this.onConnect = function () {}
        break
      case 'disconnect':
        this.onDisconnect = function () {}
        break
      case 'beforeCycle':
      case 'beforecycle':
        this.onBeforeCycle = function () {}
        break
      case 'afterCycle':
      case 'aftercycle':
        this.onAfterCycle = function () {}
        break
      default:
        console.error(MESSAGES.UNKNOWN_EVENT)
        break
    }
  }
}

export default GamepadService
