import TextDiff from 'text-diff'

import { getCompactLyrics } from '../lyrics/traverse-lyrics'
import Util from '../util'
import TimeSpliceVisitor from './TimeSpliceVisitor'

class SongModelTranscriber {
  constructor(toSections, fromSections) {
    this.toSections = toSections
    this.fromSections = fromSections
  }

  /*
    "I love everything you do" -->
         "I love nothing you do"     : [ ="I love "       -"every"       +"no",           ="thing you do "  ] --> [ ="I love "  *"nothing",    =" you do"            ]
         "I love everyone you do"    : [ ="I love every"  -"thing"       +"one",          =" you do "       ] --> [ ="I love "  *"everyone",   =" you do"            ]
         "I love every you do"       : [ ="I love every"  -"thing"       ="you do",                         ] --> [ ="I love "  *"every",      =" you do"            ]
         "I love thing you do"       : [ ="I love "       -"every"       ="thing you do"                    ] --> [ ="I love "  *"thing",      =" you do"            ]
         "I love things you do"      : [ ="I love "       -"every"       ="thing"  +"s"   =" you do"        ] --> [ ="I love "  *"things"      =" you do"            ]
         "I love that thing you do"  : [ ="I love "       +"that"        -"every"         =" thing you do " ] --> [ ="I love "  +"that"        =" thing you do"      ]
         "I love you do"             : [ ="I love "       -"everything " ="you do"                          ] === [ ="I love "      -"everything " ="you do"       ]
         "I love you too"            : [ ="I love "       -"everything " ="you "          -"d" +"to" ="o "  ] --> [ ="I love "      -"everything " ="you "  *"too" ]
         "I love every thing you do"   : [ ="I love every"  +" "           ="thing you do "                 ] --> [ *"I love every" +" thing"      =" you do"      ]
         "I love everything you don't" : [ ="I love everything you do"   +"n't"                             ] --> [ ="I love everything you"       *"don't"      ]

     "Feeling you holding me tight" -->
         "Feelers in the night"     : [ ="Feel" -"ing you holding me t" +"ers in the n"        ="ight "    ] --> [ *"Feelers "     -"you "       *"in the night" ]
         "Feeding you alright"      : [ ="Fee"  -"l +"d" ="ing you "    -"holding me t" +"alr" ="ight "    ] --> [ *"Feeding you " -"holding me" *"alright"      ]

     "Super Sparkling Cold Brew" -->
         "Super SparklingCold Brew"     : [ ="Super Sparkling" -" " ="Cold Brew" ] --> [ ="Super " *"SparklingCold " ="Brew" ]
     */
  restrictDiffsToWordBoundaries(diffs) {
    let newDiffs = []
    let boundaryFragment = ''

    for (let i = 0; i < diffs.length; i++) {
      const currDiff = diffs[i]
      const currDiffLastWordIsComplete =
        currDiff[1].endsWith(' ') || currDiff[1].endsWith('\n') || i === diffs.length - 1
      const diffType = currDiff[0]
      const diffWords = currDiff[1].trim().split(' ')

      const nextDiff = i + 1 < diffs.length ? diffs[i + 1] : null
      const nextDiffFirstWordIsComplete = nextDiff
        ? nextDiff[1].startsWith(' ') || nextDiff[1].startsWith('\n')
        : false // TODO: make sure it's an insert? ??
      const nextDiffType = nextDiff ? nextDiff[0] : 0
      const nextDiffWords = nextDiff ? nextDiff[1].trim().split(' ') : []

      if (currDiffLastWordIsComplete) {
        if (!boundaryFragment.length) {
          if (!nextDiffFirstWordIsComplete && diffType === 1) {
            const insertionFragment = diffWords.join(' ') + nextDiffWords.splice(0, 1)[0] // cut and return first word
            const insertionDiff = [1, insertionFragment]
            newDiffs.push(insertionDiff)

            const frontSnippedDiff = [0, nextDiffWords.join(' ')]
            newDiffs.push(frontSnippedDiff)
            i++ // handled next diff
            continue
          }
          newDiffs.push(currDiff)
          continue
        } else if (diffType === 0) {
          // check others?
          const completedFragment = boundaryFragment + diffWords.splice(0, 1)[0] // cut and return first word
          const inPlaceSwapDiff = [0 /*2*/, completedFragment]
          newDiffs.push(inPlaceSwapDiff)

          const frontSnippedDiff = [0, diffWords.join(' ')]
          newDiffs.push(frontSnippedDiff)
          boundaryFragment = ''
          continue
        } else if (diffType === 1) {
        } else {
          continue
        }
      }

      if (!currDiffLastWordIsComplete && !nextDiffFirstWordIsComplete) {
        if (diffType === 0 /* && nextDiffType == -1 */) {
          boundaryFragment = diffWords.splice(diffWords.length - 1, 1)[0] // cut and return last word
          const backSnippedDiff = [0, diffWords.join(' ')]
          newDiffs.push(backSnippedDiff)
          continue
        }
        if (diffType === -1 && nextDiff) {
          boundaryFragment += nextDiffWords[0]
          const inPlaceSwapDiff = [0 /*2*/, boundaryFragment]
          newDiffs.push(inPlaceSwapDiff)
          boundaryFragment = ''
          continue
        }
        if (diffType === 1 && nextDiffType === 0 && boundaryFragment.length) {
          continue
        }
      } else {
        if (boundaryFragment.length) {
          const inPlaceSwapDiff = [0 /*2*/, boundaryFragment]
          newDiffs.push(inPlaceSwapDiff)
          boundaryFragment = ''
          continue
        }
        if (diffType === 0 && nextDiffType === -1) {
          boundaryFragment = diffWords.splice(diffWords.length - 1, 1)[0] // cut and return last word
          const backSnippedDiff = [0, diffWords.join(' ')]
          newDiffs.push(backSnippedDiff)
          continue
        }
        if (diffType === 0 && nextDiffType === 1) {
          const inPlaceSwapDiff = [0 /*2*/, diffWords.join(' ')]
          newDiffs.push(inPlaceSwapDiff)
          continue
        }
      }
    }

    return newDiffs
  }

  _simplifyDiffs(targetWords, diffs) {
    let simpleDiffs = []
    let targetIndex = -1
    let lastWordFragment = null

    for (let i = 0; i < diffs.length; i++) {
      const currDiff = diffs[i]

      const currDiffEndsWithSpace = currDiff[1].endsWith(' ')
      const nextDiffStartsWithSpace = i + 1 < diffs.length && diffs[i + 1][1].startsWith(' ') // TODO: make sure it's an insert?
      const diffWords = currDiff[1].trim().split(' ')

      if (currDiffEndsWithSpace || nextDiffStartsWithSpace) {
        lastWordFragment = null
      } else {
        lastWordFragment = diffWords.splice(diffWords.length - 1, 1)[0] // don't include word-fragment in this diff
        currDiff[1] = diffWords.join(' ')
      }
      const diffWordCount = diffWords.length

      if (currDiff[0] === -1) {
        if (diffWordCount > 0) {
          simpleDiffs.push(currDiff)
        }
        continue
      }

      let lastDiffWord = diffWords[diffWordCount - 1]
      let targetWord

      let okToPush
      if (diffWordCount === 1 && !lastDiffWord.length && currDiff[0] === 1) {
        okToPush = true
      } else {
        let isFirst = true
        for (let j = 0; j < diffWordCount; j++) {
          targetWord = targetWords[++targetIndex]
          if (targetWord.length === 0) {
            if (isFirst) {
              j--
            }
          } else {
            isFirst = false
          }
        }
        okToPush = targetWord === lastDiffWord
      }

      if (okToPush) {
        simpleDiffs.push(currDiff)
      } else {
        let replaced = false
        const replacementDiff = []
        if (currDiff[0] === 0) {
          simpleDiffs.push(currDiff) // need this, but last word not complete (TODO: replace last word?)
          replacementDiff.push(-1)
        } else {
          replacementDiff.push(1) // this is an insert frag we'll be replacing with complete insert
        }
        let missingWords = []

        while (!replaced && ++i < diffs.length) {
          const nextDiff = diffs[i]
          // const words = lyrics2.replace(/ \n /g,' ').trim(' ').split(' ');]
          if (nextDiff[0] === -1) {
            continue
          }
          const nextWords = nextDiff[1].trim().split(' ')
          const nextWord = nextWords[0]
          if (nextWord) {
            missingWords.push(nextWord)
            lastDiffWord += nextWord
            if (targetWord === lastDiffWord) {
              nextWords.shift()
              nextDiff[1] = nextWords.join(' ')
              if (nextDiff[1].length > 0) {
                i--
              }
              const replacement = replacementDiff[0] === -1 ? missingWords.join(' ') : lastDiffWord
              replacementDiff.push(replacement)
              simpleDiffs.push(replacementDiff)
              replaced = true
            }
          }
        }

        if (!replaced) {
          throw new Error(`could not match [${targetWord}]!`)
        }
      }

      if (i === diffs.length - 1 && lastWordFragment != null && simpleDiffs.length > 0) {
        simpleDiffs[simpleDiffs.length - 1][1] += ' ' + lastWordFragment
      }
    }

    return simpleDiffs
  }

  _getDiffs() {
    const sectionRegExpG = /\[[^[\]]*\]/g
    let lyrics1 = getCompactLyrics(this.fromSections).toLowerCase()
    let lyrics2 = getCompactLyrics(this.toSections).toLowerCase()
    lyrics1 = Util.stripPunctuationAndExtraSpaces(lyrics1)
    lyrics1 = lyrics1.replace(/\n/g, ' ')
    lyrics1 = lyrics1.replace(sectionRegExpG, '\n')
    lyrics2 = Util.stripPunctuationAndExtraSpaces(lyrics2)
    lyrics2 = lyrics2.replace(/\n/g, ' ')
    lyrics2 = lyrics2.replace(sectionRegExpG, '\n')
    const differ = new TextDiff()
    const diffs = differ.main(lyrics1, lyrics2)
    differ.cleanupSemantic(diffs)
    // const words = lyrics2.replace(/\n/g,'').trim(' ').split(' ');

    const simplifiedDiffs = this.restrictDiffsToWordBoundaries(diffs)

    function splitIntoWordLines(text) {
      const lines = text.split(/\r\n|\n/)
      const wordLines = lines.map(function (line, index) {
        const trimmedLine = line.trim()
        return trimmedLine ? trimmedLine.split(' ') : []
        // return splitLine;
      })

      return wordLines
    }

    const wordlineDiffs = simplifiedDiffs.map(function (diff, diffIndex) {
      const type = diff[0]
      const diffText = diff[1].trim()
      const wordLines = splitIntoWordLines(diffText)
      const partCount = wordLines.reduce(function (accumulate, currLine) {
        const newParts = currLine.length

        return accumulate + newParts
      }, 0)
      return {
        type: type,
        wordLines: wordLines,
        wordCount: partCount,
      }
    })

    return wordlineDiffs
  }

  transferWordTimes() {
    const transcribeVisitor = new TimeSpliceVisitor(this.toSections, this.fromSections)
    const wordlineDiffs = this._getDiffs()

    transcribeVisitor.startTrack()

    // if empty, it's a section, so seek to section, otherwise
    // loop through words, seeking

    for (let i = 0; i < wordlineDiffs.length; i++) {
      const currDiff = wordlineDiffs[i]
      const wordCount = currDiff.wordCount
      let transcriberWordsToSkip = 0
      let wordsToTranscribe = 0
      let donorWordsToSkip = 0

      if (currDiff.type === 1) {
        transcriberWordsToSkip = wordCount
      } else if (currDiff.type === 0) {
        wordsToTranscribe = wordCount
      } else if (currDiff.type === -1) {
        donorWordsToSkip = wordCount

        if (i + 1 < wordlineDiffs.length && wordlineDiffs[i + 1].type === 1) {
          const wordsToInsert = wordlineDiffs[++i].wordCount
          wordsToTranscribe = Math.min(wordsToInsert, donorWordsToSkip)
          donorWordsToSkip -= wordsToTranscribe
          transcriberWordsToSkip = wordsToInsert - wordsToTranscribe
        }
      }

      let diffLineIndex = 0
      let sectionWords = currDiff.wordLines[0]
      let sectionPos = 0
      function incrementDiff() {
        if (sectionPos < sectionWords.length) {
          if (sectionWords.length) {
            return sectionWords[sectionPos++]
          }
          sectionPos++
          return ''
        }
        if (++diffLineIndex < currDiff.wordLines.length) {
          sectionWords = currDiff.wordLines[diffLineIndex]
          sectionPos = 0
          return sectionWords.length ? sectionWords[sectionPos++] : ''
        } else {
          return null
        }
      }

      let currWord = incrementDiff()
      while (currWord !== null) {
        if (wordsToTranscribe < -1 || transcriberWordsToSkip < -1 || donorWordsToSkip < -1) {
          throw new Error('not terminating!')
        }
        if (currWord.length || donorWordsToSkip > 0) {
          transcribeVisitor.isTranscribing = true
          while (wordsToTranscribe-- > 0) {
            transcribeVisitor.donor.visitNextWord()
            transcribeVisitor.visitNextWord()
            currWord = incrementDiff()
            if (currWord != null && currWord.length === 0) {
              console.log('trailing blank')
              wordsToTranscribe++ // doesn't count as a word
            }
          }

          transcribeVisitor.isTranscribing = false
          while (transcriberWordsToSkip-- > 0) {
            transcribeVisitor.visitNextWord()
            currWord = incrementDiff()
          }

          while (donorWordsToSkip-- > 0) {
            transcribeVisitor.donor.visitNextWord()
            currWord = incrementDiff()
          }
        } else {
          // TODO: set transcribing
          if (currDiff.type === 1) {
            transcribeVisitor.startNextSection()
            currWord = incrementDiff()
          } else if (currDiff.type === 0) {
            transcribeVisitor.donor.startNextSection()
            transcribeVisitor.startNextSection()
            currWord = incrementDiff()
          } else if (currDiff.type === -1) {
            // TODO: check for insert following
            transcribeVisitor.donor.startNextSection()
            currWord = incrementDiff()
          }
        }
      }
    }
  }
}

export default SongModelTranscriber
