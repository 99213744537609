import cx from 'classnames'
import React, { ChangeEvent } from 'react'

import { getMatchInvite, joinMatch } from '../../actions/social/leaderboard-actions'
import { useAppDispatch } from '../../hooks'
import { InviteInfo } from '../../types'
import Util from '../../util/util'
import CloseIcon from '../widgets/CloseIcon'
import { TextInputWithLabel } from '../widgets/TextInputWithLabel'
import ModalBackdrop from './ModalBackdrop'

const PROMPT_DEFAULT = 'Please enter a valid invite key...'
const PROMPT_JOIN_SUCCESS = 'Match joined!'
const PROMPT_JOIN_FAIL = 'Match not joinable:'

type Props = {
  onClose: () => void
}

const JoinMatchModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch()
  const [managedInviteKey, setManagedInviteKey] = React.useState('')
  const [isDisableJoinButton, setIsDisableJoinButton] = React.useState(true)
  const [joinInfo, setJoinInfo] = React.useState<InviteInfo | undefined>(undefined)
  const [prompt, setPrompt] = React.useState(PROMPT_DEFAULT)
  const [isError, setIsError] = React.useState(false)
  const joinOrCheckStatusLabel = joinInfo ? `Join!` : 'Knock...'

  const onJoinClicked = async () => {
    const inviteKeyParts = Util.getInviteKeyParts(managedInviteKey)
    if (!inviteKeyParts) {
      console.log(`bad invite key ${managedInviteKey}`)
      return
    }
    if (joinInfo) {
      const payload = await dispatch(joinMatch(inviteKeyParts)).unwrap()
      const { isJoinable: isSuccess, reason } = payload
      if (isSuccess) {
        setPrompt(PROMPT_JOIN_SUCCESS)
        setTimeout(onClose, 1000)
      } else {
        setPrompt(`${PROMPT_JOIN_FAIL} (${reason})`)
        setIsError(true)
        setJoinInfo(undefined)
      }
    } else {
      const payload = await dispatch(getMatchInvite(inviteKeyParts)).unwrap()
      const { isJoinable, reason, inviteInfo } = payload
      if (isJoinable && inviteInfo) {
        const { expires } = inviteInfo
        setPrompt(`invitation expires: ${Util.formattedTimeStamp(expires)}`)
        setJoinInfo(inviteInfo)
        setIsError(false)
      } else {
        setPrompt(`${PROMPT_JOIN_FAIL} (${reason})`)
        setIsError(true)
        setIsDisableJoinButton(true)
      }
    }
  }

  const onInviteKeyChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.value
    setManagedInviteKey(key)
    const isValidKey = Util.getInviteKeyParts(key) !== null
    setIsDisableJoinButton(!isValidKey)
    setJoinInfo(undefined)
    setIsError(false)
    setPrompt(isValidKey ? '' : PROMPT_DEFAULT)
    event.stopPropagation()
  }
  const promptClassName = cx('prompt', { isError })
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer joinMatchModal">
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">Join Match...</div>
        <div>
          <TextInputWithLabel
            id="matchInviteKey"
            label="Invite Key"
            value={managedInviteKey}
            onChange={onInviteKeyChanged}
            onBlur={onInviteKeyChanged}
          />
          <div className="join">
            <button disabled={isDisableJoinButton} onClick={onJoinClicked}>
              {joinOrCheckStatusLabel}
            </button>
            <div className="status">
              {joinInfo && <div className="prompt">match: {joinInfo.matchTitle}</div>}
              <div className={promptClassName}>{prompt}</div>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default JoinMatchModal
