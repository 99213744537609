import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  selectCurrentPlaylistSlug,
  selectCurrentPlayStatus,
} from '../../selectors/current-play-selectors'
import { selectCurrentSessionInfo, selectCurrentUsername } from '../../selectors/session-selectors'
import realtime from '../../services/RealtimeService'
import { RootState } from '../../reducers'

const updateUserStatus = createAsyncThunk<void, string, { state: RootState }>(
  'updateUserStatus',
  async (status, { getState }) => {
    const sessionStatus = {
      username: selectCurrentUsername(getState()),
      status,
      timestamp: Date.now(),
    }
    realtime.set('session/latest', sessionStatus)
  }
)

const songSwitched = createAsyncThunk<void, void, { state: RootState }>(
  'songSwitched',
  async (_, { dispatch, getState }) => {
    const isBroadcasting = true // TODO: allow setting, check if anyone listening?
    if (!isBroadcasting) {
      return
    }
    const state = getState()
    const { username, currentMatchSlug: matchSlug } = selectCurrentSessionInfo(state)
    const playlistSlug = selectCurrentPlaylistSlug(state)
    const playStatus = selectCurrentPlayStatus(state)
    const messagePayload = {
      from: username,
      message: { matchSlug, playlistSlug, playStatus },
      timestamp: Date.now(),
    }
    realtime.set(`user/${username}/songSwitch`, messagePayload)
    // dispatch(updatePlayFollowers())
  }
)

export { songSwitched, updateUserStatus }
