import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  DEFAULT_PLAYLIST_KEY,
  defaultDirtyState,
  defaultTrackInfo,
} from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import blasterPeersSlice from '../../reducers/blasterPeersSlice'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import localAuthoringSlice from '../../reducers/localAuthoringSlice'
import { selectOwnedMatchSlug } from '../../selectors/blaster-peer-selectors'
import {
  selectCurrentPlaylistSlug,
  selectCurrentTrackInfo,
  selectIsCurrentMatchOwnedMatch,
} from '../../selectors/current-play-selectors'
import {
  selectLocalTrackInfoForState,
  selectLocalTracks,
} from '../../selectors/local-authoring-selectors'
import { selectPeerPlaylistInfo } from '../../selectors/match-selectors'
import { selectCurrentUsername } from '../../selectors/session-selectors'
import { AudioData, PlaylistInfo, TrackInfo } from '../../types'
import Util from '../../util/util'
import { removeTrack, updatePlaylist, updateTrack } from '../social/peer-playlists'

const updateLocalLyrics = (
  trackSlug: string,
  lyrics: string,
  wordCount: number,
  isDirty: boolean
) => {
  const audioData = { lyrics, wordCount, isLyricsDirty: isDirty, isInfoDirty: false }
  if (isDirty) {
    audioData.isInfoDirty = true
  }
  return updateLocalTrack({ trackSlug, audioData })
}

const updateLocalOwner = (trackSlug: string, newOwner: string) => {
  return updateLocalTrack({ trackSlug, audioData: { owner: newOwner } })
}

const updateLocalTitle = (trackSlug: string, newTitle: string) => {
  return updateLocalTrack({ trackSlug, audioData: { title: newTitle, isInfoDirty: true } })
}

const updateLocalArtist = (trackSlug: string, artist: string) => {
  return updateLocalTrack({ trackSlug, audioData: { artist, isInfoDirty: true } })
}

const updateLocalLinks = (trackSlug: string, links: string) => {
  return updateLocalTrack({ trackSlug, audioData: { links, isInfoDirty: true } })
}

const updateLocalAudioDuration = (trackSlug: string, duration: number) => {
  return updateLocalTrack({
    trackSlug,
    audioData: { duration },
  })
}

const updateLocalAudioURL = (trackSlug: string, remotePath: string) => {
  return updateLocalTrack({
    trackSlug,
    audioData: { remotePath },
  })
}

const updateLocalImageURL = (trackSlug: string, remoteImagePath: string) => {
  return updateLocalTrack({
    trackSlug,
    audioData: { remoteImagePath },
  })
}

const updateLocalTiming = (trackSlug: string, timing: string, timedWordCount?: number) => {
  return updateLocalTrack({
    trackSlug,
    audioData: { timing, timedWordCount, isTimingDirty: true, isInfoDirty: true },
  })
}

const updateLocalTimingURL = (trackSlug: string, url: string) => {
  return updateLocalTrack({ trackSlug, audioData: { remoteTimingPath: url } })
}

const updateDirty = (trackSlug: string, dirtyState: any) => {
  return updateLocalTrack({ trackSlug, audioData: { ...dirtyState } })
}

const addTrackToLocalPlaylist = createAsyncThunk<
  void,
  { playlistSlug?: string; trackInfo: TrackInfo },
  { state: RootState; dispatch: AppDispatch }
>(
  'addTrackToLocalPlaylist',
  ({ playlistSlug = DEFAULT_PLAYLIST_KEY, trackInfo }, { dispatch, getState }) => {
    const state = getState()
    const compoundMatchSlug = selectOwnedMatchSlug(state)
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const addToPlaylist = (slug: string) => {
      const playlistDef = selectPeerPlaylistInfo(matchOwner, matchSlug, slug)(state)
      // TODO: wordCount, timedWordCount recalc!
      dispatch(updatePlaylist({ matchSlug, playlistDef, newTrackInfo: trackInfo })) // TODO: this updates remote playlist even if track hasn't been synced?
    }

    addToPlaylist(playlistSlug)
    if (playlistSlug !== DEFAULT_PLAYLIST_KEY) {
      addToPlaylist(DEFAULT_PLAYLIST_KEY)
    }
    dispatch(updateTrack({ trackInfo }))
  }
)

// TODO: double-check to prevent removing from DEFAULT if still in any other playlist?
const removeTrackFromLocalPlaylist = createAsyncThunk<
  void,
  { playlistSlug: string; trackInfo: TrackInfo },
  { state: RootState; dispatch: AppDispatch }
>(
  'removeTrackFromLocalPlaylist',
  ({ playlistSlug, trackInfo }, { dispatch, getState }) => {
    const state = getState()
    const compoundMatchSlug = selectOwnedMatchSlug(state)
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const { slug, owner } = trackInfo
    const playlistInfo: PlaylistInfo = selectPeerPlaylistInfo(
      matchOwner,
      matchSlug,
      playlistSlug
    )(state)
    const { trackOrder = [] } = playlistInfo
    const trackIndex = trackOrder.indexOf(slug)
    if (trackIndex < 0) {
      console.log(`track ${slug} not in playlist ${playlistSlug}`)
      return
    }
    const newTrackOrder = [...trackOrder]
    newTrackOrder.splice(trackIndex, 1) // TODO: wordCount, timedWordCount recalc!
    const playlistDef: PlaylistInfo = { ...playlistInfo, trackOrder: newTrackOrder }
    dispatch(updatePlaylist({ matchSlug, playlistDef }))
    if (playlistSlug === DEFAULT_PLAYLIST_KEY && owner && owner !== matchOwner) {
      dispatch(removeTrack({ slug }))
    }
  }
  // TODO: remove from owned match!
)

const updateLocalTrack = createAsyncThunk<
  void,
  { trackSlug: string; audioData: AudioData },
  { state: RootState; dispatch: AppDispatch }
>('updateLocalTrack', ({ trackSlug, audioData }, { dispatch, getState }) => {
  const state = getState()
  const tracks = selectLocalTracks(state)
  const creatingLocalTrackData = !(trackSlug in tracks)

  if (creatingLocalTrackData) {
    const trackInfo: AudioData = {
      ...audioData,
      dirtyState: { ...defaultDirtyState, isInfoDirty: true },
    }
    dispatch(currentPlaySlice.actions.setIsInfoDirty(true))
    dispatch(localAuthoringSlice.actions.addOrUpdateTrack({ slug: trackSlug, trackInfo }))
  } else {
    const currTrackInfo = tracks[trackSlug]
    const { dirtyState: currDirtyState = defaultDirtyState } = currTrackInfo
    const trackInfo: AudioData = { ...currTrackInfo, dirtyState: { ...currDirtyState } }
    if (audioData.owner) {
      trackInfo.owner = audioData.owner
    }
    if (audioData.title) {
      trackInfo.title = audioData.title.trim()
    }
    if (audioData.artist) {
      trackInfo.artist = audioData.artist.trim()
    }
    if (audioData.links) {
      // TODO: can't clear? (probly also applies elsewhere)
      trackInfo.links = audioData.links.trim()
    }
    if (audioData.localPath) {
      trackInfo.localPath = audioData.localPath
    }
    if (audioData.remotePath) {
      trackInfo.remotePath = audioData.remotePath
    }
    if (audioData.remoteImagePath) {
      trackInfo.remoteImagePath = audioData.remoteImagePath
    }
    if (audioData.lyrics) {
      trackInfo.lyrics = audioData.lyrics
    }
    if (audioData.wordCount) {
      trackInfo.wordCount = audioData.wordCount
    }
    if (audioData.timedWordCount) {
      trackInfo.timedWordCount = audioData.timedWordCount
    }
    if (audioData.duration) {
      trackInfo.duration = audioData.duration
    }
    if (audioData.remoteLyricsPath) {
      trackInfo.remoteLyricsPath = audioData.remoteLyricsPath
    }
    if (audioData.remoteTimingPath) {
      trackInfo.remoteTimingPath = audioData.remoteTimingPath
    }
    if (audioData.timing) {
      trackInfo.timing = audioData.timing
    }
    if (audioData.manifest) {
      trackInfo.manifest = audioData.manifest
    }
    if ('isAudioDirty' in audioData) {
      // @ts-ignore
      trackInfo.dirtyState.isAudioDirty = audioData.isAudioDirty
      dispatch(currentPlaySlice.actions.setIsAudioDirty(!!audioData.isAudioDirty))
    }
    if ('isImageDirty' in audioData) {
      // @ts-ignore
      trackInfo.dirtyState.isImageDirty = audioData.isImageDirty
      dispatch(currentPlaySlice.actions.setIsImageDirty(!!audioData.isImageDirty))
    }
    if ('isInfoDirty' in audioData) {
      // @ts-ignore
      trackInfo.dirtyState.isInfoDirty = audioData.isInfoDirty
      dispatch(currentPlaySlice.actions.setIsInfoDirty(!!audioData.isInfoDirty))
    }
    if ('isLyricsDirty' in audioData) {
      // @ts-ignore
      trackInfo.dirtyState.isLyricsDirty = audioData.isLyricsDirty
      dispatch(currentPlaySlice.actions.setIsLyricsDirty(!!audioData.isLyricsDirty))
    }
    if ('isTimingDirty' in audioData) {
      // @ts-ignore
      trackInfo.dirtyState.isTimingDirty = audioData.isTimingDirty
      dispatch(currentPlaySlice.actions.setIsTimingDirty(!!audioData.isTimingDirty))
    }
    dispatch(localAuthoringSlice.actions.addOrUpdateTrack({ slug: trackSlug, trackInfo }))
  }

  const username = selectCurrentUsername(state)
  const trackInfo: TrackInfo = selectLocalTrackInfoForState(trackSlug)(getState())
  dispatch(blasterPeersSlice.actions.updateTrack({ username, trackInfo }))

  if (creatingLocalTrackData) {
    const isCurrentMatchOwnedMatch = selectIsCurrentMatchOwnedMatch(state)
    const currentPlaylistSlug = selectCurrentPlaylistSlug(state)
    const whichPlaylist = isCurrentMatchOwnedMatch ? currentPlaylistSlug : undefined
    dispatch(addTrackToLocalPlaylist({ playlistSlug: whichPlaylist, trackInfo }))
  }
  dispatch(currentPlaySlice.actions.setTrackInfo(trackInfo))
})

type LocalAudioSwitchedArgs = {
  remoteURL?: string
  fileName?: string
  filePath?: string
  isInitialLoad?: boolean
  audioDuration?: number
}
const defaultFileNameInfo = { artist: 'Unknown', title: 'Untitled' }
const localAudioSwitched = createAsyncThunk<
  void,
  LocalAudioSwitchedArgs,
  { state: RootState; dispatch: AppDispatch }
>('loading/localAudioSwitched', (args, { dispatch, getState }) => {
  const { remoteURL, fileName, filePath, isInitialLoad, audioDuration = 0 } = args
  const state = getState()
  const fileNameParts = fileName ? Util.removeExtension(fileName).split('|') : ''
  const fileNameInfo = defaultFileNameInfo
  if (fileNameParts.length) {
    const firstPart = fileNameParts[0].trim()
    fileNameInfo.artist = firstPart
    fileNameInfo.title = fileNameParts.length > 1 ? fileNameParts[1].trim() : firstPart
  }
  const currentTrackInfo = selectCurrentTrackInfo(state)
  const { slug, artist, title, owner } = currentTrackInfo
  const updatedInfo = { artist, title, duration: audioDuration }
  if (fileName) {
    updatedInfo.artist = fileNameInfo.artist || artist || defaultTrackInfo.artist
    updatedInfo.title = fileNameInfo.title || title || defaultTrackInfo.title
    dispatch(
      currentPlaySlice.actions.setTrackInfo({
        ...currentTrackInfo,
        ...updatedInfo,
      })
    )
    // this.dirtySongInfo(); // TODO: needed?
  }
  const isBorrowed = owner !== selectCurrentUsername(state)
  if (isBorrowed) {
    return
  }
  const audioData = {
    ...updatedInfo,
    localPath: filePath,
    remotePath: remoteURL,
    isInfoDirty: !isInitialLoad,
  }
  dispatch(updateLocalTrack({ trackSlug: slug, audioData }))
})

export {
  localAudioSwitched,
  addTrackToLocalPlaylist,
  removeTrackFromLocalPlaylist,
  updateLocalTrack,
  updateLocalLyrics,
  updateLocalTitle,
  updateLocalArtist,
  updateLocalLinks,
  updateLocalAudioDuration,
  updateLocalAudioURL,
  updateLocalImageURL,
  updateLocalOwner,
  updateLocalTiming,
  updateLocalTimingURL,
  updateDirty,
}
