import cx from 'classnames'
import React from 'react'

import { matchInvitationResponse } from '../../../actions/social/leaderboard-actions'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectMatchInvites } from '../../../selectors/session-selectors'
import { InviteInfo } from '../../../types'
import Util from '../../../util/util'

export const UserMatchInvites = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch()
  const matchInvites = useAppSelector(selectMatchInvites)
  const matchInviteItems: JSX.Element[] = []
  const MatchInviteItem = ({
    inviteOwner,
    invite,
  }: {
    inviteOwner: string
    invite: InviteInfo
  }) => {
    const {
      slug: inviteKey,
      matchOwner,
      matchTitle: title,
      matchSlug,
      expires: expiresMillis,
    } = invite
    const acceptInvite = () => {
      dispatch(
        matchInvitationResponse({ inviteOwner, matchOwner, matchSlug, inviteKey, isAccept: true })
      )
      onClose()
    }
    const declineInvite = () => {
      dispatch(
        matchInvitationResponse({ inviteOwner, matchOwner, matchSlug, inviteKey, isAccept: false })
      )
    }
    const expirationDate = Util.formattedTimeStamp(expiresMillis)
    const isExpired = Date.now() > expiresMillis
    const viaNonOwner = inviteOwner === matchOwner ? '' : ` (via ${inviteOwner})`
    const expirationClassName = cx('center', { expired: isExpired })
    return (
      <div key={`invitation-${inviteOwner}-${matchSlug}`} className="invite">
        <div className="matchInvite">
          <div className="left">
            <div className="title">{title}</div>
            <div className="owner">
              {matchOwner}
              {viaNonOwner}
            </div>
          </div>
          <div className={expirationClassName}>{expirationDate}</div>
          <div className="right">
            <button onClick={acceptInvite} disabled={isExpired}>
              Accept
            </button>
            <button onClick={declineInvite}>{isExpired ? 'Remove' : 'Decline'}</button>
          </div>
        </div>
      </div>
    )
  }
  // TODO: filter to ignore multiple invites with same key
  Object.keys(matchInvites).forEach((inviteOwner) => {
    const ownerInvites = matchInvites[inviteOwner]
    Object.keys(ownerInvites).forEach((inviteKey: string) => {
      const invite = ownerInvites[inviteKey]
      matchInviteItems.push(
        <MatchInviteItem key={inviteKey} inviteOwner={inviteOwner} invite={invite} />
      )
    })
  })
  const header = matchInviteItems.length ? (
    <div className="matchInvite header">
      <div className="left heading">Match/Host</div>
      <div className="center">Expiration</div>
      <div className="right"></div>
    </div>
  ) : (
    '(none)'
  )
  return (
    <div>
      <div className="heading">My Invitations</div>
      <div className="inviteDisplay">
        <div className="invite header">{header}</div>
        {matchInviteItems}
      </div>
    </div>
  )
}
