import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../reducers'
import currentPlaySlice from '../reducers/currentPlaySlice'
import matchStatusSlice from '../reducers/matchStatusSlice'
import scoreStatusSlice from '../reducers/scoreStatusSlice'
import {
  selectCurrentPlaylistInfo,
  selectCurrentPlayStatus,
  selectCurrentTrackDuration,
  selectCurrentTrackSlug,
  selectIsPlaying,
} from '../selectors/current-play-selectors'
import { selectPeerPlaylistInfo, selectPlayerScore } from '../selectors/match-selectors'
import {
  selectCurrentSessionInfo,
  selectIsCurrentOwnedMatch,
  selectMode,
} from '../selectors/session-selectors'
import {
  recalc,
  incrementalRecalc,
  getPlaylistScore,
  getMatchScore,
  getScoreRank,
} from '../util/score-utils'
import { selectOwnedTrackCounters } from '../selectors/match-selectors'
import { PlayStatus, PlayStatusScoreInfo, ScoreDelta, Section } from '../types'

const updateScore = createAsyncThunk<
  void,
  { sections: Section[]; scoreSeconds: number; scoreDelta: ScoreDelta | undefined },
  { state: RootState; dispatch: AppDispatch }
>('playAction/updateScore', ({ sections, scoreSeconds, scoreDelta }, { dispatch, getState }) => {
  const state = getState()
  const {
    username,
    currentBlaster,
    currentMatchSlug: compoundMatchSlug,
    mode,
  } = selectCurrentSessionInfo(state)
  const isCurrentOwnedMatch = selectIsCurrentOwnedMatch(state)
  if (isCurrentOwnedMatch && mode === 'play') {
    return
  }
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const isPlaying = selectIsPlaying(state)
  const isEditMode = selectMode(state) === 'edit'
  const trackSlug = selectCurrentTrackSlug(state)
  const { slug: playlistSlug } = selectCurrentPlaylistInfo(state)
  const currPlayStatus = selectCurrentPlayStatus(state)
  const trackDuration = selectCurrentTrackDuration(state)
  const {
    topScore: currentTopScore,
    maxScore: blastMaxScore,
    numElements: editMaxScore,
  } = currPlayStatus
  const maxScore = isEditMode ? editMaxScore : blastMaxScore
  const numLaps =
    trackDuration && scoreSeconds > trackDuration ? Math.floor(scoreSeconds / trackDuration) : 0
  const newPlayStats: PlayStatusScoreInfo = scoreDelta
    ? incrementalRecalc(currPlayStatus, numLaps, scoreDelta)
    : recalc({
        sections,
        currentTopScore,
        maxScore,
        currentLapCount: numLaps,
        isUseDoneCount: isEditMode,
      })
  const {
    isNewHighScore,
    score,
    errorCount,
    doneCount,
    topScore,
    topScoreRank,
    counters: newCounters,
  } = newPlayStats
  const runningScoreRank = getScoreRank(score, maxScore) // TODO: correctly compute more complicated "color you are you on track for"

  // Now we have to go out of our way to prevent our audience total counters from getting overwritten
  // Yeah, we're paying for overloading stuff here. TODO: break into separate state or something...
  const counters = isCurrentOwnedMatch ? selectOwnedTrackCounters(trackSlug)(state) : newCounters

  const newPlayStatus: PlayStatus = {
    ...currPlayStatus,
    isPlaying,
    score,
    numLaps,
    doneCount,
    errorCount,
    topScore,
    topScoreRank,
    counters,
    scoreSeconds,
    timestamp: Date.now(),
    runningScoreRank,
  }
  dispatch(currentPlaySlice.actions.setPlayStatus(newPlayStatus))
  const trackScore = {
    player: currentBlaster,
    username,
    matchSlug: compoundMatchSlug,
    trackSlug,
    playStatus: newPlayStatus,
  }
  dispatch(scoreStatusSlice.actions.updateScore({ ...trackScore, playlistSlug }))
  if (scoreDelta) {
    dispatch(currentPlaySlice.actions.setScoreDelta(scoreDelta))
  }

  if (isNewHighScore) {
    dispatch(matchStatusSlice.actions.updateTrackScore(trackScore))
    const playlistInfo = selectPeerPlaylistInfo(matchOwner, matchSlug, playlistSlug)(state)
    const { trackScores } = selectPlayerScore({
      username,
      player: currentBlaster,
      matchOwner,
      matchSlug,
    })(getState())
    const playlistStatus = getPlaylistScore(playlistInfo, trackScores)
    const playlistScore = {
      username,
      player: currentBlaster,
      matchSlug: compoundMatchSlug,
      playlistSlug,
      playStatus: playlistStatus,
    }
    dispatch(matchStatusSlice.actions.updatePlaylistScore(playlistScore))
    const currMatchStatus = selectPlayerScore({
      username,
      player: currentBlaster,
      matchOwner,
      matchSlug,
    })(getState())
    const newMatchStatus = getMatchScore(currMatchStatus)
    const matchScore = {
      username,
      player: currentBlaster,
      matchSlug: compoundMatchSlug,
      playStatus: newMatchStatus,
    }
    dispatch(matchStatusSlice.actions.updateMatchScore(matchScore))
  }
})

export default updateScore
