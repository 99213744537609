import cx from 'classnames'
import React from 'react'

import { Pane, PaneClasses } from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsFocus } from '../../reducers/focusSlice'
import modalsSlice from '../../reducers/modalsSlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectMatchTitle } from '../../selectors/match-selectors'
import {
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectPlayerVisibility,
} from '../../selectors/session-selectors'
import keyBindings from '../../services/KeyBindings'
import { onModalContainerRef } from '../../util/scrolling'
import Match from '../social/Match'
import CloseIcon from '../widgets/CloseIcon'
import ModalBackdrop from '../modals/ModalBackdrop'
import { CheckboxWithLabel } from '../widgets/TextInputWithLabel'

const GameSidebar = () => {
  const dispatch = useAppDispatch()
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.STATUS))
  const currentMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const matchTitle = useAppSelector(selectMatchTitle(matchOwner, matchSlug))
  const currentUserName = useAppSelector(selectCurrentUsername)
  const isOwnedMatch = matchOwner === currentUserName
  const playerVisibility = useAppSelector(selectPlayerVisibility)
  const { isShowGuestPlayers, isShowHostPlayer, isShowUserPlayers } = playerVisibility
  const className = cx(PaneClasses[Pane.STATUS], { hasFocus: isCurrFocus, left: true })
  // const onClick = () => {
  //   // console.log(`container: ${PaneClasses[Pane.SETTINGS]}`)
  //   dispatch(activateNextPane({ pane: Pane.STATUS }))
  // }
  const onToggleGuestPlayers = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowGuestPlayers: !isShowGuestPlayers,
      })
    )
  }
  const onToggleHostPlayer = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowHostPlayer: !isShowHostPlayer,
      })
    )
  }
  const onToggleOtherPlayers = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowUserPlayers: !isShowUserPlayers,
      })
    )
  }
  const onClose = () => {
    keyBindings.popModal()
    dispatch(modalsSlice.actions.toggleLeaderboardModal(false))
    // if (isWatching) {
    //   dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: false }))
    // }
  }
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer leaderboardModal" ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">
          <div>Leaderboard</div>
          <div className="matchTitle">{matchTitle}</div>
          <button className="blastButton" tabIndex={-1} />
        </div>
        <div className={className}>
          {isOwnedMatch && (
            <div className="visibility">
              <span>Show: </span>
              <CheckboxWithLabel
                label="Guests"
                value={isShowGuestPlayers}
                onChange={onToggleGuestPlayers}
              />
              <CheckboxWithLabel
                label="Host"
                value={isShowHostPlayer}
                onChange={onToggleHostPlayer}
              />
              <CheckboxWithLabel
                label="Others"
                value={isShowUserPlayers}
                onChange={onToggleOtherPlayers}
              />
            </div>
          )}
          <Match matchSlug={currentMatchSlug} isLeft={true} />
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default GameSidebar
