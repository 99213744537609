const storage = window.localStorage
const storageVersion = 6 // removes local playlist/track support
const LAST_REQUESTED_PATH = 'lastRequestedPath'

type TimingData = {}

class UserLocalStorage {
  private _username: string

  constructor() {
    this._username = 'unknown'
  }

  set username(username: string) {
    this._username = username
  }

  get versionKey() {
    return `${this._username}-version`
  }

  get timingSlugsKey() {
    return `${this._username}-timingSlugs`
  }

  timingKeyForSlug(slug: string) {
    return `${this._username}-timing-${slug}`
  }

  get localTimingSlugs() {
    return JSON.parse(storage.getItem(this.timingSlugsKey) || '') || []
  }

  set localTimingSlugs(localTimingSlugs) {
    storage.setItem(this.timingSlugsKey, JSON.stringify(localTimingSlugs))
  }

  getTimingDataForSlug(slug: string) {
    const key = this.timingKeyForSlug(slug)
    return JSON.parse(storage.getItem(key) || '')
  }

  setTimingDataForSlug(slug: string, timingData: TimingData) {
    const key = this.timingKeyForSlug(slug)
    storage.setItem(key, JSON.stringify(timingData))
  }

  addOrUpdateTiming(slug: string, timingData: TimingData) {
    if (!this.localTimingSlugs.includes(slug)) {
      this.localTimingSlugs = this.localTimingSlugs.concat(slug)
    }
    this.setTimingDataForSlug(slug, timingData)
  }

  removeTiming(trackSlug: string) {
    const timingIndex = this.localTimingSlugs.indexOf(trackSlug)
    if (timingIndex < 0) {
      console.log(`timing for ${trackSlug} not in local timings`)
      return
    }
    this.localTimingSlugs = [...this.localTimingSlugs].splice(timingIndex, 1)
    storage.removeItem(this.timingKeyForSlug(trackSlug))
  }

  validateAuthoringInfo(isReset: boolean) {
    this.updateToLatestVersionIfNeeded()
    this.removeOrphanedTimings()
  }

  removeOrphanedTimings() {
    const validTimingSlugs = this.localTimingSlugs.filter((slug: string) => {
      if (this.getTimingDataForSlug(slug)) {
        return true
      } else {
        console.warn(`removing reference to missing timing [${slug}]`)
        return false
      }
    })
    this.localTimingSlugs = validTimingSlugs
  }

  updateToLatestVersionIfNeeded() {
    let savedStorageVersion = storageVersion
    try {
      savedStorageVersion = parseInt(storage.getItem(this.versionKey) || '')
    } catch (err) {
      try {
        savedStorageVersion = parseInt(storage.getItem('version') || '') // pre multi-user version
      } catch (err) {}
    }

    switch (savedStorageVersion) {
      case 5: // stopped maintaining local playlists/tracks
        console.log('pls remove your local storage...')
        break

      default:
    }
    storage.setItem(this.versionKey, String(storageVersion))
  }

  set lastRequestedPath(path: string) {
    if (!path) {
      storage.removeItem(LAST_REQUESTED_PATH)
    } else {
      storage.setItem(LAST_REQUESTED_PATH, path)
    }
  }

  get lastRequestedPath() {
    return storage.getItem(LAST_REQUESTED_PATH) || ''
  }
}

const defaultUserLocalStorage = new UserLocalStorage()
export default defaultUserLocalStorage
