import LyricPrintVisitor from '../visitors/LyricPrintVisitor'
import { Line, Section, Target, Word } from '../../types'
import LyricVisitorBase from '../visitors/LyricVisitorBase'

type SimpleLyricVisitorBase = {
  visitTrack: (isStart: boolean) => void
  visitSection: (section: Section) => void
  visitLine: (line: Line) => void
  visitWord: (word: Word) => void
}

const traverseLyrics = (sections: Section[], lyricsVisitor: SimpleLyricVisitorBase) => {
  lyricsVisitor.visitTrack(true)

  sections.forEach((section) => {
    lyricsVisitor.visitSection(section)

    section.lines.forEach((line) => {
      lyricsVisitor.visitLine(line)

      line.words.forEach((word) => {
        lyricsVisitor.visitWord(word)
      })
    })
  })

  lyricsVisitor.visitTrack(false)
}

const traverseLyricsSubset = (
  sections: Section[],
  lyricsVisitor: SimpleLyricVisitorBase,
  start: Target,
  end: Target
) => {
  lyricsVisitor.visitTrack(true)

  const lastSectionIndex = Math.min(sections.length - 1, end.sectionIndex)
  for (let sectionIndex = start.sectionIndex; sectionIndex <= lastSectionIndex; sectionIndex++) {
    const section = sections[sectionIndex]
    lyricsVisitor.visitSection(section)

    const lines = section.lines
    const startingLineIndex = sectionIndex === start.sectionIndex ? start.lineIndex : 0
    const lastLineIndex =
      sectionIndex === end.sectionIndex
        ? Math.min(lines.length - 1, end.lineIndex)
        : lines.length - 1
    for (let lineIndex = startingLineIndex; lineIndex <= lastLineIndex; lineIndex++) {
      const line = lines[lineIndex]
      lyricsVisitor.visitLine(line)

      const words = line.words
      const startingWordIndex =
        sectionIndex === start.sectionIndex && lineIndex === start.lineIndex ? start.wordIndex : 0
      const lastWordIndex =
        sectionIndex === end.sectionIndex && lineIndex === end.lineIndex
          ? Math.min(words.length - 1, end.wordIndex)
          : words.length - 1
      for (let wordIndex = startingWordIndex; wordIndex <= lastWordIndex; wordIndex++) {
        lyricsVisitor.visitWord(words[wordIndex])
      }
    }
  }
  lyricsVisitor.visitTrack(false)
}

const traverseLyrics2 = (sections: Section[], lyricsVisitor: LyricVisitorBase) => {
  lyricsVisitor.startTrack()

  sections.forEach((section) => {
    lyricsVisitor.startSection(section)

    section.lines.forEach((line) => {
      lyricsVisitor.startLine(line, section)

      line.words.forEach((word) => {
        lyricsVisitor.visitWord(word, line, section)
      })

      lyricsVisitor.endLine(line, section)
    })

    lyricsVisitor.endSection(section)
  })

  lyricsVisitor.endTrack()
}

const getLyricsWithTiming = (sections: Section[]) => {
  const printVisitor = new LyricPrintVisitor(true)
  traverseLyrics2(sections, printVisitor)

  return printVisitor.toString()
}

const getLyricsWithoutTiming = (sections: Section[]) => {
  const printVisitor = new LyricPrintVisitor(false, true)
  traverseLyrics2(sections, printVisitor)

  return printVisitor.toString()
}

const getCompactLyrics = (sections: Section[]) => {
  const printVisitor = new LyricPrintVisitor(false, false)
  traverseLyrics2(sections, printVisitor)

  return printVisitor.toString()
}

export {
  traverseLyrics,
  traverseLyrics2,
  traverseLyricsSubset,
  getCompactLyrics,
  getLyricsWithTiming,
  getLyricsWithoutTiming,
}
