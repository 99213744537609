import cx from 'classnames'
import React, { ChangeEvent, useState } from 'react'
import ReactCountdownClock from 'react-countdown-clock'

import { COUNTDOWN_CLOCK_SIZE } from '../../constants/constants'
import { useAppDispatch } from '../../hooks'
import soundStatusSlice from '../../reducers/soundStatusSlice'
import { NumberInputWithLabel } from '../widgets/TextInputWithLabel'
import ModalBackdrop from './ModalBackdrop'

interface BreakTimerProps {
  onClose: () => void
}

const BreakTimer = ({ onClose }: BreakTimerProps) => {
  const dispatch = useAppDispatch()
  const [minutes, setMinutes] = useState('10')
  const [seconds, setSeconds] = useState('0')
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const handleStartTimer = () => {
    setIsTimerRunning(true)
    setIsFinished(false)
  }
  const handleComplete = () => {
    dispatch(soundStatusSlice.actions.play('glass'))
    setIsTimerRunning(false)
    setIsFinished(true)
  }
  const handleReset = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsFinished(false)
  }

  const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds)
  const className = cx('breakTimerModal', { flash: isFinished })

  return (
    <ModalBackdrop onClick={onClose}>
      {isTimerRunning ? (
        <div className="breakTimerClock">
          <ReactCountdownClock
            seconds={totalSeconds}
            color="#eaeaea"
            alpha={0.8}
            size={COUNTDOWN_CLOCK_SIZE * 2}
            timeFormat="hms"
            onComplete={handleComplete}
            showMilliseconds={false}
          />
        </div>
      ) : (
        <div className={className} onClick={handleReset}>
          <h1>Break Timer</h1>
          {isFinished ? (
            <div>
              <h1 className="finished">Time's up!</h1>
            </div>
          ) : (
            <>
              <div className="inputContainer">
                <div>
                  <NumberInputWithLabel
                    label=""
                    id="minutes"
                    value={minutes}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setMinutes(e.target.value)}
                    min={0}
                    max={59}
                  />
                </div>
                :
                <div>
                  <NumberInputWithLabel
                    label=""
                    // TODO:  pattern="[0-9]*" doesn't work?
                    id="seconds"
                    value={seconds}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSeconds(e.target.value)
                    }}
                    min={0}
                    max={59}
                  />
                </div>
              </div>
              <div className="buttonContainer">
                <button className="button" onClick={handleStartTimer}>
                  Start
                </button>
                <button className="button" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </ModalBackdrop>
  )
}
export default BreakTimer
