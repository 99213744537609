import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './index'

type TrackLoadStatusPart = 'info' | 'text' | 'audio' | 'timing'

export type OpenPlaylistInfo = {
  title: string | null
  slug: string | null
  matchSlug: string | null
  isNew: boolean
}
export type OpenMatchInfo = {
  owner: string | null
  slug: string | null
}
export type OpenPlayerInfo = {
  title: string | null
  slug: string | null
  isNew: boolean
}

export type OpenTrackGalleryInfo = {
  isOpen: boolean
}

type ModalsState = {
  isUserSettingsOpen: boolean
  isTrackInfoOpen: boolean
  isSyncModalOpen: boolean
  isJoinMatchModalOpen: boolean
  isHelpModalOpen: boolean
  isBreakTimerModalOpen: boolean
  isLeaderboardModalOpen: boolean
  openTrackGalleryInfo: OpenTrackGalleryInfo
  openMatchInfo: OpenMatchInfo
  openPlaylistInfo: OpenPlaylistInfo
  openPlayerInfo: OpenPlayerInfo
  lyricEditRange: null | { start: number; end: number }
  trackLoadStatus: {
    info: boolean
    text: boolean
    audio: boolean
    timing: boolean
  }
}
const initialState: ModalsState = {
  openPlayerInfo: {
    title: null,
    slug: null,
    isNew: false,
  },
  isUserSettingsOpen: false,
  openTrackGalleryInfo: { isOpen: false },
  isTrackInfoOpen: false,
  isSyncModalOpen: false,
  isJoinMatchModalOpen: false,
  isHelpModalOpen: false,
  isLeaderboardModalOpen: false,
  isBreakTimerModalOpen: false,
  openMatchInfo: {
    owner: null,
    slug: null,
  },
  openPlaylistInfo: {
    title: null,
    slug: null,
    isNew: false,
    matchSlug: null,
  },
  lyricEditRange: null,
  trackLoadStatus: {
    info: false,
    text: false,
    audio: false,
    timing: false,
  },
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleUserSettings: (state, action) => {
      state.isUserSettingsOpen = action.payload
    },
    toggleTrackInfo: (state, action) => {
      state.isTrackInfoOpen = action.payload
    },
    togglePlayerInfo: (state, action) => {
      const { slug, title, isNew } = action.payload
      state.openPlayerInfo.title = title
      state.openPlayerInfo.slug = slug
      state.openPlayerInfo.isNew = isNew
    },
    toggleSyncModal: (state, action) => {
      state.isSyncModalOpen = action.payload
    },
    toggleJoinMatchModal: (state, action) => {
      state.isJoinMatchModalOpen = action.payload
    },
    toggleHelpModal: (state, action) => {
      state.isHelpModalOpen = action.payload
    },
    toggleBreakTimerModal: (state, action) => {
      state.isBreakTimerModalOpen = action.payload
    },
    toggleLeaderboardModal: (state, action: PayloadAction<boolean>) => {
      state.isLeaderboardModalOpen = action.payload
    },
    toggleTrackGalleryInfo: (state, action) => {
      state.openTrackGalleryInfo = action.payload
    },
    toggleMatchInfo: (state, action) => {
      const compoundMatchSlug = action.payload || ''
      const matchParts = compoundMatchSlug.split('/')
      const [owner, slug] = matchParts
      state.openMatchInfo.owner = owner
      state.openMatchInfo.slug = slug
    },
    togglePlaylistInfo: (state, action) => {
      const { slug, title, isNew, matchSlug } = action.payload
      state.openPlaylistInfo.title = title
      state.openPlaylistInfo.slug = slug
      state.openPlaylistInfo.isNew = isNew
      state.openPlaylistInfo.matchSlug = matchSlug
    },
    toggleTrackLoadingStatus: (state, action) => {
      const { part, status } = action.payload
      console.log('isTrackLoading', action.payload)
      state.trackLoadStatus[part as TrackLoadStatusPart] = status
    },
    setLyricEditRange: (state, action) => {
      state.lyricEditRange = action.payload
    },
  },
  extraReducers: {},
})

export const selectIsAnySidebarModalOpen = (state: RootState) => {
  const {
    isTrackInfoOpen,
    isJoinMatchModalOpen,
    openMatchInfo: { owner },
  } = state.modals
  return isTrackInfoOpen || isJoinMatchModalOpen || owner
}
export const selectTrackGalleryInfo = (state: RootState) => {
  return state.modals.openTrackGalleryInfo
}
export default modalsSlice
