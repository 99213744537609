// Assumes range will be in LyricPrintVisitor pretty-printed output
import { Line, Section, Target, Word } from '../../types'

class TargetCharacterRangeVisitor {
  target: Target
  isDone = false
  targetRange = {
    start: 0,
    end: 0,
  }
  constructor(target: Target) {
    this.target = target
  }
  startTrack() {}
  endTrack() {}

  startSection(section: Section) {
    if (this.isDone) {
      return
    }

    if (section.index === this.target.sectionIndex && this.target.lineIndex === -1) {
      this.isDone = true
      this.targetRange.start++ // skip opening [
      this.targetRange.end = this.targetRange.start + section.label.length
    } else {
      this.targetRange.start += section.label.length + 3 // [] + trailing \n
    }
  }
  endSection() {
    if (this.isDone) {
      return
    }
    this.targetRange.start++ // skip past trailing \n
  }

  startLine(line: Line, section: Section) {}

  endLine() {
    if (this.isDone) {
      return
    }

    this.targetRange.start++ // skip past trailing \n
  }

  visitWord(word: Word, line: Line, section: Section) {
    if (this.isDone) {
      return
    }
    if (word.index > 0) {
      this.targetRange.start++ // skip past trailing space
    }
    const wordLength = word.label.length

    if (
      section.index === this.target.sectionIndex &&
      line.index === this.target.lineIndex &&
      word.index === this.target.wordIndex
    ) {
      this.isDone = true
      this.targetRange.end = this.targetRange.start + wordLength
    } else {
      this.targetRange.start += wordLength
    }
  }
}

export default TargetCharacterRangeVisitor
