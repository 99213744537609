const DeviceSupport = function () {}

/*
    config = {
        minimumSize : { width, height }, // TODO: portrait vs. landscape?
        required    : [ "feature/browser/device", ... ]
        excluded    : [ "feature/browser/device", ... ]
        custom      : [ function1, function2,... ] // return 0 if success, msg if failure
    }
 */
DeviceSupport.prototype = {
  init: function (config, forceUnsupported, debug) {
    this.forceUnsupported = forceUnsupported // for testing
    this.config = config

    if (config && !debug) {
      this.failPage = config.failPage
    }
  },

  requirementTypes: ['required', 'excluded', 'minimumSize', 'custom'],

  isSupported: function () {
    let isSupported
    let failedMessage

    for (let i = 0; i < this.requirementTypes.length; i++) {
      failedMessage = this.checkRequirements(this.requirementTypes[i])

      if (failedMessage) {
        break
      }
    }

    isSupported = !failedMessage

    if (!isSupported || this.forceUnsupported) {
      if (this.failPage) {
        // TODO: consider other ways to customize that don't a) ignore failedMessage, or b) change the URL?
        window.location = this.failPage
      } else {
        this._defaultFail(failedMessage)
      }
    }

    return isSupported
  },

  checkRequirements: function (type) {
    let failedProp = undefined

    if (type === 'minimumSize') {
      let minSize = this.config.minimumSize

      if (minSize) {
        // eslint-disable-next-line no-restricted-globals
        if (screen.width < minSize.width || screen.height < minSize.height) {
          failedProp = minSize.width + 'x' + minSize.height + ' minimum screen size'
        }
      }
    } else {
      const checkArray = this.config[type]

      for (let i = 0; i < checkArray.length; i++) {
        const prop = checkArray[i]

        failedProp = prop

        if (type === 'custom') {
          try {
            failedProp = prop(this)
          } catch (e) {
            console.error(e)
            failedProp = 'unknown check exception'
          }
        } else if (prop in this) {
          const hasProp = this[prop]
          const passes = type === 'required' ? hasProp : !hasProp

          if (passes) {
            failedProp = null
          }
        }

        if (failedProp) {
          const verb = type === 'custom' ? '' : type

          failedProp = failedProp + ' ' + verb

          break
        }
      }
    }

    return failedProp
  },

  _defaultFail: function (failedProp) {
    document.body.innerHTML = 'Unsupported Browser or Device: ' + failedProp
  },

  get chrome() {
    return !!navigator.userAgent.match(/chrome/i)
  },
  get safari() {
    return !!navigator.userAgent.match(/safari/i) && !this.chrome
  },
  get edge() {
    return !!navigator.userAgent.match(/edge/i)
  },
  get android() {
    return !!navigator.userAgent.match(/android/i)
  },

  get firefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  },

  get iPhoneOriPod() {
    return !!navigator.platform.match(/i(Phone|Pod)/i)
  },
  get iPad() {
    return !!navigator.platform.match(/iPad/i)
  },

  get webAudio() {
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext

      new AudioContext()
    } catch (e) {
      return false
    }

    return true
  },
}

const defaultDeviceSupport = new DeviceSupport()
export default defaultDeviceSupport
