import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import player from '../../services/Player'

const loadLocalTiming = createAsyncThunk<
  void,
  { asReference: boolean; file: Blob },
  { state: RootState; dispatch: AppDispatch }
>('load/loadLocalTiming', ({ asReference, file }, { dispatch }) => {
  const reader = new FileReader()
  reader.onload = (evt: ProgressEvent<FileReader>) => {
    console.log('loaded local timing')
    const fileStr = evt.target ? evt.target.result : ''
    const lrcLines = String(fileStr).split(/[\r\n]+/g)
    dispatch(currentPlaySlice.actions.setLRClines(lrcLines))

    if (asReference) {
      dispatch(currentPlaySlice.actions.setIsPlayable(true)) // TODO: criteria?
    }
    dispatch(currentPlaySlice.actions.setIsScoreDirty(true))
    player._loadLRC({ asReference, lrcLines })
  }
  reader.readAsText(file)
})

export default loadLocalTiming
