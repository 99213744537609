import React, { ChangeEvent } from 'react'

import { checkUserAliasAvailability, initUserAlias } from '../../../actions/social/update-username'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectCurrentUsername } from '../../../selectors/session-selectors'
import CloseIcon from '../../widgets/CloseIcon'
import { TextInputWithLabel } from '../../widgets/TextInputWithLabel'

// TODO: memory leak if user closes dialog while api call in flight
type Props = { onClose: () => void }
const NameChangeModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch()
  const currUsername = useAppSelector(selectCurrentUsername)
  const currName = currUsername.split('@')[1]
  const [newUsername, setNewUsername] = React.useState('')
  const [isUserNameDirty, setIsUserNameDirty] = React.useState(false)
  const [userNameChangeStatus, setUserNameChangeStatus] = React.useState('')
  const [isNewUsernameAvailable, setIsNewUsernameAvailable] = React.useState(false)
  const _checkAvailability = async () => {
    setUserNameChangeStatus('working')
    const isAvailable = await dispatch(checkUserAliasAvailability(newUsername)).unwrap()
    setIsNewUsernameAvailable(isAvailable)
    setUserNameChangeStatus(isAvailable ? 'available!' : 'not available')
  }
  const _createUserAlias = async () => {
    setUserNameChangeStatus('working')
    const newAlias = await dispatch(initUserAlias({ username: newUsername })).unwrap()
    setIsUserNameDirty(false)
    setIsNewUsernameAvailable(false)
    setUserNameChangeStatus(
      newAlias ? `alias "${newAlias}" created! log in again to access.` : 'alias creation failed'
    )
  }
  const identityButtonClick = isNewUsernameAvailable ? _createUserAlias : _checkAvailability
  const identityButtonText = isNewUsernameAvailable ? 'Create Now' : 'Check Availability'
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value
    const isValid = newName.length > 3 && newName !== currName
    setNewUsername(newName)
    setIsUserNameDirty(isValid)
    setIsNewUsernameAvailable(false)
    setUserNameChangeStatus('')
  }

  return (
    <div className="modalContainer nameChangeModal">
      <CloseIcon onClose={onClose} />
      <div className="modalHeading">New Blaster Alias...</div>
      <TextInputWithLabel
        id="blasterName"
        label="New Alias"
        value={newUsername}
        onChange={onChange}
        onBlur={onChange}
      />
      <button disabled={!isUserNameDirty} onClick={identityButtonClick}>
        {identityButtonText}
      </button>
      <div className="status">{userNameChangeStatus}</div>
    </div>
  )
}

export default NameChangeModal
