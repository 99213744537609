import { pathOr } from 'ramda'
import React, { ChangeEvent, createRef, KeyboardEvent, useState } from 'react'

import { addOrUpdateMatchInfo, switchActiveBlaster } from '../../actions/social/leaderboard-actions'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsPlaying } from '../../selectors/current-play-selectors'
import { selectMatchStatus } from '../../selectors/match-selectors'
import {
  selectCurrentBlaster,
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectPlayerVisibility,
} from '../../selectors/session-selectors'
import { PlayerStatusMap } from '../../types'
import Util from '../../util/util'
import { SelectWithLabel } from './TextInputWithLabel'

export const PlayerMenu = () => {
  const dispatch = useAppDispatch()
  const playerMenuRef = createRef<HTMLSelectElement>()
  const currentMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const currentBlaster = useAppSelector(selectCurrentBlaster)
  const isPlaying = useAppSelector(selectIsPlaying)
  const currentUsername = useAppSelector(selectCurrentUsername)
  const matchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))
  const playerVisibility = useAppSelector(selectPlayerVisibility)
  const { info: matchInfo, leaderboard } = matchStatus
  const { guestOrder: currentGuestOrder = [] } = matchInfo
  const [guestOrder, setGuestOrder] = useState<string[]>([])
  const [isInited, setIsInited] = useState(true)
  const currentUserPlayerScores: PlayerStatusMap = pathOr(
    {},
    ['players', currentUsername],
    matchStatus
  )

  const setNewBlaster = React.useCallback(
    (newBlasterSlug: string) => {
      dispatch(switchActiveBlaster({ newBlasterSlug }))
    },
    [dispatch]
  )

  React.useEffect(() => {
    setGuestOrder([]) // TODO: it'd be nice is we could just set isInited to false here...
  }, [matchOwner, currentUsername, currentMatchSlug, playerVisibility])

  React.useEffect(() => {
    if (isInited) {
      return
    }
    const { isShowHostPlayer } = playerVisibility
    const defaultOrder = Object.keys(currentUserPlayerScores)
    if (defaultOrder.length) {
      const newGuestOrder = currentGuestOrder.length ? currentGuestOrder : defaultOrder
      const filteredOrder = newGuestOrder.filter(
        (player) => isShowHostPlayer || player !== currentUsername
      )
      if (filteredOrder.length) {
        setNewBlaster(filteredOrder[0])
      }
      setGuestOrder(filteredOrder)
      setIsInited(true)
    }
  }, [
    isInited,
    currentGuestOrder,
    currentUsername,
    currentUserPlayerScores,
    playerVisibility,
    setNewBlaster,
  ])

  if (matchOwner !== currentUsername) {
    return null
  }
  const players = guestOrder.map((slug) => {
    const { name } = currentUserPlayerScores[slug] || { name: `?${slug}` }
    return { value: slug, name: name || slug }
  })
  const onLoadLast = () => {
    setIsInited(false)
  }
  const onShuffle = () => {
    const newOrder = Util.shuffleArray(guestOrder)
    const newMatchInfo = { ...matchInfo, guestOrder: newOrder }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
    setIsInited(false)
  }
  const onSort = () => {
    const sortedGuestPlayers: string[] = []
    leaderboard.forEach((compoundPlayer) => {
      const [username, player] = compoundPlayer.split('/')
      if (username === currentUsername) {
        sortedGuestPlayers.push(player)
      }
    })
    const newMatchInfo = { ...matchInfo, guestOrder: sortedGuestPlayers }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
    setIsInited(false)
  }
  const onChangePlayer = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      const blasterSlug = event.target.value
      setNewBlaster(blasterSlug)
    }
  }
  const onKeyDown = (event: KeyboardEvent) => {
    const step = event.keyCode === 40 ? 1 : event.keyCode === 38 ? -1 : 0
    if (playerMenuRef.current && step !== 0) {
      const newIndex =
        (playerMenuRef.current.selectedIndex + step + players.length) % players.length
      playerMenuRef.current.selectedIndex = newIndex
      setNewBlaster(players[newIndex].value)
      event.stopPropagation()
    }
  }

  return (
    <div className="playerMenu">
      {playerVisibility.isShowGuestPlayers && players.length > 0 && (
        <SelectWithLabel
          onRef={playerMenuRef}
          itemArray={players}
          label="Team"
          onChange={onChangePlayer}
          onKeyDown={onKeyDown}
          value={currentBlaster}
        />
      )}
      {!isPlaying && playerVisibility.isShowGuestPlayers && (
        <div>
          {currentGuestOrder.length > 0 && !players.length && (
            <button className="chatNewTrackButton" onClick={onLoadLast}>
              Load Players
            </button>
          )}
          {players.length > 0 && (
            <button className="chatNewTrackButton" onClick={onShuffle}>
              Shuffle
            </button>
          )}
          {players.length > 0 && (
            <button className="chatNewTrackButton" onClick={onSort}>
              Sort
            </button>
          )}
        </div>
      )}
    </div>
  )
}
