class UploadProgress {
  progressWrapper: HTMLElement | null
  progressDiv: HTMLElement | null
  successMessage: string
  failureMessage: string
  constructor(progressDivId: string) {
    this.progressWrapper = document.getElementById(`${progressDivId}Wrapper`)
    this.progressDiv = document.getElementById(progressDivId)
    this.successMessage = 'Upload complete.'
    this.failureMessage = 'Upload failed.'
  }

  start() {
    if (this.progressWrapper && this.progressDiv) {
      this.progressDiv.innerText = ''
      this.progressDiv.style.width = String(0)
      this.progressDiv.classList.toggle('show', false)
      this.progressDiv.classList.toggle('done', false)
      this.progressWrapper.classList.toggle('show', true)
    }
  }

  stop(err?: Error) {
    if (this.progressWrapper && this.progressDiv) {
      this.progressDiv.classList.toggle('show', true)
      this.progressDiv.classList.toggle('done', true)
      this.progressWrapper.classList.toggle('show', false)
      this.progressDiv.style.width = '100%'
      this.progressDiv.innerText = err ? this.failureMessage : this.successMessage
    }
  }

  doProgress(percent: number) {
    if (this.progressDiv) {
      this.progressDiv.style.width = `${percent}%`
    }
  }
}

export default UploadProgress
