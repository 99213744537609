import { useEffect, useState } from 'react'
import { alertSounds } from '../../constants/constants'
import soundStatusSlice, { selectSoundStatus } from '../../reducers/soundStatusSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'

type AudioMap = { [key: string]: HTMLAudioElement }
export const SimpleSounds = () => {
  const dispatch = useAppDispatch()
  const [audioElems, setAudioElems] = useState<AudioMap>({})
  const soundStatuses = useAppSelector(selectSoundStatus)
  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => {
    const preloadAudio = () => {
      const newAudioElems: AudioMap = {}
      const promises = alertSounds.map((soundId) => {
        const path = `/audio/${soundId}.m4a`
        return new Promise<string>((resolve, reject) => {
          const audio = new Audio()
          newAudioElems[soundId] = audio
          audio.src = path
          audio.oncanplaythrough = () => {
            // console.log(`Success loading audio [${soundId}]: ${path}`)
            resolve(soundId)
          }
          audio.onerror = (err) => {
            console.log(`Error loading audio [${soundId}]: ${path}`, err)
            reject(path)
          }
        })
      })
      Promise.all(promises)
        .then((soundIds) => {
          soundIds.forEach((soundId) => {
            dispatch(soundStatusSlice.actions.add(soundId))
          })
          setLoaded(true)
          setAudioElems(newAudioElems)
        })
        .catch((err) => console.error('Error loading audio files', err.message))
    }
    if (!isLoaded) {
      preloadAudio()
    }
  }, [audioElems, dispatch, isLoaded])

  useEffect(() => {
    const soundsPlayed: string[] = Object.keys(soundStatuses).reduce(
      (acc: string[], name: string) => {
        if (name in soundStatuses && name in audioElems) {
          const audioRef = audioElems[name]
          if (audioRef) {
            const soundStatus = soundStatuses[name]
            if (soundStatus.isPlaying) {
              audioRef.play().catch((err) => {
                console.log(`failed to play [${name}] because: ${err.message}`)
              })
              acc.push(name)
            }
          }
        }
        return acc
      },
      []
    )

    soundsPlayed.forEach((name) => {
      dispatch(soundStatusSlice.actions.stop(name))
    })
  }, [soundStatuses, dispatch, audioElems])

  return (
    <div id={`${isLoaded ? 'loaded' : 'loading'}`}>
      {/*{loaded ? <p>All audio files preloaded successfully!</p> : <p>Loading audio files...</p>}*/}
    </div>
  )
}
