import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { API_PATH_FETCH_SPOTIFY_TOKEN } from '../../constants/constants'
import { FIREBASE_AUDIENCE } from '../../constants/environment'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import sessionSlice from '../../reducers/sessionSlice'
import {
  selectCurrentUsername,
  selectIsSpotifyConnected,
  selectSpotifyToken,
} from '../../selectors/session-selectors'
import { SpotifyAudioPlayer } from '../../services/AudioWrappers'
import player from '../../services/Player'
import { AppDispatch, RootState } from '../../reducers'

const spotifyDeviceName = `LyricBlaster - ${FIREBASE_AUDIENCE}`

const getSpotifyToken = createAsyncThunk<
  Promise<string>,
  { userManagerAccessToken: string; isForceRefresh?: boolean },
  { state: RootState; dispatch: AppDispatch }
>(
  'getSpotifyToken',
  ({ userManagerAccessToken, isForceRefresh = false }, { dispatch, getState }) => {
    const state = getState()
    return new Promise<string>((resolve, reject) => {
      const username = selectCurrentUsername(state)
      const config = {
        'content-type': 'application/json',
        headers: {
          authorization: `Bearer ${userManagerAccessToken}`,
        },
      }
      const url = `${API_PATH_FETCH_SPOTIFY_TOKEN}/${username}${isForceRefresh ? '/refresh' : ''}`
      axios
        .get(url, config)
        .then((response) => {
          console.log(response.data)
          const {
            data: { accessToken },
          } = response
          dispatch(sessionSlice.actions.setSpotifyToken(accessToken))
          resolve(accessToken)
        })
        .catch((error) => {
          console.log('getSpotifyToken:', error.response.data)
          reject('failed')
        })
    })
  }
)

const initSpotifyPlayer = createAsyncThunk<
  Promise<void>,
  string,
  { state: RootState; dispatch: AppDispatch }
>('init/spotifyPlayer', async (userManagerAccessToken, { dispatch, getState }) => {
  const state = getState()
  const isPlayerInitialized = selectIsSpotifyConnected(state)
  if (isPlayerInitialized) {
    return Promise.resolve()
  }
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    script.src = 'https://sdk.scdn.co/spotify-player.js'
    script.async = true

    document.body.appendChild(script)

    const getOAuthToken = async (cb: (token: string) => void) => {
      console.log('spotify getOAuthToken called')
      const token = await dispatch(getSpotifyToken({ userManagerAccessToken })).unwrap()
      cb(token)
    }
    window.onSpotifyWebPlaybackSDKReady = () => {
      const spotifyPlayer = new window.Spotify.Player({
        name: spotifyDeviceName,
        getOAuthToken,
        volume: 0.5,
      })
      const audioPlayer = new SpotifyAudioPlayer(spotifyPlayer)

      spotifyPlayer.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id)
      })
      spotifyPlayer.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id)
      })
      spotifyPlayer.addListener(
        'player_state_changed',
        (playerState: { paused: boolean; duration: number }) => {
          dispatch(sessionSlice.actions.setSpotifyPlayerState(playerState))
          const { paused, duration } = playerState
          /* This is a bit of a hack: we need to work around the fact
             that there seems to be no way via the Spotify API to tee up a track without
             it automatically playing; so, we detect that initial state here and
             pause and rewind. We also take this opportunity to initialize our player
             with the track duration -- which we would need to do some other way
             if not for this quirk, so, we'll take the good with bad for now...
          */
          const isInitialTrackLoad = !player.isPlaying && !paused
          if (isInitialTrackLoad) {
            audioPlayer.stop()
            audioPlayer.seek(0)

            const trackSeconds = duration / 1000
            audioPlayer.trackDuration = trackSeconds
            dispatch(currentPlaySlice.actions.setTrackDuration(trackSeconds))
            player.updateClock()
          }
        }
      )

      spotifyPlayer.connect()
      player.setAudioPlayer('spotify', audioPlayer)
      resolve()
    }
  })
})

const loadSpotifyTrack = createAsyncThunk<
  number,
  string,
  { state: RootState; dispatch: AppDispatch }
>('load/spotifyTrack', async (spotifyUri, { dispatch, getState }) => {
  const token = selectSpotifyToken(getState())
  return new Promise<number>((resolve, reject) => {
    fetch('https://api.spotify.com/v1/me/player/play', {
      method: 'PUT',
      body: JSON.stringify({ uris: [spotifyUri] }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      player.setAudioPlayer('spotify')
      resolve(player.trackDuration)
    })
  })
})

export { getSpotifyToken, initSpotifyPlayer, loadSpotifyTrack }
