import React from 'react'
import cx from 'classnames'

import { useAppSelector } from '../../../hooks'
import { selectInviteForMatch } from '../../../selectors/session-selectors'
import { MinimalInviteInfo, MinimalInviteInfoMap } from '../../../types'
import Util from '../../../util/util'
import PlayerInviteModal from '../PlayerInviteModal'

type Props = {
  currUserId: string
  matchOwner: string
  matchInvites: MinimalInviteInfoMap
  matchSlug: string
  isOwnedMatch: boolean
  isShareable: boolean
  isDirty?: boolean
}

export const MatchInvites = ({
  isDirty = false, // TODO: fix
  isShareable,
  currUserId,
  matchOwner,
  matchInvites = {},
  matchSlug,
  isOwnedMatch,
}: Props) => {
  const isOwner = currUserId === matchOwner
  const matchInviteKey = useAppSelector(selectInviteForMatch(matchSlug))
  const [isShowInviteModal, setisShowInviteModal] = React.useState(false)
  const [selectedInviteInfo, setSelectedInviteInfo] = React.useState<MinimalInviteInfo | null>(null)
  const getInvites = () => {
    const getInvite = (key: string) => {
      const inviteInfo = matchInvites[key]
      const { expires } = inviteInfo
      // const { expires, prompt } = inviteInfo // TODO: use prompt!
      const isExpired = expires < Date.now()
      const expirationDate = Util.formattedTimeStamp(expires)
      const onInviteClick = () => {
        createOrUpdateInvite(inviteInfo)
      }
      const className = cx('invite', { expired: isExpired })
      return (
        <div key={key} className={className} onClick={onInviteClick}>
          <div className="key">{key}</div>
          <div className="status">{isExpired ? 'expired' : 'expiring'}</div>
          <div className="date">{expirationDate}</div>
          {/*<div className="prompt">{prompt}</div>*/}
        </div>
      )
    }
    return (
      <div>
        <label>Invites:</label>
        <div className="invitesDisplay2">{Object.keys(matchInvites).map(getInvite)}</div>
      </div>
    )
  }

  const getButtons = () => {
    const isInviteVisible = (isOwner && !isOwnedMatch) || isShareable
    const isInviteEnabled = !isDirty
    return (
      <div className="buttons">
        {isInviteVisible && (
          <button onClick={onCreateOrShareInviteClick} disabled={!isInviteEnabled}>
            {isOwner ? 'New Invite' : 'Share'}...
          </button>
        )}
      </div>
    )
  }
  const closeShowInviteModal = () => {
    setisShowInviteModal(false)
  }
  const getInviteModal = () => {
    if (!isShowInviteModal) {
      return false
    }
    return (
      <PlayerInviteModal
        onClose={closeShowInviteModal}
        currUserId={currUserId}
        matchOwner={matchOwner}
        matchSlug={matchSlug}
        existingInvite={selectedInviteInfo}
      />
    )
  }
  const onCreateOrShareInviteClick = () => {
    if (true || isOwner) {
      createOrUpdateInvite(matchInvites[matchInviteKey])
    } else {
      // TODO
      // createOrUpdateInvite(inviteInfo)
    }
  }
  const createOrUpdateInvite = (inviteInfo: MinimalInviteInfo) => {
    if (inviteInfo || isOwner) {
      setSelectedInviteInfo(inviteInfo)
      setisShowInviteModal(true)
    }
  }
  return (
    <>
      {isOwner && getInvites()}
      {getButtons()}
      {getInviteModal()}
    </>
  )
}
