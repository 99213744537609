import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../reducers'
import { selectCurrentUser } from '../../selectors/blaster-peer-selectors'
import {
  DEFAULT_OWNED_MATCH_TITLE,
  DEFAULT_PLAYLIST_KEY,
  defaultMatchInfo,
} from '../../constants/constants'
import Util from '../../util/util'
import { addOrUpdateMatchInfo, initOrUpdateCurrentUser } from '../social/leaderboard-actions'

export const initializeAuthoring = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('initializeAuthoring', (_, { dispatch, getState }) => {
  const currentUser = selectCurrentUser(getState())
  if (currentUser.ownedMatchSlug) {
    console.log('user authoring already initialized')
    return
  }
  const { username } = currentUser
  const timestamp = Date.now()
  const allTracksPlaylistSlug = DEFAULT_PLAYLIST_KEY // TODO: generate or let 'default' be known and perhaps used and/or abused? (update local storage to use generated slug)
  const playlistInfo = [
    {
      slug: allTracksPlaylistSlug,
      title: 'All Tracks',
      trackOrder: [],
      wordCount: 0,
      timestamp,
    },
  ]
  const matchSlug = Util.generateId()
  const ownedMatchSlug = `${username}/${matchSlug}`
  const matchInfo = {
    ...defaultMatchInfo(),
    slug: ownedMatchSlug,
    title: DEFAULT_OWNED_MATCH_TITLE,
    timestamp,
    isShareable: true,
  }
  dispatch(initOrUpdateCurrentUser({ allTracksPlaylistSlug, ownedMatchSlug, playlistInfo }))
  dispatch(addOrUpdateMatchInfo({ matchInfo, playlistInfo }))
})
