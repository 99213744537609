import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import { TrackInfo, TrackInfoMap } from '../types'

export interface BlasterPeer {
  username: string
  isCurrentUser: boolean
  tracksInitialized: boolean
  tracks: TrackInfoMap
  ownedMatchSlug: string
  activeMatchSlug: string
  allTracksPlaylistSlug: string
  broadcastTo: string[]
  syncWith: string[]
}
export type BlasterPeerMap = { [key: string]: BlasterPeer }
const initialState: BlasterPeerMap = {}
const blasterPeersSlice = createSlice({
  name: 'blasterPeers',
  initialState,
  reducers: {
    initCurrentUser: (state, action) => {
      const { username, ownedMatchSlug, allTracksPlaylistSlug, playlistMap = {} } = action.payload
      const newPeer = {
        [username]: {
          username,
          isCurrentUser: true,
          tracksInitialized: false,
          tracks: {},
          playlistMap,
          ownedMatchSlug,
          allTracksPlaylistSlug,
        },
      }
      const result = mergeDeepRight(state, newPeer)
      return result
    },
    setOnlineStatus: (state, action) => {
      const { username, onlineStatus } = action.payload
      const updatedPeer = {
        [username]: { onlineStatus },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
    setIsFollowing: (state, action) => {
      const { username, syncWith } = action.payload
      const updatedPeer = {
        [username]: { syncWith },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
      // TODO:
      // toggleFollowing(username, amFollowing) {
      //   if (amFollowing) {
      //     this.usersBeingFollowed[username] = Date.now();
      //   } else {
      //     delete this.usersBeingFollowed[username];
      //   }
      // }
    },
    setIsFollowedBy: (state, action) => {
      const { username, broadcastTo } = action.payload
      const updatedPeer = {
        [username]: { broadcastTo },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
    setTracks: (state, action: PayloadAction<{ username: string; tracks: TrackInfoMap }>) => {
      const { username, tracks } = action.payload
      const updatedPeer = {
        [username]: { tracks, tracksInitialized: true },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
    updateTrack: (state, action: PayloadAction<{ username: string; trackInfo: TrackInfo }>) => {
      const { username, trackInfo } = action.payload
      const updatedPeer = {
        [username]: {
          tracks: {
            [trackInfo.slug]: trackInfo,
          },
        },
      }
      const result = mergeDeepRight(state, updatedPeer)
      return result
    },
  },
})

export default blasterPeersSlice
