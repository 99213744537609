import getState, { store } from '../reducers'
import sessionSlice from '../reducers/sessionSlice'
import vizParametersSlice from '../reducers/vizParametersSlice'
import { selectCurrentVisualizationSlug, selectPrefs } from '../selectors/session-selectors'
import { Line, ModelPart, Section, VizType, Word } from '../types'
import Util from '../util/util'
import { updateWordOnBlast } from '../util/score-utils'
import splitLyrics from '../util/lyrics/split-lyrics'
import { traverseLyrics } from '../util/lyrics/traverse-lyrics'
import HtmlCreateVisitor from '../util/visitors/HtmlCreateVisitor'
import LyricVizLibrary from './LyricVizLibrary'
import player from './Player'
import AnimatingVizVisitor from './viz/AnimatingVizVisitor'
import SongModelTranscriber from '../util/visitors/SongModelTranscriber'

class LyricVizBuilder {
  vizVisitor: AnimatingVizVisitor | null
  vizLibrary: LyricVizLibrary
  isAnimateViz: boolean
  partBuilder: HtmlCreateVisitor
  _isVizSwitchingScheduled: boolean
  lyricsModel: {
    sections: Section[]
    title: string
    numWords: number
    numTimedWords: number
  }
  _offset = { x: 0, y: 0 }

  constructor() {
    this.lyricsModel = {
      sections: [],
      title: '',
      numWords: 0,
      numTimedWords: 0,
    }
    this.vizVisitor = null
    this.vizLibrary = new LyricVizLibrary()
    this.isAnimateViz = true
    this._isVizSwitchingScheduled = false
    this.partBuilder = new HtmlCreateVisitor('')
  }
  get sections() {
    return this.lyricsModel.sections
  }

  get wordCount() {
    return this.lyricsModel.numWords
  }

  get timedWordCount() {
    return this.lyricsModel.numTimedWords
  }

  set timedWordCount(numTimedWords) {
    this.lyricsModel.numTimedWords = numTimedWords
  }

  get partClassName() {
    return `.${this.partBuilder.partKey}`
  }

  get isVizSwitchingScheduled() {
    return this._isVizSwitchingScheduled
  }

  set isVizSwitchingScheduled(isVizSwitchingScheduled: boolean) {
    this._isVizSwitchingScheduled = isVizSwitchingScheduled
  }

  tick() {
    if (this.vizVisitor && this.isAnimateViz) {
      this.vizVisitor.tick()
      this.updateViz()
    }
  }

  toggleSelection(part: ModelPart | null) {
    // this.interactionContainer.classList.toggle('viz-anim1');
  }

  buildLyrics() {
    this.partBuilder.partKey = ''
    const currentVisualizationSlug = selectCurrentVisualizationSlug(getState())
    this.switchVisualization(currentVisualizationSlug)
  }

  switchVisualization(vizKey: VizType) {
    const { theme: themeIndex } = selectPrefs(getState())
    const { partBuilder } = this
    const newPartKey =
      player.isEditMode || player.isSpellMode ? 'read' : vizKey === 'page' ? 'play' : 'viz' // TODO: abstract to viz library
    if (newPartKey !== partBuilder.partKey) {
      partBuilder.firstTarget = {
        sectionIndex: -1,
        lineIndex: -1,
        wordIndex: -1,
        elem: null,
      }
      player.interactionContainer.innerHTML = ''
      partBuilder.partKey = newPartKey
      partBuilder.traverse(this.sections)
      player.firstTarget = partBuilder.firstTarget
      this.updateLyricElements()
    }
    this.vizVisitor = this.vizLibrary.createVizVisitor(vizKey, themeIndex)
    store.dispatch(sessionSlice.actions.setCurrentVisualizationSlug(vizKey))
    store.dispatch(
      vizParametersSlice.actions.setControls({
        activeVizKey: vizKey,
        controls: this.vizVisitor.controls,
      })
    )
    this.updateViz()
  }

  switchViz({ isSwitchingIn, vizSlug }: { isSwitchingIn: boolean; vizSlug: VizType }) {
    if (!player.isPlayMode) {
      return
    }
    this.switchVisualization(vizSlug)
    console.log(`switching ${isSwitchingIn ? 'in' : 'out'} [${vizSlug}]`)
    if (!isSwitchingIn) {
      setTimeout(() => {
        player.refreshTarget()
      }, 100)
    }
  }

  updateViz() {
    const offset = {
      x: player.interactionContainer['offsetWidth'] / 2,
      y: player.interactionContainer['offsetHeight'] / 2,
    }
    if (offset.x > 0) {
      this._offset = offset // HACK: remember our last non-zero half-width // TODO: fix?
    }
    if (this.vizVisitor) {
      this.vizVisitor.vizWillUpdate(this._offset)
      this.vizVisitor.currentTarget = player.target
      this.vizVisitor.traverse(this.sections)
    }
  }

  updatePartHTML(part: ModelPart) {
    const { isSection, isLine, referenceTime, time, elem } = part
    const timestamp = elem?.querySelector('.word-timestamp')
    if (timestamp) {
      const calcRelative = !(isSection || isLine) && player.isPlayMode && referenceTime != null
      const deltaTime = (time || 0) - (calcRelative ? referenceTime : 0)
      const clockSeconds = Util.secondsToClock(deltaTime, calcRelative)
      timestamp.innerHTML = time ? clockSeconds : ''
    }

    if (!this.isVizSwitchingScheduled) {
      player.resetPartTiming(part)
    }
  }

  updateLyricElements() {
    const { isEditMode, latencySeconds } = player
    const lineNums = document.getElementsByClassName('line-num')
    for (let i = 0; i < lineNums.length; i++) {
      lineNums[i].classList.toggle('hidden', !isEditMode)
    }
    const updateVisitor = {
      visitTrack: (isStart: boolean) => {},
      visitSection: (section: Section) => {
        this.updatePartHTML(section)
      },
      visitLine: (line: Line) => {
        this.updatePartHTML(line)
      },
      visitWord: (word: Word) => {
        if (word.time) {
          updateWordOnBlast(word, isEditMode, latencySeconds, player.trackPoints)
        } else {
          player.clearWord(word)
        }
        this.updatePartHTML(word)
      },
    }
    traverseLyrics(this.sections, updateVisitor)
  }

  _countTimedWords() {
    let numTimedWords = 0
    this.sections.forEach((section) => {
      section.lines.forEach((line) => {
        line.words.forEach(({ time }) => {
          if (time) {
            numTimedWords++
          }
        })
      })
    })
    return numTimedWords
  }

  updateLyrics(isTransferTimings: boolean, newLyrics: string) {
    this.isVizSwitchingScheduled = false

    const prevSections = this.sections
    const { sections, numWords, title } = splitLyrics(newLyrics)
    const initialNumTimedWords = this.timedWordCount
    const isPerformTransfer = isTransferTimings && prevSections.length && initialNumTimedWords > 0
    let updatedNumTimedWords = initialNumTimedWords
    if (isPerformTransfer) {
      const transcriber = new SongModelTranscriber(sections, prevSections)
      transcriber.transferWordTimes()
      updatedNumTimedWords = this._countTimedWords()
    }
    this.lyricsModel = {
      sections,
      title,
      numWords,
      numTimedWords: updatedNumTimedWords,
    }
  }
  // updatePartLabel(part: ModelPart) {
  //   const { isSection, isLine, elem, label } = part
  //   if (isSection) {
  //     if (elem) {
  //       elem.innerHTML = `<span>${label}</span>`
  //     }
  //   } else if (isLine) {
  //     // do nothing for now
  //   } else {
  //     const partLabel = elem?.querySelector('.word')
  //     if (partLabel) {
  //       partLabel.innerHTML = label
  //     }
  //   }
  // }
}

const vizBuilder = new LyricVizBuilder()
export default vizBuilder
