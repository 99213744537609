import { createSlice } from '@reduxjs/toolkit'

type VizStringControlParam = 'min' | 'max' | 'step'
type VizBooleanControlParam = 'isAnimateEnabled' | 'isAnimateRandom'
type VizControl = {
  initialValue: string | number
  min: string | number
  max: string | number
  step: string | number
  isAnimateEnabled: boolean
  isAnimateRandom: boolean
  label: string
  type: string
}
type VizParametersState = {
  controls: { [key: string]: VizControl }
  activeControlKey: string
  activeVizKey: string
}
const initialState: VizParametersState = {
  controls: {},
  activeControlKey: '',
  activeVizKey: 'page',
}

const vizParametersSlice = createSlice({
  name: 'vizParams',
  initialState,
  reducers: {
    setActiveVizKey: (state, action) => {
      state.activeVizKey = action.payload
    },
    setActiveControlKey: (state, action) => {
      state.activeControlKey = action.payload
    },
    setControls: (state, action) => {
      const { activeVizKey, controls } = action.payload
      state.controls = controls
      state.activeVizKey = activeVizKey
    },
    updateControlValue: (state, action) => {
      const { key, value } = action.payload
      state.controls[key].initialValue = value // TODO: rename to value
    },
    updateControlParameter: (state, action) => {
      const { controlKey, paramKey, value } = action.payload
      if (typeof value === 'boolean') {
        state.controls[controlKey][paramKey as VizBooleanControlParam] = value
      } else {
        state.controls[controlKey][paramKey as VizStringControlParam] = value
      }
    },
  },
})

export default vizParametersSlice
