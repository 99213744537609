import React from 'react'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../reducers'
import ModalBackdrop from './ModalBackdrop'

type Props = {
  onClose: () => void
}
const ErrorModal = ({ onClose }: Props) => {
  const trackError = useAppSelector((state: RootState) => state.session.trackError)
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer errorModal">
        <div className="modalHeading">Something went wrong...</div>
        <div className="modalBody">
          <div>{trackError}</div>
          <button onClick={onClose}>Reload</button>
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default ErrorModal
