import LyricVisitorBase from './LyricVisitorBase'
import { Line, Section, Word } from '../../types'

class TimeSpliceVisitor extends LyricVisitorBase {
  _sections: Section[]
  donor: TimeSpliceVisitor | undefined
  index = {
    section: -1,
    line: -1,
    word: -1,
  }
  _isTranscribing = false
  constructor(sections: Section[], donorSections?: Section[]) {
    super()
    this._sections = sections

    if (donorSections) {
      this.donor = new TimeSpliceVisitor(donorSections)
    }
  }

  startNextSection() {
    this.index.section++
    this.index.line = -1
    this.index.word = -1
    if (this.index.section >= this.sections.length) {
      this.endTrack()
    } else {
      this.startSection(this.sections[this.index.section])
    }
    return this.currentSection
  }

  startNextLine(): Line | null {
    let section = this.currentSection || this.startNextSection()
    if (section) {
      this.index.line++
      this.index.word = -1

      if (this.index.line >= section.lines.length) {
        this.endSection(section)
        this.startNextSection()
        return this.startNextLine()
      } else {
        const line = section.lines[this.index.line]
        this.startLine(line, section)
        return line
      }
    }
    return null
  }

  visitNextWord(): Word | null {
    let line = this.currentLine || this.startNextLine()
    if (line) {
      this.index.word++
      if (this.index.word >= line.words.length) {
        this.endLine(line, this.currentSection)
        this.startNextLine()
        return this.visitNextWord()
      } else {
        const word = line.words[this.index.word]
        this.visitWord(word, line, this.currentSection)
        return word
      }
    }
    return null
  }

  set isTranscribing(isOn) {
    this._isTranscribing = isOn
  }
  get isTranscribing() {
    return this._isTranscribing
  }
  get sections() {
    return this._sections
  }
  get currentSection() {
    return 0 <= this.index.section && this.index.section < this.sections.length
      ? this.sections[this.index.section]
      : undefined
  }
  get currentLine() {
    const currSection = this.currentSection
    return currSection && 0 <= this.index.line && this.index.line < currSection.lines.length
      ? currSection.lines[this.index.line]
      : null
  }
  get currentWord() {
    const currLine = this.currentLine
    return currLine && 0 <= this.index.word && this.index.word < currLine.words.length
      ? currLine.words[this.index.word]
      : null
  }

  startTrack() {
    if (this.donor) {
      this.donor.startTrack()
    }
  }
  endTrack() {}
  startSection(section: Section) {
    if (this.isTranscribing && this.donor) {
      const donorSection = this.donor.currentSection
      if (donorSection) {
        if (donorSection.time != null) {
          section.time = donorSection.time
        }
      }
    }
  }
  endSection(section: Section) {}

  startLine(line: Line, section: Section) {
    if (this.isTranscribing && this.donor) {
      const donorLine = this.donor.currentLine
      if (donorLine) {
        if (donorLine.time != null) {
          line.time = donorLine.time
        }
      }
    }
  }
  endLine(line: Line, section?: Section) {}

  visitWord(word: Word, line: Line, section?: Section) {
    if (this.isTranscribing && this.donor) {
      const donorWord = this.donor.currentWord
      if (donorWord) {
        if (donorWord.time != null) {
          word.time = donorWord.time
        }
      }
    }
  }
}

export default TimeSpliceVisitor
