import { createAsyncThunk } from '@reduxjs/toolkit'

import userLocal from '../../services/UserLocalStorage'
import localAuthoringSlice from '../../reducers/localAuthoringSlice'
import { selectCurrentTrackSlug } from '../../selectors/current-play-selectors'
import { RootState } from '../../reducers'

const initLocalAuthoringFromStorage = createAsyncThunk(
  'initLocalAuthoringFromStorage',
  (isReset: boolean, { dispatch, getState }) => {
    userLocal.validateAuthoringInfo(isReset)
    const { localTimingSlugs } = userLocal

    localTimingSlugs.forEach((slug: string) => {
      const timingInfo = userLocal.getTimingDataForSlug(slug)
      dispatch(localAuthoringSlice.actions.addOrUpdateTiming({ slug, timingInfo }))
    })
  }
)

const saveLocalTiming = createAsyncThunk(
  'saveLocalTiming',
  (
    {
      trackSlug,
      timing,
      scoreSeconds,
      timedWordCount = 0,
    }: { trackSlug: string; timing: string; scoreSeconds: number; timedWordCount?: number },
    { dispatch }
  ) => {
    const timingInfo = {
      slug: trackSlug,
      timing,
      timedWordCount,
      scoreSeconds,
      timestamp: Date.now(),
    }
    userLocal.addOrUpdateTiming(trackSlug, timingInfo)
    dispatch(localAuthoringSlice.actions.addOrUpdateTiming({ slug: trackSlug, timingInfo }))
  }
)

const removeLocalTiming = createAsyncThunk<void, void, { state: RootState }>(
  'removeLocalTiming',
  (_, { dispatch, getState }) => {
    const trackSlug = selectCurrentTrackSlug(getState())
    userLocal.removeTiming(trackSlug)
    dispatch(localAuthoringSlice.actions.removeTiming(trackSlug))
  }
)

export { initLocalAuthoringFromStorage, removeLocalTiming, saveLocalTiming }
