const { NODE_ENV } = process.env
const IS_LOCAL_DEV = NODE_ENV === 'development'
// eslint-disable-next-line no-undef
const env = LYRICBLASTER_ENV
const {
  VERSION,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  FIREBASE_AUDIENCE = 'local',
  OBFUSCATOR_KEY = 'abcXYZ',
  S3_ACCESS_ID,
  S3_ACCESS_SECRET,
  NODE_ASSETBASE,
  NODE_GA,
  SERVER_API_URL,
} = env

export {
  IS_LOCAL_DEV,
  VERSION,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  FIREBASE_AUDIENCE,
  OBFUSCATOR_KEY,
  S3_ACCESS_ID,
  S3_ACCESS_SECRET,
  NODE_ASSETBASE,
  NODE_GA,
  SERVER_API_URL,
}
