import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import sessionSlice from '../../reducers/sessionSlice'
import socialSlice from '../../reducers/socialSlice'
import { selectTrackFromPath } from '../../selectors/blaster-peer-selectors'
import { selectCurrentBlaster } from '../../selectors/session-selectors'
import history from '../../services/History'
import selectTrack from './selectTrack'

const loadTrackFromUri = createAsyncThunk<
  void,
  { trackPath: string },
  { state: RootState; dispatch: AppDispatch }
>('load/loadTrackFromUri', ({ trackPath }, { dispatch, getState }) => {
  const state = getState()
  dispatch(sessionSlice.actions.setCurrentTrackPath(trackPath))
  dispatch(sessionSlice.actions.setTrackError(''))
  const selectedTrackInfo = selectTrackFromPath(state, trackPath)
  history.push(trackPath)
  if (selectedTrackInfo) {
    const username = selectCurrentBlaster(state)
    const { matchSlug, trackInfo, playlistInfo } = selectedTrackInfo
    const { slug: trackSlug } = trackInfo
    const { slug: playlistSlug, owner } = playlistInfo
    dispatch(selectTrack({ playlistInfo, trackInfo, matchSlug }))
    dispatch(
      socialSlice.actions.revealTrack({ username, matchSlug, playlistSlug, trackSlug, owner })
    )
    // dispatch(
    //   socialSlice.actions.revealTrack({
    //     isLeft: true,
    //     username,
    //     matchSlug,
    //     playlistSlug,
    //     trackSlug,
    //     owner,
    //   })
    // )
  } else if (trackPath.length > 1) {
    const trackError = `couldn't find track at: ${trackPath}`
    dispatch(sessionSlice.actions.setTrackError(trackError))
  }
})

export default loadTrackFromUri
