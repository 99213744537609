import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  BLASTER_URL_PREFIX,
  DEFAULT_PLAYLIST_KEY,
  defaultTrackInfo,
} from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import sessionSlice from '../../reducers/sessionSlice'
import socialSlice from '../../reducers/socialSlice'
import { selectOwnedMatchSlug } from '../../selectors/blaster-peer-selectors'
import {
  selectCurrentPlaylistSlug,
  selectCurrentTrackSlug,
} from '../../selectors/current-play-selectors'
import { selectPeerPlaylistInfo } from '../../selectors/match-selectors'
import { selectCurrentMatchSlug } from '../../selectors/session-selectors'
import history from '../../services/History'
import player from '../../services/Player'
import Util from '../../util/util'
import { updateLocalTrack } from '../authoring/update-local-track'
import { switchActiveBlaster } from '../social/leaderboard-actions'
import setCurrentPlaylist from './setCurrentPlaylist'
import trackLoaded from './trackLoaded'

const newTrack = createAsyncThunk<void, void, { state: RootState; dispatch: AppDispatch }>(
  'load/newTrack',
  (_, { dispatch, getState }) => {
    player.stop()
    const state = getState()
    const compoundMatchSlug = selectOwnedMatchSlug(state)
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const activeMatchSlug = selectCurrentMatchSlug(state)
    const playlistSlug = selectCurrentPlaylistSlug(state)
    const trackSlug = selectCurrentTrackSlug(state) // TODO: make this all one selector
    const defaultPlaylistInfo = selectPeerPlaylistInfo(
      matchOwner,
      matchSlug,
      DEFAULT_PLAYLIST_KEY
    )(state)

    dispatch(
      socialSlice.actions.clearActiveTrack({
        matchOwner,
        matchSlug: activeMatchSlug,
        playlistSlug,
        trackSlug,
      })
    )
    player.stop()
    player.clearTiming()
    dispatch(currentPlaySlice.actions.setIsPlayable(false))
    const newTrackSlug = Util.generateId()
    const trackInfo = {
      ...defaultTrackInfo,
      owner: matchOwner,
      slug: newTrackSlug,
    }
    dispatch(setCurrentPlaylist({ matchSlug, playlistInfo: defaultPlaylistInfo }))
    if (matchSlug !== activeMatchSlug) {
      dispatch(switchActiveBlaster({ newBlasterSlug: matchOwner }))
    }

    dispatch(
      updateLocalTrack({
        trackSlug: newTrackSlug,
        audioData: { title: trackInfo.title, artist: trackInfo.artist },
      })
    )
    dispatch(trackLoaded({ trackInfo, isLocal: true, isNew: true }))
    dispatch(
      socialSlice.actions.toggleTrackExpanded({
        matchOwner,
        matchSlug: activeMatchSlug,
        playlistSlug,
        trackSlug,
        isExpanded: false,
      })
    )
    player.playerBuffer = null
    const trackPath = `/${BLASTER_URL_PREFIX}/${matchSlug}/${DEFAULT_PLAYLIST_KEY}/${newTrackSlug}`
    history.push(trackPath)
    dispatch(sessionSlice.actions.setCurrentTrackPath(trackPath))
    dispatch(
      socialSlice.actions.revealTrack({
        matchOwner,
        matchSlug,
        playlistSlug: DEFAULT_PLAYLIST_KEY,
        trackSlug: newTrackSlug,
        owner: matchOwner,
      })
    )
  }
)

export default newTrack
