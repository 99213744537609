import { createAsyncThunk } from '@reduxjs/toolkit'

import { BLASTER_URL_PREFIX } from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import {
  selectCurrentPlaylistInfo,
  selectCurrentTrackSlug,
} from '../../selectors/current-play-selectors'
import { selectCurrentMatchSlug } from '../../selectors/session-selectors'
import loadTrackFromUri from './loadTrackFromUri'

/*
  Load the "next" track based on criteria
    For now, support simply next/previous within the current playlist
      next loops back to the first track, prev sticks at 0 (tbd whether that asymmetry works)
 */
const loadNextTrack = createAsyncThunk<
  void,
  { isPrevious?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('load/nextTrack', ({ isPrevious = false }, { dispatch, getState }) => {
  const state = getState()
  const matchSlug = selectCurrentMatchSlug(state)
  const { slug: playlistSlug, trackOrder = [] } = selectCurrentPlaylistInfo(state)
  const currentTrackSlug = selectCurrentTrackSlug(state)
  const currentTrackIndex = trackOrder.indexOf(currentTrackSlug)
  const nextTrackIndex = isPrevious
    ? Math.max(0, currentTrackIndex - 1)
    : (currentTrackIndex + 1) % trackOrder.length
  const nextTrackSlug = trackOrder[nextTrackIndex]
  const trackPath = `/${BLASTER_URL_PREFIX}/${matchSlug}/${playlistSlug}/${nextTrackSlug}`

  dispatch(loadTrackFromUri({ trackPath }))
})

export default loadNextTrack
