import getState from '../../reducers/'
import { Line, ModelPart, Point, Section, VizVisitorBaseProps } from '../../types'
import VizVisitorBase from './VizVisitorBase'

class AnimatingVizVisitor extends VizVisitorBase {
  zoom: number
  step: number
  constructor(props: VizVisitorBaseProps) {
    super(props)

    this.zoom = props.zoom
    this.controls.zoom = {
      type: 'text',
      label: 'Zoom',
      initialValue: this.zoom,
      step: props.zoomStep,
      min: this.zoom - props.zoomRange,
      max: this.zoom + props.zoomRange,
      isAnimateEnabled: true,
    }

    this.step = props.step
    this.controls.step = {
      type: 'text',
      label: 'Step',
      initialValue: this.step,
      step: 1,
      min: 1,
      max: 20,
    }
  }

  visitSection(section: Section) {
    super.visitSection(section)
  }

  visitLine(line: Line, section: Section) {
    super.visitLine(line, section)
  }

  vizWillUpdate(offset: Point) {
    super.vizWillUpdate(offset)
    const {
      step: { initialValue: step },
      zoom: { initialValue: zoom },
    } = getState().vizParams.controls

    this.step = step as number
    this.zoom = zoom as number
  }

  tick(part?: ModelPart) {
    super.tick(part)
    const { zoom: zoomControl, step: stepControl } = getState().vizParams.controls
    {
      const { min, max, step, initialValue, isAnimateEnabled } = zoomControl
      if (isAnimateEnabled) {
        const steppedZoom = (initialValue as number) + (step as number)
        if (min >= steppedZoom || steppedZoom >= max) {
          this.updateControlStep('zoom', -step)
        }
        this.updateControlValue('zoom', steppedZoom)
      }
    }
    {
      const { min, max, step, initialValue, isAnimateEnabled } = stepControl
      if (isAnimateEnabled) {
        const steppedStep = (initialValue as number) + (step as number)
        if (min >= steppedStep || steppedStep >= max) {
          this.updateControlStep('step', -step)
        }
        this.updateControlValue('step', steppedStep)
      }
    }
  }
}

export default AnimatingVizVisitor
