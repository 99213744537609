import { createSlice } from '@reduxjs/toolkit'

type Transcript = { text: string; isFrom: boolean }
type TranscriptMap = { [key: string]: Transcript[] }
const initialState: TranscriptMap = {}

const chatTranscriptSlice = createSlice({
  name: 'chatTranscript',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { username, text, isFrom } = action.payload

      const currUserTranscript = state[username] || []
      const newTranscript = {
        ...state,
        [username]: [...currUserTranscript, { text, isFrom }],
      }
      return newTranscript
    },
  },
})

export default chatTranscriptSlice
