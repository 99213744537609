import keyBindings from '../services/KeyBindings'

// Approximately equal function to deal with floating point variances
// const approx = (lhs, rhs) => Math.abs(rhs - lhs) < 1

// based on http://en.wikipedia.org/wiki/Smoothstep
const smoothStep = (start: number, end: number, point: number) => {
  if (point <= start) {
    return 0
  }
  if (point >= end) {
    return 1
  }
  const x = (point - start) / (end - start) // interpolation
  return x * x * (3 - 2 * x)
}
const SMOOTHSCROLL_MILLIS = 100

const smoothScroll = (element: HTMLElement, target: number, isHoriz?: boolean) => {
  target = Math.round(target)

  const start_time = Date.now()
  const end_time = start_time + SMOOTHSCROLL_MILLIS
  const start_edge = isHoriz ? element.scrollLeft : element.scrollTop
  const distance = target - start_edge

  return new Promise<void>((resolve, reject) => {
    const scrollFrame = () => {
      const now = Date.now()
      const point = smoothStep(start_time, end_time, now)
      const frameEdge = Math.round(start_edge + distance * point)
      if (isHoriz) {
        element.scrollLeft = frameEdge
      } else {
        element.scrollTop = frameEdge
      }
      // check if we're done!
      if (now >= end_time) {
        resolve()
        return
      }
      setTimeout(scrollFrame, 0) // schedule next frame for execution
    }
    // boostrap the animation process
    setTimeout(scrollFrame, 0)
  })
}
const smoothscrollH = (element: HTMLElement, target: number) => {
  smoothScroll(element, target, true)
}

// Handles auto-scrolling when target box moves outside the interaction container
const autoScroll = (container: HTMLElement, targetElem?: HTMLElement | null) => {
  // Check for null elements i.e. top of song, scroll to top
  if (targetElem === null || targetElem === undefined) {
    smoothScroll(container, 0)
    return
  }

  // Get pixel coordinates of the interaction area and target element
  const scrollRect = container.getBoundingClientRect()
  const targetRect = targetElem.getBoundingClientRect()
  const marginV = (scrollRect.bottom - scrollRect.top) * 0.2

  // Check Vertical
  // Target is too far down: scroll down
  let deltaV = targetRect.bottom - (scrollRect.bottom - marginV)
  if (deltaV >= 0) {
    smoothScroll(container, container.scrollTop + deltaV)
    // console.log(`scrolled down by ${deltaV}`)
  } else {
    // Target is too far up: scroll back up
    deltaV = scrollRect.top + marginV - targetRect.top
    if (deltaV >= 0) {
      smoothScroll(container, container.scrollTop - deltaV)
      // console.log(`scrolled up by ${deltaV}`)
    }
  }
  // Check Horizontal
  const marginH = (scrollRect.right - scrollRect.left) * 0.2
  // Target is too far right: scroll right
  let deltaH = targetRect.right - (scrollRect.right - marginH)
  if (deltaH >= 0) {
    smoothscrollH(container, container.scrollLeft + deltaH)
    // console.log(`scrolled right by ${deltaH}`)
  } else {
    // Target is too far left: scroll back left
    deltaH = scrollRect.left + marginH - targetRect.left
    if (deltaH >= 0) {
      smoothscrollH(container, container.scrollLeft - deltaH)
      // console.log(`scrolled left by ${deltaH}`)
    }
  }
}

const onModalContainerRef = (container: HTMLDivElement | null) => {
  keyBindings.onModalContainerRef(container)
}

export { autoScroll, onModalContainerRef }
