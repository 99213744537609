import cx from 'classnames'
import React from 'react'

import switchUserMode from '../../actions/switchUserMode'
import { selectIsEditable, selectIsPlayable } from '../../selectors/current-play-selectors'
import { selectModeFlags, selectPrefs } from '../../selectors/session-selectors'
import userManager from '../../services/UserManager'
import { ModeType } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'

const ModeSwitch = () => {
  const dispatch = useAppDispatch()
  const { isVizMode, isPlayMode, isEditMode, isSpellMode } = useAppSelector(selectModeFlags)
  const isEditable = useAppSelector(selectIsEditable)
  const isPlayable = useAppSelector(selectIsPlayable)
  const { defaultMode } = useAppSelector(selectPrefs)
  const [modePref] = defaultMode.split('-')

  const onModeChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newMode = (event.target as HTMLButtonElement).name as ModeType
    dispatch(switchUserMode(newMode))
    if (modePref === 'last') {
      userManager.defaultMode = `last-${newMode}`
    }
  }
  const playButtonClassname = cx('pushButton', { active: isPlayMode, disabled: !isPlayable })
  const vizButtonClassname = cx('pushButton', { active: isVizMode, disabled: !isPlayable })
  const editButtonClassname = cx('pushButton', { active: isEditMode, disabled: !isEditable })
  const spellButtonClassname = cx('pushButton', { active: isSpellMode, disabled: !isPlayable })
  return (
    <div className="mode-selector">
      <button
        name="viz"
        className={vizButtonClassname}
        disabled={!isPlayable}
        onClick={onModeChange}
      >
        Viz
      </button>
      <button
        name="spell"
        className={spellButtonClassname}
        disabled={!isPlayable}
        onClick={onModeChange}
      >
        Spell
      </button>
      <button
        name="play"
        className={playButtonClassname}
        disabled={!isPlayable}
        onClick={onModeChange}
      >
        Blast
      </button>
      {isEditable && (
        <button
          name="edit"
          className={editButtonClassname}
          disabled={!isEditable}
          onClick={onModeChange}
        >
          Mod
        </button>
      )}
    </div>
  )
}

export default ModeSwitch
