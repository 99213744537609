import getState from '../../reducers/'
import AnimatingVizVisitor from './AnimatingVizVisitor'
import { Line, ModelPart, Point, Section, VizVisitorBaseProps, Word } from '../../types'

type WaveVizVisitorProps = VizVisitorBaseProps & {
  amplitude: number
  frequency: number
}
class WaveVizVisitor extends AnimatingVizVisitor {
  amplitude = 0
  frequency = 0
  degrees = 0
  theta = 0
  constructor(props: WaveVizVisitorProps) {
    super(props)

    this.amplitude = props.amplitude
    this.frequency = props.frequency
    this.degrees = 0

    this.controls.amplitude = {
      type: 'text',
      label: 'Height',
      initialValue: this.amplitude,
      step: 0.3,
      min: 1.0,
      max: 5.0,
    }

    this.controls.frequency = {
      type: 'text',
      label: 'Frequency',
      initialValue: this.frequency * 2,
      min: 1,
      max: 8,
    }
  }

  tick(part?: ModelPart) {
    super.tick(part)
    const { amplitude } = getState().vizParams.controls
    const { max, step, initialValue, isAnimateEnabled } = amplitude
    if (isAnimateEnabled) {
      const steppedAmplitude = (initialValue as number) + (step as number)
      if (Math.abs(steppedAmplitude) >= max) {
        this.updateControlStep('zoom', -step)
      }
      this.updateControlValue('amplitude', steppedAmplitude)
    }
  }

  vizWillUpdate(offset: Point) {
    super.vizWillUpdate(offset)
    const {
      amplitude: { initialValue: amplitude },
      frequency: { initialValue: frequency },
    } = getState().vizParams.controls
    this.degrees = -90
    this.amplitude = amplitude as number
    this.frequency = (frequency as number) / 2.0
  }

  visitSection(section: Section) {
    super.visitSection(section)
  }

  visitLine(line: Line, section: Section) {
    super.visitLine(line, section)
  }

  visitWord(word: Word, line: Line, section: Section) {
    this.degrees += this.step
    this.theta = (this.degrees * Math.PI) / 180
    const x = this.zoom * this.theta + this.offset.x / 2
    const y = -this.zoom * this.amplitude * Math.cos(this.frequency * this.theta) + this.offset.y

    if (word.elem) {
      word.elem.style.top = y + 'px'
      word.elem.style.left = x + 'px'
      // word.elem.style.transform = 'rotate(15deg)';
      word.elem.style.zIndex = String(this.degrees % 360)
    }
    this.setWordColor(word, line, section)
  }
}

export default WaveVizVisitor
