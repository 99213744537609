import cx from 'classnames'
import React from 'react'

import { PlayStatus } from '../../types'

type Props = {
  scoreInfo: PlayStatus
  isUseTopScore?: boolean
  isShowRank?: boolean
  isAuthoring?: boolean
  isMini?: boolean
  isHeader?: boolean
  isActive?: boolean
}
const ScoreProgressBar = ({
  scoreInfo,
  isUseTopScore = false,
  isAuthoring = false,
  isMini,
  isHeader,
  isActive,
  isShowRank = false,
}: Props) => {
  const {
    maxScore,
    numElements,
    doneCount,
    score,
    errorCount,
    runningScoreRank,
    topScore,
    topScoreRank,
  } = scoreInfo
  const progressRank =
    isAuthoring || (!isUseTopScore && runningScoreRank) ? runningScoreRank : topScoreRank
  const isCurrentScoreTopScore = !!score && !!topScore && score === topScore

  const progressScore = isUseTopScore ? topScore : score
  const errorText = errorCount ? `/ ${errorCount}` : ''
  const numeratorText = isAuthoring ? String(doneCount) : String(progressScore)
  const topScoreMarker = isCurrentScoreTopScore && isUseTopScore && !isHeader ? '!' : ''
  const denominatorText = isAuthoring ? `${String(numElements)} ${errorText}` : String(maxScore)
  const progressText = `${numeratorText}${topScoreMarker}${isMini ? '' : ' / '}${
    isMini ? '' : denominatorText
  }`
  const [maxWidth, setMaxWidth] = React.useState(0)
  const setWrapperRef = (elem: HTMLDivElement) => {
    if (elem) {
      setMaxWidth(elem.clientWidth)
    }
  }
  const progressDone = isUseTopScore ? numElements : doneCount
  const newWidth = numElements ? Math.min(progressDone / numElements, 1) * maxWidth : 0
  const style = { width: `${newWidth}px` }
  const classOptions = {
    mini: isMini,
    header: isHeader,
    active: isActive,
  }
  const wrapperClassName = cx('chatScoreProgressWrapper', classOptions)
  const progressClassName = cx('chatScoreProgress', {
    ...classOptions,
    [`${progressRank || 'none'}`]: isShowRank,
  })
  const scoreClassName = cx('chatScore', classOptions)
  return (
    <div ref={setWrapperRef} className={wrapperClassName}>
      <div className={progressClassName} style={style}></div>
      {progressText && <div className={scoreClassName}>{progressText}</div>}
      {/*{progressText && <div className={scoreClassName}>{'10345 / 12147'}</div>}*/}
    </div>
  )
}

const LapProgressBar = ({ timeSpent, lapLength }: { timeSpent: number; lapLength: number }) => {
  const [maxWidth, setMaxWidth] = React.useState(0)
  if (!lapLength) {
    return null
  }
  const setWrapperRef = (elem: HTMLDivElement) => {
    if (elem) {
      setMaxWidth(elem.clientWidth)
    }
  }
  const numCompletedLaps = Math.floor(timeSpent / lapLength)
  const currentLapProgress = timeSpent - lapLength * numCompletedLaps
  const numLapsToShow = numCompletedLaps + (currentLapProgress > 0 ? 1 : 0)
  if (!numLapsToShow) {
    return null
  }
  const lapWidth = maxWidth / numLapsToShow
  const lapWidths = Array(numCompletedLaps).fill(lapWidth)
  const newWidth = (currentLapProgress / lapLength) * lapWidth
  lapWidths.push(newWidth)
  return (
    <div ref={setWrapperRef} className="chatClockProgressWrapper">
      {lapWidths.map((lapWidth, index) => {
        const style = { width: `${lapWidth}px` }
        const className = cx('chatClockProgress', {
          progressLapComplete: index < lapWidths.length - 1,
        })
        return <div key={`clockProgress-${index}`} className={className} style={style}></div>
      })}
    </div>
  )
}

export { LapProgressBar, ScoreProgressBar }
