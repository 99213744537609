import cx from 'classnames'
import React, {
  ChangeEvent,
  createRef,
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { BLASTER_URL_PREFIX } from '../../constants/constants'
import { useAppSelector } from '../../hooks'
import {
  selectCurrentPlaylistSlug,
  selectCurrentTrackSlug,
} from '../../selectors/current-play-selectors'
import {
  selectMatchInfo,
  selectMatchTracks,
  selectTrackStats,
} from '../../selectors/match-selectors'
import {
  selectAudience,
  selectCurrentBlaster,
  selectCurrentMatchSlug,
  selectCurrentUsername,
} from '../../selectors/session-selectors'
import keyBindings from '../../services/KeyBindings'
import { TopScoreStat, TrackInfo } from '../../types'
import { onModalContainerRef } from '../../util/scrolling'
import { getTrackArtistAndTitle } from '../../util/track-utils'
import Util from '../../util/util'
import CloseIcon from '../widgets/CloseIcon'
import { SelectWithLabel } from '../widgets/TextInputWithLabel'
import ModalBackdrop from './ModalBackdrop'

type Props = {
  onClose: () => void
}
type TrackGridInfo = {
  isDisabled: boolean
  className: string
  trackInfo?: TrackInfo
}

const SQUARES_PER_ROW = 4

const TrackGalleryModal = ({ onClose }: Props) => {
  const isPubMode = useAppSelector(selectAudience) === 'pub'
  const compoundMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const currentPlaylistSlug = useAppSelector(selectCurrentPlaylistSlug)
  const currentBlaster = useAppSelector(selectCurrentBlaster)
  const username = useAppSelector(selectCurrentUsername)
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const currentBlasterIsGuest = username === matchOwner && currentBlaster !== matchOwner
  const { title: matchTitle } = useAppSelector(selectMatchInfo(matchOwner, matchSlug))
  const matchTracksBySet = useAppSelector(selectMatchTracks(compoundMatchSlug))
  const matchTrackStats = useAppSelector(selectTrackStats({ matchOwner, matchSlug }))
  const currentSetIndex = matchTracksBySet.findIndex((setInfo) => {
    return setInfo.slug === currentPlaylistSlug
  })
  const [trackSetIndex, setTrackSetIndex] = useState(Math.max(0, currentSetIndex))
  const tracksRef = createRef<HTMLDivElement>()
  const [selectedGridRow, setSelectedGridRow] = useState(0)
  const [selectedGridCol, setSelectedGridCol] = useState(0)
  const [trackGridInfo, setTrackGridInfo] = useState<TrackGridInfo[]>([])

  const trackSetMenuItems = matchTracksBySet.map(({ slug, title }) => {
    return { value: slug, name: title }
  })
  const { tracks, slug: playlistSlug, perTrackPlayLimit } = matchTracksBySet[trackSetIndex]
  const currTrackSlug = useAppSelector(selectCurrentTrackSlug)
  const navCallback = useCallback(
    (horizStep: number, vertStep: number) => {
      const numSquares = trackGridInfo.length
      const numRows = numSquares / SQUARES_PER_ROW
      const selectedIndex = selectedGridRow * SQUARES_PER_ROW + selectedGridCol
      let gotoCol = selectedGridCol
      let gotoRow = selectedGridRow
      let gotoNode = null
      while (!gotoNode) {
        if (horizStep > 0) {
          if (gotoCol === SQUARES_PER_ROW - 1) {
            gotoCol = 0
            gotoRow = gotoRow === numRows - 1 ? 0 : gotoRow + 1
          } else {
            gotoCol++
          }
        } else if (horizStep < 0) {
          if (gotoCol === 0) {
            gotoCol = SQUARES_PER_ROW - 1
            gotoRow = gotoRow === 0 ? numRows - 1 : gotoRow - 1
          } else {
            gotoCol--
          }
        } else if (vertStep > 0) {
          if (gotoRow === numRows - 1) {
            gotoRow = 0
            gotoCol = gotoCol === SQUARES_PER_ROW - 1 ? 0 : gotoCol + 1
          } else {
            gotoRow++
          }
        } else if (vertStep < 0) {
          if (gotoRow === 0) {
            gotoRow = numRows - 1
            gotoCol = gotoCol === 0 ? SQUARES_PER_ROW - 1 : gotoCol - 1
          } else {
            gotoRow--
          }
        }
        const gotoIndex = gotoRow * SQUARES_PER_ROW + gotoCol
        const nthSquare = trackGridInfo[gotoIndex]
        if (nthSquare.isDisabled) {
          if (gotoIndex === selectedIndex) {
            break // should never be back where we started
          }
        } else {
          gotoNode = tracksRef.current?.childNodes[gotoIndex] as HTMLAnchorElement
        }
      }
      setSelectedGridCol(gotoCol)
      setSelectedGridRow(gotoRow)
    },
    [selectedGridRow, selectedGridCol, tracksRef, trackGridInfo]
  )
  useEffect(() => {
    const gotoIndex = selectedGridRow * SQUARES_PER_ROW + selectedGridCol
    const gotoNode = tracksRef.current?.childNodes[gotoIndex] as HTMLAnchorElement
    gotoNode?.focus()
  }, [selectedGridRow, selectedGridCol, trackGridInfo])

  useEffect(() => {
    const onKeyDown = ({ keyCode }: KeyboardEvent) => {
      const vertStep = keyCode === 40 ? 1 : keyCode === 38 ? -1 : 0
      const horizStep = keyCode === 39 ? 1 : keyCode === 37 ? -1 : 0
      navCallback(horizStep, vertStep)
    }
    window.addEventListener('keydown', onKeyDown)
    keyBindings._navCallback = navCallback

    return () => {
      keyBindings._navCallback = null
      window.removeEventListener('keydown', onKeyDown)
    }
  })
  const isCheckPlaylimit = isPubMode && currentBlasterIsGuest && perTrackPlayLimit !== undefined
  useEffect(() => {
    const trackGrid: TrackGridInfo[] = tracks.map((trackInfo) => {
      const { slug: trackSlug } = trackInfo
      const isCurrentTrack = trackSlug === currTrackSlug
      const { playerTopScores } = matchTrackStats[trackSlug] || {
        playerTopScores: [],
      }
      const currentBlasterHasPlayed = playerTopScores.some(({ playerId }) => {
        return currentBlaster === playerId
      })
      const isDisabled =
        isCheckPlaylimit && (currentBlasterHasPlayed || playerTopScores.length >= perTrackPlayLimit)
      return {
        isDisabled: isDisabled || isCurrentTrack,
        className: '',
        trackInfo,
      }
    })
    const numSquaresToAdd = (SQUARES_PER_ROW - (tracks.length % SQUARES_PER_ROW)) % SQUARES_PER_ROW
    for (let i = 0; i < numSquaresToAdd; i++) {
      trackGrid.push({ isDisabled: true, className: 'blastButton' })
    }
    setTrackGridInfo(trackGrid)
  }, [trackSetIndex, perTrackPlayLimit, currentBlaster, isCheckPlaylimit])
  // TODO: missing dependencies on tracks and matchTrackStats, but adding either of these causes infinite loop

  // if (!matchTracksBySet.length) {
  //   return null
  // }
  const getTracks = () => {
    if (trackSetIndex >= matchTracksBySet.length) {
      return null
    }
    const trackGrid = trackGridInfo.map(({ className, isDisabled, trackInfo }, index) => {
      if (!trackInfo) {
        return (
          <div key={`emptySquare-${index}`} className="emptySquare">
            <button className="blastButton" tabIndex={-1} />
          </div>
        )
      }
      const { slug: trackSlug, duration, wordCount } = trackInfo
      const { playerTopScores, username } = matchTrackStats[trackSlug] || {
        playerTopScores: [],
        username: '',
      }
      const { title, artist } = getTrackArtistAndTitle(trackInfo)
      const durationClockTime = Util.secondsToClock(duration, false, false)
      const pointsPerTrack = Math.floor((10 * wordCount) / duration)
      const pointsPerTrackStr = duration > 0 ? pointsPerTrack : '-'
      const pointsStr = duration > 0 ? pointsPerTrack * wordCount : '-'
      const durationStr = duration > 0 ? durationClockTime : '-'
      const divider = <span>{' / '}</span>
      const toPath = `/${BLASTER_URL_PREFIX}/${compoundMatchSlug}/${playlistSlug}/${trackSlug}`
      const getTopScoreBadge = () => {
        return playerTopScores.map((topScoreStat: TopScoreStat) => {
          const { topScoreRank, topScore, playerId, playerName } = topScoreStat
          const topScoreClassName = cx('topScore', { [topScoreRank]: true })
          return (
            <div key={`${username}-${playerId}`} className={topScoreClassName} title={playerName}>
              {topScore}
            </div>
          )
        })
      }
      const TrackDiv = () => (
        <div className="track">
          <div className="title">{title}</div>
          <div className="artist">{artist}</div>
          <div className="matchNodeInfo">
            <div className="score">
              <div className="points">{pointsStr}</div>
              <div className="topScores">{getTopScoreBadge()}</div>
            </div>
            <div className="pointInfo">
              <span>{durationStr}</span>
              {divider}
              <span>{wordCount}</span>
              {divider}
              <span>{pointsPerTrackStr}</span>
            </div>
          </div>
        </div>
      )
      if (isDisabled) {
        return (
          <div key={trackSlug} className="disabled">
            <TrackDiv></TrackDiv>
          </div>
        )
      }
      return (
        <Link key={trackSlug} to={toPath}>
          <TrackDiv></TrackDiv>
        </Link>
      )
    })
    return trackGrid
  }
  const onSwitchTrackset = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSetIndex = event.target.selectedIndex
    if (newSetIndex >= 0) {
      setTrackSetIndex(newSetIndex)
      setSelectedGridRow(0)
      setSelectedGridCol(0)
    }
  }
  const onKeyDown = (event: ReactKeyboardEvent<HTMLSelectElement>) => {
    // handle up / down arrows only
    const step = event.keyCode === 40 ? 1 : event.keyCode === 38 ? -1 : 0
    if (step !== 0) {
      const currTarget = event.currentTarget
      const nextIndex = Math.min(
        Math.max(0, currTarget.selectedIndex + step),
        currTarget.options.length - 1
      )
      const nextValue = currTarget.options[nextIndex].value
      currTarget.selectedIndex = nextIndex
      currTarget.value = nextValue
      setTrackSetIndex(nextIndex)
      event.stopPropagation()
    }
  }
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer trackGalleryModal" ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">
          <SelectWithLabel
            label=""
            itemArray={trackSetMenuItems}
            onChange={onSwitchTrackset}
            value={currentPlaylistSlug}
            onKeyDown={onKeyDown}
            isDisableTabIndex={isPubMode}
          />
          <div>{matchTitle}</div>
        </div>
        <div className="tracks" ref={tracksRef}>
          {getTracks()}
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default TrackGalleryModal
