import React, { ChangeEvent, createRef, KeyboardEvent, useState } from 'react'

import { cancelEditTarget, updateEditTarget } from '../../actions/authoring/lyric-editing'
import { selectCurrentLyrics } from '../../selectors/current-play-selectors'
import { useAppDispatch, useAppSelector } from '../../hooks'

type Props = {
  selectedRange: { start: number; end: number }
}
const LyricEditModal = ({ selectedRange }: Props) => {
  const dispatch = useAppDispatch()
  const lyrics = useAppSelector(selectCurrentLyrics)
  const loadUrlRef = createRef<HTMLInputElement>()
  const lyricTextRef = createRef<HTMLTextAreaElement>()
  const [isInited, setIsInited] = useState(false)
  React.useEffect(() => {
    const lyricTextElem = lyricTextRef.current
    if (lyricTextElem && !isInited) {
      const textBeforePosition = lyrics.substr(0, selectedRange.start)
      lyricTextElem.blur()
      lyricTextElem.value = textBeforePosition
      lyricTextElem.focus()
      lyricTextElem.value = lyrics
      lyricTextElem.setSelectionRange(selectedRange.start, selectedRange.end)
      setIsInited(true)
    }
  }, [lyrics, lyricTextRef, isInited, selectedRange.start, selectedRange.end])
  const onLoadUrlChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target || !event.target.files) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = function (event) {
      if (!event.target) {
        return
      }
      console.log('loaded')
      if (lyricTextRef.current) {
        lyricTextRef.current.value = String(event.target.result)
      }
      // TODO: implement dirty
    }
    reader.readAsText(file)
  }
  const onSave = () => {
    if (lyricTextRef.current) {
      dispatch(updateEditTarget({ newText: lyricTextRef.current.value }))
    }
  }
  const onCancel = () => {
    dispatch(cancelEditTarget())
  }
  const onLoad = () => {
    if (loadUrlRef.current) {
      loadUrlRef.current.click()
    }
  }
  const onKeydown = (event: KeyboardEvent) => {
    let handled = true

    switch (event.keyCode) {
      case 83: // 's' key
        if (event.metaKey) {
          onSave()
        } else {
          handled = false
        }
        break

      case 27: // Esc key
        onCancel()
        break

      default:
        handled = false
    }

    if (handled) {
      event.preventDefault()
    }
    if (handled || !event.ctrlKey) {
      event.stopPropagation()
    }
  }

  return (
    <div className="lyricEditContainer">
      <div className="lyricEntry">
        <textarea className="lyricEditText" ref={lyricTextRef} onKeyDown={onKeydown}></textarea>
      </div>
      <div>
        <button id="lyricSaveButton" onClick={onSave}>
          Save
        </button>
        <button id="lyricCancelButton" onClick={onCancel}>
          Cancel
        </button>
        <button id="lyricLoadButton" onClick={onLoad}>
          Load...
        </button>
        <input
          id="lyricLoadUrl"
          type="file"
          ref={loadUrlRef}
          className="hidden"
          tabIndex={-1}
          onChange={onLoadUrlChanged}
        />
      </div>
    </div>
  )
}

export default LyricEditModal
