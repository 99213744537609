import { createAsyncThunk } from '@reduxjs/toolkit'

import { PLAYLIST_TYPES } from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectBlasterPeer } from '../../selectors/blaster-peer-selectors'
import { PlaylistInfo } from '../../types'

const setCurrentPlaylist = createAsyncThunk<
  void,
  { matchSlug: string; playlistInfo: PlaylistInfo },
  { state: RootState; dispatch: AppDispatch }
>(
  'setCurrentPlaylist',
  ({ matchSlug: compoundMatchSlug, playlistInfo }, { dispatch, getState }) => {
    const { owner } = playlistInfo
    const state = getState()
    const [matchOwner] = compoundMatchSlug.split('/')
    const blasterPeer = selectBlasterPeer(matchOwner)(state)
    const { isCurrentUser } = blasterPeer
    const prefix = isCurrentUser ? PLAYLIST_TYPES.OWNER : owner
    dispatch(sessionSlice.actions.setCurrentMatchSlug(compoundMatchSlug))
    dispatch(
      currentPlaySlice.actions.setPlaylistInfo({
        ...playlistInfo,
        prefix,
      })
    )
  }
)

export default setCurrentPlaylist
