import React, { ChangeEvent } from 'react'
import { uploadAllDirty } from '../../actions/authoring/upload-actions'
import CloseIcon from '../widgets/CloseIcon'
import ModalBackdrop from './ModalBackdrop'
import { selectDirtyState } from '../../selectors/current-play-selectors'
import { UploadHelpers } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'

type Props = {
  onClose: () => void
  uploadHelpers: UploadHelpers
}
const SyncModal = ({ uploadHelpers, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const actualDirtyState = useAppSelector(selectDirtyState)
  const [dirtyState, setDirtyState] = React.useState(actualDirtyState)
  const [isOverriding, setIsOverriding] = React.useState(false)
  const onAudioDirty = (event: ChangeEvent<HTMLInputElement>) => {
    setDirtyState({ ...dirtyState, isAudioDirty: event.target.checked })
    setIsOverriding(true)
  }
  const onImageDirty = (event: ChangeEvent<HTMLInputElement>) => {
    setDirtyState({ ...dirtyState, isImageDirty: event.target.checked })
    setIsOverriding(true)
  }
  const onInfoDirty = (event: ChangeEvent<HTMLInputElement>) => {
    setDirtyState({ ...dirtyState, isInfoDirty: event.target.checked })
    setIsOverriding(true)
  }
  const onLyricsDirty = (event: ChangeEvent<HTMLInputElement>) => {
    setDirtyState({ ...dirtyState, isLyricsDirty: event.target.checked })
    setIsOverriding(true)
  }
  const onTimingDirty = (event: ChangeEvent<HTMLInputElement>) => {
    setDirtyState({ ...dirtyState, isTimingDirty: event.target.checked })
    setIsOverriding(true)
  }
  const effectiveDirtyState = isOverriding ? dirtyState : actualDirtyState
  const onUploadClicked = () => {
    dispatch(uploadAllDirty({ uploadHelpers, effectiveDirtyState })).then(() => {
      setIsOverriding(false)
      setDirtyState(actualDirtyState)
    })
  }
  const { isAudioDirty, isInfoDirty, isLyricsDirty, isTimingDirty, isImageDirty } =
    effectiveDirtyState
  const isDirty = isAudioDirty || isInfoDirty || isLyricsDirty || isTimingDirty

  return (
    <ModalBackdrop isTop>
      <div className="saveContainer">
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">Sync...</div>
        <div className="localControls">
          <button id="upload" disabled={!isDirty} onClick={onUploadClicked}>
            Upload
          </button>
          <div className="dirty-div">
            <div>
              <input type="checkbox" id="info-dirty" checked={isInfoDirty} onChange={onInfoDirty} />
              <label htmlFor="info-dirty">Info</label>
              <div id="manifest-uploadProgressWrapper" className="uploadProgressWrapper">
                <div id="manifest-uploadProgress" className="uploadProgress"></div>
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="lyrics-dirty"
                checked={isLyricsDirty}
                onChange={onLyricsDirty}
              />
              <label htmlFor="lyrics-dirty">Lyrics</label>
              <div id="lyrics-uploadProgressWrapper" className="uploadProgressWrapper">
                <div id="lyrics-uploadProgress" className="uploadProgress"></div>
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="timing-dirty"
                checked={isTimingDirty}
                onChange={onTimingDirty}
              />
              <label htmlFor="timing-dirty">Timing</label>
              <div id="timing-uploadProgressWrapper" className="uploadProgressWrapper">
                <div id="timing-uploadProgress" className="uploadProgress"></div>
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="audio-dirty"
                checked={isAudioDirty}
                onChange={onAudioDirty}
              />
              <label htmlFor="audio-dirty">Audio</label>
              <div id="audio-uploadProgressWrapper" className="uploadProgressWrapper">
                <div id="audio-uploadProgress" className="uploadProgress"></div>
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="image-dirty"
                checked={isImageDirty}
                onChange={onImageDirty}
              />
              <label htmlFor="audio-dirty">Image</label>
              <div id="image-uploadProgressWrapper" className="uploadProgressWrapper">
                <div id="image-uploadProgress" className="uploadProgress"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default SyncModal
