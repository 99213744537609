import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { Rank, rankEnum } from '../../constants/constants'
import { resyncOwnedMatchStatus } from '../../actions/social/leaderboard-actions'
import { useAppDispatch } from '../../hooks'
import player from '../../services/Player'
import Util from '../../util/util'
import { LapProgressBar, ScoreProgressBar } from './ProgressBar'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PlayStatus } from '../../types'

export type BlastRecordDetails = {
  title: JSX.Element
  isActive: boolean
  isOwnedMatch: boolean
  isMatch: boolean
  duration: number
}

type Props = {
  details: BlastRecordDetails
  playStatus: PlayStatus
  link: null | JSX.Element
  isLeft: boolean
}
const BlastDetails = ({ details, playStatus, link, isLeft }: Props) => {
  const dispatch = useAppDispatch()
  const [isShowRefreshSpinner, setIsShowRefreshSpinner] = React.useState(false)
  const { title, isActive, duration, isOwnedMatch, isMatch } = details
  const { scoreSeconds, counters } = playStatus
  const clockValue = Util.secondsToClock(scoreSeconds)

  const getCounters = () => {
    if (isOwnedMatch && isMatch) {
      return null
    }
    return rankEnum.map((rank) => {
      const key = `counter-${rank}`
      const rankCount = counters[rank as Rank]
      return (
        <div key={key}>
          <input className={rank} type="text" tabIndex={-1} readOnly value={rankCount} />
        </div>
      )
    })
  }
  const getTitle = () => {
    if (link) {
      return link
    }
    return (
      <div className="chatMatchOrPlaylistInfo">
        <span>{title}</span>
      </div>
    )
  }
  const getAudienceTotalsButton = () => {
    const updateTotals = () => {
      setIsShowRefreshSpinner(true)
      dispatch(resyncOwnedMatchStatus()).then(() => {
        setIsShowRefreshSpinner(false)
      })
    }
    return (
      <div className="chatScoreSectionWrapper" onClick={updateTotals}>
        {isShowRefreshSpinner ? (
          <FontAwesomeIcon
            className="fas fa-spinner fa-pulse"
            size="sm"
            icon={faSpinner as IconProp}
            title="Refresh"
          />
        ) : (
          <span className="caption">audience totals</span>
        )}
      </div>
    )
  }

  const setScoreClockRef = (elem: HTMLInputElement) => {
    if (isActive) {
      player[`scoreClock${isLeft ? 'Left' : 'Right'}`] = elem
    }
  }
  return (
    <React.Fragment>
      <div className="chatScoreSectionWrapper">{getTitle()}</div>
      <div className="chatScoreSectionWrapper">
        <ScoreProgressBar scoreInfo={playStatus} isShowRank={false} isAuthoring={isOwnedMatch} />
      </div>
      <React.Fragment>
        {isOwnedMatch && getAudienceTotalsButton()}
        <div className="chatScoreBoxWrapper">{getCounters()}</div>
        {!isOwnedMatch && (
          <div className="scoreSectionWrapper">
            <div className="chatScoreSectionWrapper">
              <input
                type="text"
                className="timer"
                ref={setScoreClockRef}
                readOnly
                value={clockValue}
                tabIndex={-1}
              />
              <LapProgressBar timeSpent={scoreSeconds} lapLength={duration} />
            </div>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default BlastDetails
