import { createSlice } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'

export interface LeaderboardState {
  [matchOwner: string]: {
    [matchSlug: string]: Object
  }
}
const initialState: LeaderboardState = {}
const leaderboardPlaySlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    updateLeaderboard: (state, action) => {
      const { matchOwner, matchSlug, leaderboard } = action.payload
      const updatedLeaderboard = {
        [matchOwner]: {
          [matchSlug]: leaderboard,
        },
      }
      const result = mergeDeepRight(state, updatedLeaderboard)
      return result
    },
  },
})

export default leaderboardPlaySlice
