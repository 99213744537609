import { startEdit } from '../../actions/authoring/lyric-editing'
import { handleSectionClick, handleWordClick } from '../../actions/play-actions'
import { DEFAULT_TARGET } from '../../constants/constants'
import { isMobile } from '../../util/track-utils'
import player from '../../services/Player'
import { Line, Section, Target, Word } from '../../types'
import { LyricVisitorBaseSimple } from './LyricVisitorBase'

class HtmlCreateVisitor extends LyricVisitorBaseSimple {
  _firstTarget: Target
  _partKey: string

  constructor(partKey = '') {
    super()
    this._firstTarget = DEFAULT_TARGET
    this._partKey = partKey
  }

  get partKey() {
    return this._partKey
  }

  set partKey(newKey) {
    this._partKey = newKey
  }

  get firstTarget() {
    return this._firstTarget
  }

  set firstTarget(target) {
    this._firstTarget = target
  }

  visitTrack(isStart: boolean) {
    const trackElem = document.createElement('div')
    trackElem.classList.add('bumper')
    trackElem.addEventListener('dblclick', function (evt) {
      player.dispatch(startEdit(DEFAULT_TARGET))
      evt.stopPropagation()
    })

    player.interactionContainer.appendChild(trackElem)
    if (isStart) {
      player.trackElem = trackElem
    }
  }

  visitSection(section: Section) {
    const sectionContainer = document.createElement('div')
    sectionContainer.classList.add('section', this.partKey)
    const sectionElem = document.createElement('div')
    const wordContainer: HTMLDivElement = document.createElement('div')
    wordContainer.classList.add('lines', this.partKey)
    sectionContainer.appendChild(sectionElem)
    sectionContainer.appendChild(wordContainer)

    // sectionElem.isSection = true
    sectionElem.id = 'item-' + section.index
    sectionElem.innerHTML = this.makeSectionHtml(section)
    sectionElem.classList.add('label', this.partKey)
    if (!player.isShowSections) {
      sectionElem.classList.add('mini')
    }

    sectionElem.addEventListener('click', function (evt) {
      player.dispatch(handleSectionClick(section.index))
    })

    sectionElem.addEventListener('dblclick', function (evt) {
      const sectionTarget = {
        sectionIndex: section.index,
        lineIndex: -1,
        wordIndex: -1,
        elem: sectionElem,
      }
      player.dispatch(startEdit(sectionTarget))
    })

    player.interactionContainer.appendChild(sectionContainer)

    section.container = wordContainer
    section.elem = sectionContainer
    section.time = 0.0
    // section.eventID = -1
  }

  makeSectionHtml(section: Section) {
    const hide = player.displayTimes ? '' : ' hidden'
    const sectionHtml = `<span>${section.label}</span><span class="word-timestamp ${hide}"></span>`
    return sectionHtml
  }

  makeLineHtml(line: Line) {
    const lineHtml = `<label class="line-num">${line.index + 1}</label>`
    return lineHtml
  }

  makeWordHtml(word: Word) {
    const hide = player.displayTimes ? '' : ' hidden'
    const wordHTML = `<div class="word">${word.label}</div><div class="word-timestamp ${hide}"></div>`
    return wordHTML
  }

  visitLine(line: Line, section: Section) {
    const lineElem = document.createElement('div')

    // lineElem.isLine = true
    lineElem.id = 'item-' + section.index + '-' + line.index
    lineElem.innerHTML = this.makeLineHtml(line)
    lineElem.classList.add('line', this.partKey)

    lineElem.addEventListener('dblclick', function (evt) {
      const target = {
        sectionIndex: section.index,
        lineIndex: line.index,
        wordIndex: -1,
        elem: lineElem,
      }
      player.dispatch(startEdit(target))
    })

    line.elem = lineElem
    line.time = null

    section.container?.appendChild(lineElem)
  }

  visitWord(word: Word, line: Line, section: Section) {
    const wordElem = document.createElement('div')
    wordElem.id = 'item-' + section.index + '-' + line.index + '-' + word.index
    wordElem.innerHTML = this.makeWordHtml(word)
    wordElem.classList.add('word-container', this.partKey)

    const wordTarget = {
      sectionIndex: section.index,
      lineIndex: line.index,
      wordIndex: word.index,
      elem: wordElem,
    }
    wordElem.addEventListener('click', function (evt) {
      player.dispatch(handleWordClick(wordTarget))
    })

    function _mouseDown(evt: Event) {
      evt.preventDefault()
      player.isDragging = true
    }
    wordElem.addEventListener('mousedown', _mouseDown.bind(this))
    if (isMobile()) {
      // https://www.chromestatus.com/feature/5745543795965952
      wordElem.addEventListener('touchstart', _mouseDown.bind(this))
    }

    function _mouseEnter(evt: Event) {
      evt.preventDefault()
      if (player.isDragging) {
        player.handleWordScrub(wordTarget)
      }
    }
    wordElem.addEventListener('mouseenter', _mouseEnter.bind(this))

    function _mouseUp() {
      player.isDragging = false
    }
    wordElem.addEventListener('mouseup', _mouseUp.bind(this))
    if (isMobile()) {
      // wordElem.addEventListener('touchend', _mouseUp.bind(this))
    }
    wordElem.addEventListener('dblclick', function (evt) {
      player.dispatch(startEdit(wordTarget))
      evt.stopPropagation()
    })

    line.elem?.appendChild(wordElem)
    word.elem = wordElem // TODO: other?

    if (!this.firstTarget.elem) {
      this.firstTarget = {
        sectionIndex: section.index,
        lineIndex: line.index,
        wordIndex: word.index,
        elem: wordElem,
      }
    }
  }
}

export default HtmlCreateVisitor
