import themes from '../components/themes'
import { VizMap, VizType } from '../types'
import AnimatingVizVisitor from './viz/AnimatingVizVisitor'
import { FlowerVisitor, SpiralVisitor } from './viz/PolarVizVisitors'
import RollingVizVisitor from './viz/RollingVizVisitor'
import WaveVizVisitor from './viz/WaveVizVisitor'
import VizVisitorBase from './viz/VizVisitorBase'

class LyricVizLibrary {
  config: VizMap = {
    page: {
      class: VizVisitorBase,
    },
    text1: {
      class: RollingVizVisitor,
      nudge: { x: -50, y: -110 },
      passedLinesColor: '#ECE9BB',
      activeLineColor: '#0004FF',
      aheadLinesColor: '#ac77bf',
      amplitude: 0,
      frequency: 1,
      step: 10,
      zoom: 10,
      zoomRange: 10,
      zoomStep: 0.1,
    },
    spiral: {
      class: SpiralVisitor,
      step: 10,
      zoom: 10,
      zoomRange: 10,
      zoomStep: 0.1,
    },
    flower: {
      class: FlowerVisitor,
      nudge: { x: -50, y: -30 },
      step: 3,
      zoom: 460,
      zoomRange: 40,
      zoomStep: 1,
      thetaMultiplier: 3,
      exponent: 1,
    },
    wave: {
      class: WaveVizVisitor,
      nudge: { x: -200, y: 0 },
      step: 3,
      zoom: 80,
      zoomRange: 40,
      zoomStep: 1,
      amplitude: 3,
      frequency: 1,
    },
  }

  createVizVisitor(vizKey: VizType, themeIndex: number): AnimatingVizVisitor {
    const props = { ...this.config[vizKey] }
    const theme = themes[themeIndex]
    const {
      cssVars: { 'color-txt-lyrics': textColor },
    } = theme
    props.activeWordColor = props.activeWordColor || textColor
    props.passedLinesColor = props.passedLinesColor || textColor
    props.activeLineColor = props.activeLineColor || textColor
    props.aheadLinesColor = props.aheadLinesColor || textColor

    const vizVisitor = new props.class(props)
    return vizVisitor
  }
}

export default LyricVizLibrary
