import { makePartId } from './split-lyrics'
import { Line, ModelPart, Section, Target, Word } from '../../types'
import { defaultModelPart } from '../../constants/constants'

const getPartIndex = (part: ModelPart) => {
  const { index, elem, isSection, isLine } = part
  if (isSection) {
    return { sectionIndex: index, lineIndex: -1, wordIndex: -1, elem }
  }
  if (isLine) {
    const { sectionIndex } = part as Line
    return { sectionIndex, lineIndex: index, wordIndex: -1, elem }
  }
  const { sectionIndex, lineIndex } = part as Word
  return { sectionIndex, lineIndex, wordIndex: index, elem }
}

const getPartByIndex = (index: Target, sections: Section[]) => {
  const { sectionIndex, lineIndex, wordIndex } = index
  let part = null

  try {
    if (wordIndex > -1) {
      part = sections[sectionIndex].lines[lineIndex].words[wordIndex]
    } else if (lineIndex > -1) {
      part = sections[sectionIndex].lines[lineIndex]
    } else if (sectionIndex > -1) {
      part = sections[sectionIndex]
    }
  } catch (e) {
    // lazy way of checking indices
  }
  return part
}

const getNextWord = (currTarget: Target, sections: Section[]): Target => {
  let { sectionIndex = -1, lineIndex = -1, wordIndex = -1 } = currTarget
  if (sectionIndex < 0) {
    sectionIndex = 0
  }
  let currSection = null
  let currLine = null
  let elem = null
  let found = false
  while (!found && sectionIndex < sections.length) {
    currSection = sections[sectionIndex]
    const numLines = currSection.lines.length
    if (!numLines) {
      sectionIndex++
      lineIndex = 0
      wordIndex = -1
      continue
    }
    currLine = currSection.lines[lineIndex]
    const numWords = currLine.words.length
    wordIndex++
    if (wordIndex < numWords) {
      found = true
      elem = currLine.words[wordIndex].elem
      // _autoScroll(elem); // TODO: needed?
      break
    } else {
      wordIndex = -1
      lineIndex++
      if (lineIndex >= numLines) {
        sectionIndex++
        lineIndex = 0
      }
    }
  }
  const id = makePartId(sectionIndex, lineIndex, wordIndex)
  return { sectionIndex, lineIndex, wordIndex, id, elem }
}

const getPrevWord = (currTarget: Target, sections: Section[]): Target => {
  let { sectionIndex, lineIndex, wordIndex } = currTarget
  let currSection = null
  let currLine = null
  let found = false
  let elem = null
  if (sectionIndex >= sections.length) {
    sectionIndex = sections.length - 1
    lineIndex = -1
    wordIndex = -1
  }
  while (!found && sectionIndex >= 0) {
    currSection = sections[sectionIndex]
    const numLines = currSection.lines.length
    if (!numLines) {
      sectionIndex--
      lineIndex = -1
      wordIndex = -1
      continue
    }
    if (lineIndex === -1) {
      lineIndex = numLines - 1
    }
    currLine = currSection.lines[lineIndex]
    const numWords = currLine.words.length
    if (wordIndex === -1) {
      wordIndex = numWords
    }
    wordIndex--
    if (wordIndex >= 0) {
      found = true
      elem = currLine.words[wordIndex].elem
      // _autoScroll(elem); // TODO: needed?
      break
    } else {
      wordIndex = -1
      lineIndex--
      if (lineIndex < 0) {
        sectionIndex--
      }
    }
  }
  const id = makePartId(sectionIndex, lineIndex, wordIndex)
  return { sectionIndex, lineIndex, wordIndex, id, elem }
}

const getNextLine = (currTarget: Target, sections: Section[]): Target => {
  let { sectionIndex = -1, lineIndex = -1, wordIndex = -1 } = currTarget
  let currSection = null
  let elem = null
  let found = false
  if (sectionIndex < 0) {
    sectionIndex = 0
  }
  while (!found && sectionIndex < sections.length) {
    currSection = sections[sectionIndex]
    const numLines = currSection.lines.length
    if (!numLines) {
      sectionIndex++
      lineIndex = -1
      wordIndex = -1
      continue
    }
    lineIndex++
    if (lineIndex < numLines) {
      wordIndex = 0
      found = true
      elem = currSection.lines[lineIndex].words[wordIndex].elem
      // _autoScroll(elem); // TODO: needed?
      break
    } else {
      sectionIndex++
      lineIndex = -1
    }
  }
  const id = makePartId(sectionIndex, lineIndex, wordIndex)
  return { sectionIndex, lineIndex, wordIndex, id, elem }
}

const getPrevLine = (currTarget: Target, sections: Section[]): Target => {
  let { sectionIndex, lineIndex, wordIndex } = currTarget
  let found = false
  let elem = null
  let currSection = null
  if (sectionIndex >= sections.length) {
    sectionIndex = sections.length - 1
    lineIndex = -1
    wordIndex = -1
  }
  while (!found && sectionIndex >= 0) {
    currSection = sections[sectionIndex]
    if (lineIndex < 0) {
      lineIndex = currSection.lines.length
    }
    if (lineIndex === 0) {
      sectionIndex--
      lineIndex = -1
      wordIndex = -1
      // if (sectionIndex < 0) {
      //     _autoScroll(elem);
      // }
      continue
    }
    lineIndex--
    wordIndex = 0 // TODO: try to stay in same place horizontally?
    found = true
    elem = currSection.lines[lineIndex].words[wordIndex].elem
    // _autoScroll(elem); // TODO: needed?
    break
  }
  const id = makePartId(sectionIndex, lineIndex, wordIndex)
  return { sectionIndex, lineIndex, wordIndex, id, elem }
}

const timeToWord = (seconds: number, sections: Section[], getAsReference?: boolean) => {
  let deltaSeconds = -9999,
    prevDelta = -9999
  let target: Word,
    prevTarget = null,
    closestTarget = null

  for (let i = 0; i < sections.length; ++i) {
    const section = sections[i]

    for (let j = 0; j < section.lines.length; ++j) {
      const line = section.lines[j]

      for (let k = 0; k < line.words.length; ++k) {
        const word = line.words[k]
        target = {
          ...defaultModelPart,
          sectionIndex: i,
          lineIndex: j,
          index: k,
          elem: word.elem,
        }
        const wordTime = getAsReference ? word.referenceTime : word.time
        if (wordTime) {
          deltaSeconds = wordTime - seconds
          if (Math.abs(deltaSeconds) < Math.abs(prevDelta)) {
            closestTarget = Object.assign({}, target)
          }
        }
        if (prevDelta * deltaSeconds < 0) {
          return prevTarget
        }

        prevTarget = Object.assign({}, target)
        prevDelta = deltaSeconds
      }
    }
  }
  return closestTarget
}

// const isLastWordInLine = (sections, target) => {
//   const { sectionIndex, lineIndex, wordIndex, isLine, isSection } = target
//   if (isLine || isSection) {
//     return false
//   }
//   const line = sections[sectionIndex].lines[lineIndex]
//   return wordIndex === line.words.length - 1
// }

const isZerothPart = (target: ModelPart) => {
  const { index, isLine, isSection } = target
  if (isSection) {
    return index === 0
  }
  if (isLine) {
    const { sectionIndex } = target as Line
    return sectionIndex === 0 && index === 0
  }
  const { sectionIndex, lineIndex } = target as Word
  return sectionIndex === 0 && lineIndex === 0 && index === 0
}

const getTimeForPart = (part: ModelPart, isUseReference: boolean): number | null => {
  const { time, referenceTime } = part
  const maybeTime = isUseReference ? referenceTime : time
  if (!maybeTime) {
    const { lines } = part as Section
    const { words } = part as Line
    if (lines && lines.length) {
      return getTimeForPart(lines[0], isUseReference)
    }
    if (words && words.length) {
      return getTimeForPart(words[0], isUseReference)
    }
  }
  return maybeTime
}

export {
  getNextLine,
  getNextWord,
  getPartByIndex,
  getPartIndex,
  getPrevWord,
  getPrevLine,
  getTimeForPart,
  isZerothPart,
  timeToWord,
}
