export const MESSAGES = {
  ON: 'Gamepad detected.',
  OFF: 'Gamepad disconnected.',
  INVALID_PROPERTY: 'Invalid property.',
  INVALID_VALUE_NUMBER: 'Invalid value. It must be a number between 0.00 and 1.00.',
  INVALID_BUTTON: 'Button does not exist.',
  UNKNOWN_EVENT: 'Unknown event name.',
  NO_SUPPORT: 'Your web browser does not support the Gamepad API.',
}

const emptyEvents = () => ({ action: () => {}, after: () => {}, before: () => {} })
type Callback = () => void
type GamepadEvents = {
  action: Callback
  after: Callback
  before: Callback
}
type Direction = 'up' | 'down' | 'right' | 'left'
type AxisEvents = {
  [key in Direction]: GamepadEvents
}
type CallbackType = 'action' | 'after' | 'before'
class Gamepad {
  id: string
  index: number
  buttons: number
  axes: number
  axeValues: [number, number][]
  axeThreshold: number[]
  hapticActuator: GamepadHapticActuator | null
  vibrationMode = -1
  vibration = false
  mapping: GamepadMappingType
  buttonActions: GamepadEvents[]
  axesActions: AxisEvents[]
  pressed: { [key: string]: boolean }

  constructor(gpad: globalThis.Gamepad) {
    this.id = gpad.id
    this.index = gpad.index
    this.buttons = gpad.buttons.length
    this.axes = Math.floor(gpad.axes.length / 2)
    this.axeValues = []
    this.axeThreshold = [1.0]
    this.hapticActuator = null
    this.mapping = gpad.mapping
    this.buttonActions = []
    this.pressed = {}
    this.axesActions = []
    for (let x = 0; x < this.buttons; x++) {
      this.buttonActions[x] = emptyEvents()
    }
    for (let x = 0; x < this.axes; x++) {
      this.axesActions[x] = {
        down: emptyEvents(),
        left: emptyEvents(),
        right: emptyEvents(),
        up: emptyEvents(),
      }
      this.axeValues[x] = [0, 0]
    }

    // check if vibration actuator exists
    // if (gpad.hapticActuators) {
    //   // newer standard
    //   if (typeof gpad.hapticActuators[0].pulse === 'function') {
    //     this.hapticActuator = gpad.hapticActuators[0]
    //     this.vibrationMode = 0
    //     this.vibration = true
    //   } else if (gpad.hapticActuators[0] && typeof gpad.hapticActuators[0].pulse === 'function') {
    //     this.hapticActuator = gpad.hapticActuators[0]
    //     this.vibrationMode = 0
    //     this.vibration = true
    //   }
    // }
  }

  // set(property: string, value: number) {
  //   const properties = ['axeThreshold']
  //   if (properties.indexOf(property) >= 0) {
  //     if (property === 'axeThreshold' && (!parseFloat(value) || value < 0.0 || value > 1.0)) {
  //       console.error(MESSAGES.INVALID_VALUE_NUMBER)
  //       return
  //     }
  //     this[property] = value
  //   } else {
  //     console.error(MESSAGES.INVALID_PROPERTY)
  //   }
  // }

  // vibrate(value = 0.75, duration = 500) {
  //   if (this.hapticActuator) {
  //     switch (this.vibrationMode) {
  //       case 0:
  //         return this.hapticActuator.pulse(value, duration)
  //       case 1:
  //         return this.hapticActuator.playEffect('dual-rumble', {
  //           duration: duration,
  //           strongMagnitude: value,
  //           weakMagnitude: value,
  //         })
  //     }
  //   }
  // }

  triggerDirectionalAction(
    id: Direction,
    axe: number,
    condition: boolean,
    x: number,
    index: number
  ) {
    if (condition && x % 2 === index) {
      if (!this.pressed[`${id}${axe}`]) {
        this.pressed[`${id}${axe}`] = true
        this.axesActions[axe][id].before()
      }
      this.axesActions[axe][id].action()
    } else if (this.pressed[`${id}${axe}`] && x % 2 === index) {
      delete this.pressed[`${id}${axe}`]
      this.axesActions[axe][id].after()
    }
  }

  checkStatus() {
    const gps = navigator.getGamepads ? navigator.getGamepads() : []
    const gp = gps ? gps[this.index] : null
    if (gp) {
      if (gp.buttons) {
        for (let x = 0; x < this.buttons; x++) {
          if (gp.buttons[x].pressed === true) {
            if (!this.pressed[`button${x}`]) {
              this.pressed[`button${x}`] = true
              this.buttonActions[x].before()
            }
            this.buttonActions[x].action()
          } else if (this.pressed[`button${x}`]) {
            delete this.pressed[`button${x}`]
            this.buttonActions[x].after()
          }
        }
      }
      if (gp.axes) {
        const modifier = gp.axes.length % 2 // Firefox hack: detects one additional axe
        for (let x = 0; x < this.axes * 2; x++) {
          const val = parseFloat(gp.axes[x + modifier].toFixed(4))
          const axe = Math.floor(x / 2)
          this.axeValues[axe][x % 2] = val

          this.triggerDirectionalAction('right', axe, val >= this.axeThreshold[0], x, 0)
          this.triggerDirectionalAction('left', axe, val <= -this.axeThreshold[0], x, 0)
          this.triggerDirectionalAction('down', axe, val >= this.axeThreshold[0], x, 1)
          this.triggerDirectionalAction('up', axe, val <= -this.axeThreshold[0], x, 1)
        }
      }
    }
  }
  associateEvent(eventName: string, callback: Callback, type: CallbackType) {
    const buttonMatch = eventName.match(/^button(\d+)$/)
    if (buttonMatch) {
      const buttonId = parseInt(buttonMatch[1])
      if (buttonId >= 0 && buttonId < this.buttons) {
        this.buttonActions[buttonId][type] = callback
      } else {
        console.log(`Unsupported button ${eventName}`)
      }
    } else if (eventName === 'start') {
      this.buttonActions[9][type] = callback
    } else if (eventName === 'select') {
      this.buttonActions[8][type] = callback
    } else if (eventName === 'r1') {
      this.buttonActions[5][type] = callback
    } else if (eventName === 'r2') {
      this.buttonActions[7][type] = callback
    } else if (eventName === 'l1') {
      this.buttonActions[4][type] = callback
    } else if (eventName === 'l2') {
      this.buttonActions[6][type] = callback
    } else if (eventName === 'power') {
      if (this.buttons >= 17) {
        this.buttonActions[16][type] = callback
      } else {
        console.error(MESSAGES.INVALID_BUTTON)
      }
      return
    }
    const axisMatch = eventName.match(/^(up|down|left|right)(\d+)$/)
    if (axisMatch) {
      const direction = axisMatch[1] as Direction
      const axe = parseInt(axisMatch[2])
      if (axe >= 0 && axe < this.axes) {
        this.axesActions[axe][direction][type] = callback
      } else {
        console.error(MESSAGES.INVALID_BUTTON)
      }
      return
    }
    const singleAxisMatch = eventName.match(/^(up|down|left|right)(\d+)$/)
    if (singleAxisMatch) {
      const direction = singleAxisMatch[1] as Direction
      this.axesActions[0][direction][type] = callback
    }
  }
  on(eventName: string, callback: Callback) {
    return this.associateEvent(eventName, callback, 'action')
  }
  off(eventName: string) {
    return this.associateEvent(eventName, function () {}, 'action')
  }
  after(eventName: string, callback: Callback) {
    return this.associateEvent(eventName, callback, 'after')
  }
  before(eventName: string, callback: Callback) {
    return this.associateEvent(eventName, callback, 'before')
  }
}

export default Gamepad
