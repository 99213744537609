import React, { ReactNode, MouseEvent } from 'react'
import { useAppSelector } from '../../hooks'
import { selectIsAnySidebarModalOpen } from '../../reducers/modalsSlice'

type Props = { children?: ReactNode; isTop?: boolean; onClick?: (event: MouseEvent) => void }
export const ModalBackdrop = ({ children, isTop, onClick }: Props) => {
  const isAnySidebarModalOpen = useAppSelector(selectIsAnySidebarModalOpen)
  const backdropRef = React.createRef<HTMLDivElement>()
  const handleOnClick = (event: MouseEvent) => {
    if (!onClick || isAnySidebarModalOpen) {
      event.stopPropagation()
      return
    }
    const isBackdropEvent = event && event.target === backdropRef.current
    if (isBackdropEvent) {
      onClick(event)
    }
  }

  return (
    <div
      ref={backdropRef}
      className="modalBackdropContainer"
      style={{
        justifyContent: 'center',
        alignItems: isTop ? 'start' : 'center',
      }}
      onClick={handleOnClick}
    >
      {children}
    </div>
  )
}

export default ModalBackdrop
