import { faAngleDown, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { pathOr } from 'ramda'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  BLASTER_URL_PREFIX,
  defaultMatchScore,
  defaultPlaylistInfo,
  defaultTrackInfo,
  HOST_VISIBILITY_DEMO,
} from '../../constants/constants'
import modalsSlice from '../../reducers/modalsSlice'
import socialSlice from '../../reducers/socialSlice'
import { selectOwnedMatchSlug } from '../../selectors/blaster-peer-selectors'
import {
  selectIsLocalPlaylistDirty,
  selectIsLocalTrackDirty,
} from '../../selectors/local-authoring-selectors'
import { selectCurrentUsername, selectCurrentBlaster } from '../../selectors/session-selectors'
import {
  selectIsUserTrackExpanded,
  selectIsContainerActive,
  selectIsShowMatchPlayers,
} from '../../selectors/social-selectors'
import { getTrackArtistAndTitle } from '../../util/track-utils'
import Util from '../../util/util'
import BlastDetails from './BlastDetails'
import { ScoreProgressBar } from './ProgressBar'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MatchInfo, PlaylistInfo, PlayStatus, TrackInfo } from '../../types'

const noOp = () => {}

type TitleProps = {
  isUser: boolean
  userTitle: string
  matchInfo: MatchInfo
  playlistInfo: PlaylistInfo
  trackInfo: TrackInfo
}

type Props = {
  username: string
  userRank?: number
  matchInfo: MatchInfo
  playlistInfo?: PlaylistInfo
  trackInfo?: TrackInfo
  onToggleChildren?: () => void
  isChildrenVisible?: boolean
  matchStatus?: PlayStatus
  isLeft: boolean
}
const BlastRecord = ({
  username,
  userRank = 0,
  matchInfo,
  playlistInfo = defaultPlaylistInfo,
  trackInfo = defaultTrackInfo,
  onToggleChildren = noOp,
  isChildrenVisible,
  matchStatus = defaultMatchScore(),
  isLeft,
}: Props) => {
  const dispatch = useAppDispatch()
  const { slug: matchSlug, hostVisibility = HOST_VISIBILITY_DEMO } = matchInfo
  const [matchOwner] = matchSlug.split('/')
  const isHostDemoPlayer = username === matchOwner && hostVisibility === HOST_VISIBILITY_DEMO
  const { slug: playlistSlug, trackOrder = [] } = playlistInfo
  const { slug: trackSlug, wordCount, duration: trackDuration } = trackInfo
  const ownedMatchSlug = useAppSelector(selectOwnedMatchSlug)
  const isShowMatchPlayers = useAppSelector(selectIsShowMatchPlayers({ isLeft, matchSlug }))
  const currentUsername = useAppSelector(selectCurrentUsername)
  const currentBlaster = useAppSelector(selectCurrentBlaster) || currentUsername
  const currentPlayStatus = useAppSelector((state) => {
    const result = pathOr(null, [username, matchSlug, playlistSlug, trackSlug], state.scoreStatus)
    return result
  })
  const isCurrentUser = currentBlaster === username
  const isOwnedMatch = matchSlug === ownedMatchSlug
  const isUser = userRank > 0
  const localTrackSlug = isOwnedMatch && !!trackSlug ? trackSlug : ''
  const localTrackOrder = isOwnedMatch && !!playlistSlug ? trackOrder : []
  const isLocalTrackDirty = useAppSelector(selectIsLocalTrackDirty(localTrackSlug))
  const isLocalPlaylistDirty = useAppSelector(selectIsLocalPlaylistDirty(localTrackOrder))
  const isDirty = !!trackSlug ? isLocalTrackDirty : !!playlistSlug ? isLocalPlaylistDirty : false
  const isActive = useAppSelector(
    selectIsContainerActive({ isLeft, username, matchSlug, playlistSlug, trackSlug })
  )
  // const isShowActiveScore = useAppSelector(
  //   selectIsShowUserTrackActiveScore({ isLeft, username, matchSlug, playlistSlug, trackSlug })
  // )
  // const isShowCurrentPlayStatus = isShowActiveScore && !isOwnedMatch
  const [isShowCurrentPlayStatus, setIsShowCurrentPlayStatus] = useState(false)
  const userTitle = isShowMatchPlayers ? username : ''
  const playStatus = isShowCurrentPlayStatus
    ? currentPlayStatus || defaultMatchScore()
    : matchStatus
  const { trackDuration: playStatusTrackDuration, numElements: playStatusWordCount } = playStatus
  const duration = trackDuration || playStatusTrackDuration
  // const numElements = playStatusWordCount || wordCount // TODO: order important, make clearer
  const [isExpanded, setIsExpanded] = useState(isChildrenVisible)
  const isShowDetails = useAppSelector(
    selectIsUserTrackExpanded({ isLeft, username, matchSlug, playlistSlug, trackSlug })
  )
  const chatSongWrapper = cx({
    chatSongWrapper: true,
    matchWrapper: !playlistSlug && !trackSlug,
    playlistWrapper: playlistSlug && !trackSlug,
    active: isActive,
    dirty: isDirty,
    hostPlayer: isHostDemoPlayer && isShowMatchPlayers && !isOwnedMatch,
    currPlayer: isOwnedMatch || (isCurrentUser && !isHostDemoPlayer),
  })
  React.useEffect(() => {
    setIsExpanded(isChildrenVisible)
  }, [isChildrenVisible])
  const getExpander = () => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (trackSlug) {
        toggleDetails(event)
      } else {
        toggleChildren(event)
      }
    }

    const isRowOpen = trackSlug ? isShowDetails : isExpanded
    const title = `${isRowOpen ? 'Collapse' : 'Expand'} ${isUser ? 'Player' : 'Set'}`
    if (!playlistSlug) {
      const className = cx('chatTreeExpander', { isExpanded: isRowOpen })
      return (
        <button tabIndex={-1} className={className} onClick={onClick} title={title}>
          {userRank}
        </button>
      )
    }
    if (!trackSlug) {
      return (
        <button tabIndex={-1} className="chatTreeExpander" onClick={onClick} title={title}>
          <FontAwesomeIcon icon={(isRowOpen ? faAngleDown : faAngleRight) as IconProp} />
        </button>
      )
    }
  }
  const toggleChildren = (event: React.MouseEvent<HTMLElement>) => {
    onToggleChildren()
    setIsExpanded(!isExpanded)
    // event.stopPropagation()
  }

  const toggleDetails = (event: React.MouseEvent<HTMLElement>) => {
    const isExpanded = !isShowDetails
    dispatch(
      socialSlice.actions.toggleTrackExpanded({
        isLeft,
        username,
        matchSlug,
        playlistSlug,
        trackSlug,
        isExpanded,
      })
    )
    event.stopPropagation()
  }

  const summaryTitle = ({ isUser, userTitle, matchInfo, playlistInfo, trackInfo }: TitleProps) => {
    const { slug: trackSlug, duration: trackDuration, wordCount, timedWordCount } = trackInfo
    if (trackSlug) {
      const { title, artist } = getTrackArtistAndTitle(trackInfo)
      const duration = trackDuration || playStatusTrackDuration
      const durationClockTime = Util.secondsToClock(duration, false, false)
      const pointsPerTrack = Math.floor((10 * wordCount) / duration)
      const pointsPerTrackStr = duration > 0 ? pointsPerTrack : '-'
      const pointsStr = duration > 0 ? pointsPerTrack * wordCount : '-'
      const durationStr = duration > 0 ? durationClockTime : '-'
      const divider = <span>{' / '}</span>
      return (
        <div className="sidebarTrackTitle">
          <div>{title}</div>
          <div className="artist">{artist}</div>
          <div className="matchNodeInfo">
            <span className="points">{pointsStr}</span>
            {'  '}
            <span>{durationStr}</span>
            {divider}
            <span>{wordCount || timedWordCount}</span>
            {divider}
            <span>{pointsPerTrackStr}</span>
          </div>
        </div>
      )
    }
    const { title: matchTitle } = matchInfo
    const { title: playlistTitle } = playlistInfo
    const summaryTitle = isUser ? userTitle : playlistTitle || matchTitle || '???'
    return <div>{summaryTitle}</div>
  }
  const title = summaryTitle({ isUser, userTitle, matchInfo, playlistInfo, trackInfo })

  const getLink = () => {
    if (!trackSlug) {
      return null
    }
    const linkWrapperClass = cx('chatSongInfo', { details: isShowDetails })
    if (isActive) {
      const onClick = () => {
        dispatch(modalsSlice.actions.toggleTrackInfo(true))
      }
      return (
        <div className={linkWrapperClass}>
          <span onClick={onClick}>{title}</span>
        </div>
      )
    }
    const { slug: playlistSlug } = playlistInfo
    const toPath = `/${BLASTER_URL_PREFIX}/${matchSlug}/${playlistSlug}/${trackSlug}`
    return (
      <div className={linkWrapperClass}>
        <Link to={toPath}>{title}</Link>
      </div>
    )
  }

  const summaryClass = isShowDetails ? 'leaf activeSong' : ''
  const getSummaryLabel = () => {
    const onScoreClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (trackInfo.slug) {
        setIsShowCurrentPlayStatus(!isShowCurrentPlayStatus)
      }
      event.stopPropagation()
    }
    return (
      <div className="chatSongSummaryTopScore" onClick={onScoreClick}>
        <ScoreProgressBar
          scoreInfo={playStatus}
          isUseTopScore={isShowCurrentPlayStatus}
          isShowRank={true}
          isActive={isShowCurrentPlayStatus}
          isMini
        />
      </div>
    )
  }

  const getRowTitle = () => {
    if (trackSlug && (isCurrentUser || isOwnedMatch)) {
      return getLink()
    }
    const rowTitleClass = cx({
      chatUser: isUser,
      chatSongSummaryTitle: !isUser,
      chatSelf: isCurrentUser,
    })
    const isOwnedSet = playlistSlug && matchOwner === username
    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (isOwnedSet) {
        const { title: playlistTitle } = playlistInfo
        dispatch(
          modalsSlice.actions.togglePlaylistInfo({
            slug: playlistSlug,
            title: playlistTitle,
            matchSlug,
          })
        )
        event.stopPropagation()
      }
    }
    const helpTitle = trackSlug
      ? ''
      : isOwnedSet
      ? 'Set Info...'
      : `${isExpanded ? 'Collapse' : 'Expand'} ${isUser ? 'Player' : 'Set'}`
    return (
      <div className={rowTitleClass} onClick={onClick} title={helpTitle}>
        {title}
      </div>
    )
  }

  const getDetailsExpander = () => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      toggleDetails(event)
    }
    const title = `${isShowDetails ? 'Hide' : 'Show'} ${
      isUser ? '' : `${trackSlug ? 'Track' : 'Set'}`
    } Score`
    return (
      <button className="chatTreeExpander" onClick={onClick} title={title}>
        <FontAwesomeIcon size="2xs" icon={(isShowDetails ? faAngleUp : faAngleDown) as IconProp} />
      </button>
    )
  }
  const details = {
    title,
    isActive,
    isOwnedMatch,
    isMatch: !trackSlug && !playlistSlug,
    duration,
  }
  return (
    <div className={chatSongWrapper}>
      <div className={`chatSongSummary ${trackSlug ? summaryClass : 'rollup'}`}>
        <div className="statusLeft" onClick={toggleChildren} tabIndex={trackSlug ? -1 : 0}>
          {getExpander()}
          {!isShowDetails && getRowTitle()}
        </div>
        <div className="statusRight">
          {getSummaryLabel()}
          {getDetailsExpander()}
        </div>
      </div>
      {isShowDetails && (
        <BlastDetails details={details} playStatus={playStatus} link={getLink()} isLeft={isLeft} />
      )}
    </div>
  )
}

export default BlastRecord
