import shortid from 'shortid'
import { InvitePath } from '../types'
import { MAX_SHORT_ID_TRIES } from '../constants/constants' // TODO: deprecated, switch to nano-id

const MIN_ID_LENGTH = 7
const MAX_ID_LENGTH = 14
const isValidShortId = (shortId: string) => {
  return shortId && MIN_ID_LENGTH <= shortId.length && shortId.length <= MAX_ID_LENGTH
}

const Util = {
  get: function (url: string) {
    // Return a new promise.
    return new Promise(function (resolve, reject) {
      // Do the usual XHR stuff
      const req = new XMLHttpRequest()
      req.open('GET', url)

      req.onload = function () {
        // This is called even on 404 etc
        // so check the status
        if (req.status === 200) {
          // Resolve the promise with the response text
          resolve(req.response)
        } else {
          // Otherwise reject with the status text
          // which will hopefully be a meaningful error
          reject(Error(req.statusText))
        }
      }

      // Handle network errors
      req.onerror = function () {
        reject(Error('Network Error'))
      }

      // Make the request
      req.send()
    })
  },

  randomIntFromInterval: function (min: number, max: number) {
    return Math.floor((max - min + 1) * Math.random() + min)
  },

  trim: function (str: string, characters: string) {
    const c_array = characters.split('')
    let result = ''

    for (let i = 0; i < characters.length; i++) result += '\\' + c_array[i]

    return str.replace(new RegExp('^[' + result + ']+|[' + result + ']+$', 'g'), '')
  },

  stripPunctuationAndExtraSpaces: function (str: string) {
    /* https://stackoverflow.com/questions/4328500/how-can-i-strip-all-punctuation-from-a-string-in-javascript-using-regex
         NOTE: For Unicode punctuation, the blocks are not enough. You have to look at the general
         category Punctuation, and you will see that not all punctuations are nicely located
         in those blocks. There are many familiar punctuations inside Latin blocks, for example.
          */

    // NOTE: we don't want to strip brackets for LyricBlaster. TODO: move / generalize
    // const punctRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
    const punctRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@^_`{|}~]/g
    // const spaceRE = /\s+/g;
    // return str.replace(punctRE, '').replace(spaceRE, ' ');
    return str.replace(punctRE, '')
  },

  generateId() {
    let remainingTries = MAX_SHORT_ID_TRIES
    let id = ''

    while (remainingTries--) {
      id = shortid.generate()

      if (id.indexOf('-') < 0) {
        return id
      }
    }
    throw new Error(`failed to generate id after ${MAX_SHORT_ID_TRIES} (last was ${id})`)
  },
  getInviteKeyParts: function (compoundInviteKey: string) {
    const inviteParts = compoundInviteKey.split('-')
    const numParts = inviteParts.length
    if (numParts >= 3) {
      const inviteKey = inviteParts[numParts - 1]
      const matchSlug = inviteParts[numParts - 2]
      const userPart = inviteParts.slice(0, numParts - 2)
      const matchOwner = userPart.join('-')
      if (!matchOwner.startsWith('@') || !isValidShortId(matchSlug) || !isValidShortId(inviteKey)) {
        return null
      }
      return { matchOwner, matchSlug, inviteKey }
    }
    return null
  },
  getCompactMatchSlug: function ({ matchOwner, matchSlug, inviteKey }: InvitePath) {
    const optionalInviteKey = inviteKey ? `-${inviteKey}` : ''
    return `${matchOwner}/${matchSlug}${optionalInviteKey}`
  },
  getCompoundMatchSlugParts: function (compoundSlug: string) {
    const [matchOwner, slugPlusOptionalInvite] = compoundSlug.split('/')
    const [matchSlug, inviteKey = ''] = slugPlusOptionalInvite.split('-')
    return { matchSlug, matchOwner, inviteKey }
  },
  formattedTimeStamp: function (timestamp: number) {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const padNum = (num: number) => num.toString().padStart(2, '0')
    const month = padNum(date.getMonth() + 1)
    const day = padNum(date.getDate())
    const hour = padNum(date.getHours())
    const minute = padNum(date.getMinutes())
    return `${year}-${month}-${day} ${hour}:${minute}`
  },
  sluggify: function (name: string) {
    return name.replace(/[.,-/#!$%^&*;:{}=\-_`~() ]/g, '-').replace(/[@]/g, '')
  },

  removeExtension: function (filename: string) {
    return filename.substring(0, filename.lastIndexOf('.'))
  },

  reverseSluggify: function (name: string) {
    const words = name.split('-')
    const formattedTitle = words.map(function (word: string) {
      return word.toUpperCase()
    })

    return formattedTitle.join(' ')
  },

  // // TODO: add size initializer?
  // StringBuffer: function(separator: string) {
  //   this.buffer = []
  //   this.index = 0
  //   this.separator = separator ? separator : ''
  // },

  secondsToClock: function (time: number, isRelative = false, isShowMillis = true) {
    const rawSeconds = time
    const absoluteSeconds = Math.abs(rawSeconds)
    const ms = absoluteSeconds * 1000
    let millis: string | number = parseInt(String(ms % 1000)) // TODO: should be rounding ?
    let seconds: string | number = parseInt(String((ms / 1000) % 60))
    let minutes: string | number = parseInt(String((ms / (1000 * 60)) % 60))
    let hours: string | number = parseInt(String((ms / (1000 * 60 * 60)) % 24))

    if (hours > 0) {
      hours = hours < 10 ? '0' + hours : hours
      hours += ':'
    } else {
      hours = ''
    }

    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    millis = millis < 100 ? (millis < 10 ? '00' + millis : '0' + millis) : millis
    millis = '.' + millis
    const prefix = isRelative ? (rawSeconds < 0 ? '-' : '+') : ''
    const clockTime = `${prefix}${hours}${minutes}:${seconds}${isShowMillis ? millis : ''}`

    return clockTime
  },

  numberWithCommas: function (x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  shuffleArray: function <T>(array: T[]): T[] {
    const shuffledArray = [...array] // Copy the original array to avoid mutating it
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      // Swap elements at indices i and j
      ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
    }
    return shuffledArray
  },
  insertIntoSortedArray: function <T>(
    array: T[],
    element: T,
    compareFn: (a: T, b: T) => number
  ): void {
    let low = 0
    let high = array.length - 1

    while (low <= high) {
      const mid = Math.floor((low + high) / 2)
      const comparison = compareFn(array[mid], element)

      if (comparison === 0) {
        array.splice(mid, 0, element) // Insert at the found index
        return
      } else if (comparison < 0) {
        low = mid + 1
      } else {
        high = mid - 1
      }
    }
    // If the element is not found, insert it at the appropriate position
    array.splice(low, 0, element)
  },

  // // https://stackoverflow.com/questions/18279141/javascript-string-encryption-and-decryption
  // // TODO: get something better!
  // cipher: function (salt: string) {
  //   const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0))
  //   const byteHex = (n: number) => ('0' + Number(n).toString(16)).substr(-2)
  //   const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code)
  //
  //   return (text: string) =>
  //     text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
  // },

  // decipher: function(salt: string) {
  //   const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0))
  //   const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code)
  //   return (encoded: string) =>
  //     encoded
  //       .match(/.{1,2}/g)
  //       .map((hex) => parseInt(hex, 16))
  //       .map(applySaltToChar)
  //       .map((charCode) => String.fromCharCode(charCode))
  //       .join('')
  // },
}

// Util.StringBuffer.prototype = {
//   append: function(str: string) {
//     this.buffer[this.index] = str
//     this.index++
//   },
//
//   toString: function() {
//     return this.buffer.join(this.separator)
//   },
// }

export default Util
