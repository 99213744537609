import { createAsyncThunk } from '@reduxjs/toolkit'

import { BLASTER_URL_PREFIX, defaultMatchScore, defaultPlaylistInfo } from '../constants/constants'
import { AppDispatch, RootState } from '../reducers'
import blasterPeersSlice from '../reducers/blasterPeersSlice'
import matchStatusSlice from '../reducers/matchStatusSlice'
import realtime from '../services/RealtimeService'
import { MatchInfo, MatchPath, MatchStatus, PlayerStatus, UserMatchStatusMap } from '../types'
import { getMatchPlayerNamesByRank } from '../util/score-utils'
import loadTrackFromUri from './track-loading/loadTrackFromUri'

export const loadMatch = createAsyncThunk<
  Promise<void>,
  { matchOwner: string; matchSlug: string; isLoadFirstTrack: boolean },
  { state: RootState; dispatch: AppDispatch }
>('load/match', async ({ matchOwner, matchSlug, isLoadFirstTrack }, { dispatch, getState }) => {
  return new Promise<void>(async (resolve, reject) => {
    const rawStatus = await realtime.endpointOnce(`matches/${matchOwner}/${matchSlug}`, {})
    const { players, leaderboard } = getFixedUpPlayersAndLeaderboard({ rawStatus })
    const info = rawStatus.info as MatchInfo
    const matchStatus: MatchStatus = { info, players, invites: {}, leaderboard: [] }
    dispatch(
      matchStatusSlice.actions.updateMatchStatus({
        matchOwner,
        matchSlug,
        matchStatus,
      })
    )
    dispatch(
      matchStatusSlice.actions.updateLeaderboard({
        matchOwner,
        matchSlug,
        leaderboard,
      })
    )
    const currTracks = null // TODO: may already be loaded?
    const loadTracksPromise = currTracks
      ? Promise.resolve({})
      : realtime.endpointOnce(`tracks/${matchOwner}`)
    const ownerTracks = await loadTracksPromise
    dispatch(blasterPeersSlice.actions.setTracks({ username: matchOwner, tracks: ownerTracks })) // TODO: filter only by public?
    const {
      info: { playlistOrder, playlists },
    } = matchStatus
    const firstPlaylist = playlistOrder.length ? playlists[playlistOrder[0]] : defaultPlaylistInfo
    if (isLoadFirstTrack && firstPlaylist) {
      const { slug: firstPlaylistSlug, trackOrder: firstPlaylistTrackOrder = [] } = firstPlaylist
      const [firstTrackSlug] = firstPlaylistTrackOrder
      if (firstTrackSlug) {
        const firstTrackPath = `/${BLASTER_URL_PREFIX}/${matchOwner}/${matchSlug}/${firstPlaylistSlug}/${firstTrackSlug}`
        dispatch(loadTrackFromUri({ trackPath: firstTrackPath }))
      }
    }
    resolve()
  })
})

const getFixedUpPlayersAndLeaderboard = (rawMatchStatus: any = { players2: {} }) => {
  const players = rawMatchStatus.players2 || ({} as UserMatchStatusMap)
  Object.keys(players).forEach((username) => {
    const guestPlayers = players[username]
    Object.keys(guestPlayers).forEach((playerSlug) => {
      const player = guestPlayers[playerSlug] as PlayerStatus
      player.matchScore = player.matchScore || defaultMatchScore()
      player.playlistScores = player.playlistScores || {}
      player.trackScores = player.trackScores || {}
    })
  })
  const leaderboard = getMatchPlayerNamesByRank(players)
  return {
    players,
    leaderboard,
  }
}
export const startListeningToMatch = createAsyncThunk(
  'update/matches',
  async ({ matchOwner, matchSlug }: MatchPath, { dispatch, getState }) => {
    realtime.endpoint(`matches/${matchOwner}/${matchSlug}/players2`).on('value', (snapshot) => {
      const players2 = snapshot.val() || {}
      const { players, leaderboard } = getFixedUpPlayersAndLeaderboard({ players2 })
      dispatch(
        matchStatusSlice.actions.updateMatchPlayers({
          matchOwner,
          matchSlug,
          players,
        })
      )
      dispatch(
        matchStatusSlice.actions.updateLeaderboard({
          matchOwner,
          matchSlug,
          leaderboard,
        })
      )
    })
  }
)
