import { configureStore, combineReducers, createAsyncThunk } from '@reduxjs/toolkit'

import blasterPeers from './blasterPeersSlice'
import chatTranscript from './chatTranscriptSlice'
import currentPlay from './currentPlaySlice'
import focus from './focusSlice'
import leaderboard from './leaderboardSlice'
import loading from './loadingSlice'
import localAuthoring from './localAuthoringSlice'
import matchStatus from './matchStatusSlice'
import modals from './modalsSlice'
import scoreStatus from './scoreStatusSlice'
import session from './sessionSlice'
import settings from './settingsSlice'
import social from './socialSlice'
import soundStatus from './soundStatusSlice'
import vizParams from './vizParametersSlice'

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  // extra: { s: string; n: number }
}>()

const rootReducer = combineReducers({
  blasterPeers: blasterPeers.reducer,
  chatTranscript: chatTranscript.reducer,
  currentPlay: currentPlay.reducer,
  focus: focus.reducer,
  leaderboard: leaderboard.reducer,
  loading: loading.reducer,
  localAuthoring: localAuthoring.reducer,
  matchStatus: matchStatus.reducer,
  modals: modals.reducer,
  scoreStatus: scoreStatus.reducer,
  session: session.reducer,
  settings: settings.reducer,
  social: social.reducer,
  vizParams: vizParams.reducer,
  soundStatus: soundStatus.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({ reducer: rootReducer })
const getState = () => store.getState()
export type AppDispatch = typeof store.dispatch
export default getState
