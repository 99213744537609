import getState from '../../reducers/'
import player from '../../services/Player'
import { Line, ModelPart, Point, Section, VizVisitorBaseProps, Word } from '../../types'
import AnimatingVizVisitor from './AnimatingVizVisitor'

type RollingVizVisitorProps = VizVisitorBaseProps & {
  amplitude: number
  frequency: number
}
class RollingVizVisitor extends AnimatingVizVisitor {
  amplitude = 0
  frequency = 0
  wordNumber = 0
  isVertical = false
  isPlaying = false
  constructor(props: RollingVizVisitorProps) {
    super(props)

    this.amplitude = props.amplitude
    this.frequency = props.frequency
    this.wordNumber = 0

    this.controls.amplitude = {
      type: 'text',
      label: 'Height',
      initialValue: this.amplitude,
      step: 0.3,
      min: 1.0,
      max: 5.0,
    }

    this.controls.frequency = {
      type: 'text',
      label: 'Frequency',
      initialValue: this.frequency * 2,
      min: 1,
      max: 8,
    }
  }

  tick(part?: ModelPart) {
    super.tick(part)
    const { amplitude } = getState().vizParams.controls
    const { max, step, initialValue, isAnimateEnabled } = amplitude
    if (isAnimateEnabled) {
      const steppedAmplitude = (initialValue as number) + (step as number)
      if (Math.abs(steppedAmplitude) >= max) {
        this.updateControlStep('zoom', -step)
      }
      this.updateControlValue('amplitude', steppedAmplitude)
    }
  }

  vizWillUpdate(offset: Point) {
    super.vizWillUpdate(offset)
    const {
      amplitude: { initialValue: amplitude },
      frequency: { initialValue: frequency },
    } = getState().vizParams.controls
    this.amplitude = amplitude as number
    this.wordNumber = 0
    this.frequency = (frequency as number) / 2.0
    this.isVertical = true
    this.isPlaying = player.isPlaying
  }

  visitSection(section: Section) {
    super.visitSection(section)
    const { sectionDiff } = this.getPositionRelativeToTarget({
      sectionIndex: section.index,
      lineIndex: -1,
      wordIndex: -1,
    })
    const isActiveSection = sectionDiff === 0
    const isAhead = sectionDiff > 0
    const spacing = this.isVertical ? 20 : section.label.length * 6
    this.wordNumber += 2
    const x = !this.isVertical ? spacing * this.wordNumber : 0
    const y = this.isVertical ? spacing * this.wordNumber : 0
    if (section.elem) {
      section.elem.style.top = `${y + this.offset.y}px`
      section.elem.style.left = `${x + this.offset.x}px`
      section.elem.classList[this.isPlaying && isAhead && !isActiveSection ? 'add' : 'remove'](
        'aheadLine'
      )
      section.elem.classList[this.isPlaying && !isAhead && !isActiveSection ? 'add' : 'remove'](
        'passedLine'
      )
      section.elem.classList[isActiveSection ? 'add' : 'remove']('selected')
    }
  }

  visitLine(line: Line, section: Section) {
    super.visitLine(line, section)
  }

  visitWord(word: Word, line: Line, section: Section) {
    this.setWordColor(word, line, section)
    const { sectionDiff, lineDiff, wordDiff } = this.getPositionRelativeToTarget({
      sectionIndex: section.index,
      lineIndex: line.index,
      wordIndex: word.index,
    })
    const isActiveSection = sectionDiff === 0
    const isActiveLine = isActiveSection && lineDiff === 0
    const isActiveWord = isActiveLine && wordDiff === 0
    const isAhead =
      (isActiveLine && wordDiff > 0) || (isActiveSection && lineDiff > 0) || sectionDiff > 0
    const spacing = this.isVertical ? 30 : word.label.length * 6
    this.wordNumber += word.index === 0 && line.index !== 0 ? 2 : 1
    const x = !this.isVertical ? spacing * this.wordNumber : 0
    const y = this.isVertical ? spacing * this.wordNumber : 0
    if (word.elem) {
      word.elem.style.top = `${y}px`
      word.elem.style.left = `${x}px`
      word.elem.style.top = `${y}px`
      word.elem.style.left = `${x}px`
      word.elem.classList[this.isPlaying && isAhead && !isActiveLine ? 'add' : 'remove'](
        'aheadLine'
      )
      word.elem.classList[this.isPlaying && !isAhead && !isActiveLine ? 'add' : 'remove'](
        'passedLine'
      )
      word.elem.classList[isActiveWord ? 'add' : 'remove']('selected')
    }
  }
}

export default RollingVizVisitor
