import cx from 'classnames'
import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Props = {
  onClose: () => void
  isMini?: boolean
  isTop?: boolean
}

const CloseIcon = ({ onClose, isMini, isTop = true }: Props) => {
  const className = cx('closeIcon', { mini: isMini, top: isTop })
  return (
    <div className={className} data-test onClick={onClose} title="Close">
      <FontAwesomeIcon size="sm" icon={faTimes as IconProp} />
    </div>
  )
}

export default CloseIcon
