import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import {
  API_PATH_SWITCH_USER_ALIAS,
  CHECK_USERNAME_AVAILABILITY_URL,
  INIT_USERNAME_URL,
} from '../../constants/constants'
import userManager from '../../services/UserManager'
import { AppDispatch, RootState } from '../../reducers'

const checkUserAliasAvailability = createAsyncThunk<
  Promise<boolean>,
  string,
  { state: RootState; dispatch: AppDispatch }
>('checkUserAliasAvailability', (username, { dispatch, getState }) => {
  const config = {
    'content-type': 'application/json',
    headers: {
      authorization: `Bearer ${userManager.accessToken}`,
    },
  }
  const url = `${CHECK_USERNAME_AVAILABILITY_URL}/${username}`
  return new Promise<boolean>((resolve, reject) => {
    axios.get(url, config).then((response) => {
      console.log(response.data)
      const {
        data: { isAvailable },
      } = response
      resolve(!!isAvailable)
    })
  })
})

const switchUserAlias = createAsyncThunk<
  Promise<boolean>,
  string,
  { state: RootState; dispatch: AppDispatch }
>('switchUserAlias', (username: string, { dispatch, getState }) => {
  const config = {
    'content-type': 'application/json',
    headers: { authorization: `Bearer ${userManager.accessToken}` },
  }
  const url = `${API_PATH_SWITCH_USER_ALIAS}/${username}`
  return new Promise<boolean>((resolve, reject) => {
    axios.get(url, config).then((response) => {
      console.log(response.data)
      const {
        data: { isSuccess },
      } = response
      resolve(!!isSuccess)
    })
  })
})

type InitUserAliasArgs = { username: string; isResolveCollision?: boolean }
const initUserAlias = createAsyncThunk<
  Promise<string>,
  InitUserAliasArgs,
  { state: RootState; dispatch: AppDispatch }
>('initUserAlias', ({ username, isResolveCollision }, { dispatch, getState }) => {
  const config = {
    'content-type': 'application/json',
    headers: { authorization: `Bearer ${userManager.accessToken}` },
  }
  const maxRetries = isResolveCollision ? 3 : 0 // TODO: is this enough, since will fail for commonly taken aliases?
  // const { userAliases } = selectUserInfo(getState())

  const tryToInit = async (numTries = 0): Promise<string> => {
    const newAlias = `${username}${numTries || ''}`
    const url = `${INIT_USERNAME_URL}/${newAlias}`
    const response = await axios.get(url, config)
    const {
      data: { isSuccess, message },
    } = response
    if (isSuccess) {
      return newAlias
    }
    console.log(`tryToInit failed on try #${numTries} because: ${message}`)
    if (numTries < maxRetries) {
      return await tryToInit(numTries + 1)
    }
    throw new Error(`tryToInit failed after ${numTries} retries`)
  }

  return new Promise<string>((resolve, reject) => {
    tryToInit()
      .then((newAlias) => {
        // const updatedAliases = isResolveCollision ? userAliases : userAliases.concat(newAlias)
        // sessionSlice.actions.initSession({
        //   userAliases: updatedAliases,
        // })
        resolve(newAlias)
      })
      .catch((err) => {
        reject(err)
      })
  })
})

export { checkUserAliasAvailability, initUserAlias, switchUserAlias }
