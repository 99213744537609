import cx from 'classnames'
import React, { ChangeEvent, KeyboardEvent } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faA,
  faImage,
  faQuestionCircle,
  faMagic,
  faMoon,
  faSignOutAlt,
  faSun,
  faVolumeDown,
  faVolumeUp,
  faHourglassEnd,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'

import { activateNextPane } from '../actions/play-actions'
import { nextBackgroundEffect, nextBackgroundImage } from '../actions/viz-switch'
import {
  BLEND_MODES,
  DEFAULT_FONT_SIZE,
  HOW_TO_PROMPT,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE,
  Pane,
  PaneClasses,
} from '../constants/constants'
import { useAppDispatch, useAppSelector } from '../hooks'
import { selectIsFocus } from '../reducers/focusSlice'
import modalsSlice from '../reducers/modalsSlice'
import sessionSlice from '../reducers/sessionSlice'
import { selectIsPlaying } from '../selectors/current-play-selectors'
import {
  selectCurrentBackgroundBlendMode,
  selectCurrentThemeIndex,
  selectIsFullScreen,
  selectMatchInvites,
} from '../selectors/session-selectors'
import player from '../services/Player'
import userManager from '../services/UserManager'
import ModeSwitch from './play-area/ModeSwitch'
import themes from './themes'

const Footer = ({ currentUsername = '??' }) => {
  const dispatch = useAppDispatch()
  const matchInvites = useAppSelector(selectMatchInvites)
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.FOOTER))
  const theme = useAppSelector(selectCurrentThemeIndex)
  const blendMode = BLEND_MODES[useAppSelector(selectCurrentBackgroundBlendMode)]
  const isFullscreen = useAppSelector(selectIsFullScreen)
  const isPlaying = useAppSelector(selectIsPlaying)
  const { isShowLyrics } = useAppSelector((state) => state.settings)
  const openInviteCount = Object.keys(matchInvites).reduce((acc, matchOwner) => {
    const ownerInvites = matchInvites[matchOwner]
    return acc + Object.keys(ownerInvites).length
  }, 0)
  const triggerHelp = () => {
    dispatch(modalsSlice.actions.toggleHelpModal(true))
  }
  const onToggleTimer = () => {
    dispatch(modalsSlice.actions.toggleBreakTimerModal(true))
  }
  const onHideLyricsToggle = () => {
    player.toggleMasked(!isShowLyrics)
  }
  const volumeRef = React.createRef<HTMLInputElement>()
  const textSizeRef = React.createRef<HTMLInputElement>()

  const onBackgroundSettings = () => {
    dispatch(nextBackgroundImage())
  }
  const onToggleLightDarkTheme = () => {
    const nextThemeIndex = (theme + 1) % themes.length
    dispatch(sessionSlice.actions.setCurrentThemeIndex(nextThemeIndex))
  }
  const onNextBgBlendMode = () => {
    dispatch(nextBackgroundEffect())
  }
  const _getVolumeControl = () => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) =>
      (player.volume = parseFloat(event.target.value))
    const setVolume = (newVolume: number) => {
      player.volume = newVolume
      if (volumeRef.current) {
        volumeRef.current.value = String(newVolume)
      }
    }
    const onMuteClick = () => setVolume(0)
    const onMaxClick = () => setVolume(10)
    const onKeyDown = (event: KeyboardEvent) => {
      const step = event.keyCode === 38 ? 1 : event.keyCode === 40 ? -1 : 0
      setVolume(player.volume + step)
      event.stopPropagation()
    }
    return (
      <div className="footerSlider volume">
        <button onClick={onMuteClick}>
          <FontAwesomeIcon size="sm" icon={faVolumeDown as IconProp} title="Volume Down" />
        </button>
        <input
          ref={volumeRef}
          type="range"
          step="1"
          min="0"
          max="10"
          defaultValue={player.volume}
          onChange={onChange}
          onInput={onChange}
          onKeyDown={onKeyDown}
        />
        <button onClick={onMaxClick}>
          <FontAwesomeIcon size="sm" icon={faVolumeUp as IconProp} title="Volume Up" />
        </button>
      </div>
    )
  }
  const _getTextSizeControl = () => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) =>
      (player.lyricFontSize = parseInt(event.target.value))

    const setFontSize = (newFontSize: number) => {
      player.lyricFontSize = newFontSize
      if (textSizeRef.current) {
        textSizeRef.current.value = String(newFontSize)
      }
    }
    const onClick = () => setFontSize(DEFAULT_FONT_SIZE)
    const onKeyDown = (event: KeyboardEvent) => {
      const step = event.keyCode === 38 ? 1 : event.keyCode === 40 ? -1 : 0
      setFontSize(player.lyricFontSize + step)
      event.stopPropagation()
    }
    return (
      <div className="footerSlider fontSize">
        <button onClick={onClick}>
          <FontAwesomeIcon size="sm" icon={faA as IconProp} title="Lyric font size" />
        </button>
        <input
          ref={textSizeRef}
          type="range"
          min={MIN_FONT_SIZE}
          max={MAX_FONT_SIZE}
          step="1"
          defaultValue={player.lyricFontSize}
          onChange={onChange}
          onInput={onChange}
          onKeyDown={onKeyDown}
        />
      </div>
    )
  }
  const _getLogout = () => {
    const logout = () => {
      userManager.logout()
    }
    return (
      <button className="footerButton" aria-label="logout" onClick={logout}>
        <FontAwesomeIcon size="lg" icon={faSignOutAlt as IconProp} title="Logout" />
      </button>
    )
  }
  const _getUserBadge = () => {
    const onClick = () => {
      dispatch(modalsSlice.actions.toggleUserSettings(true))
    }
    return (
      <button className="footerButton" aria-label="user" onClick={onClick} title="Preferences">
        <img src={userManager.picture} alt="avatar" />
        <span>{currentUsername}</span>
        {openInviteCount > 0 && <div className="badge">{openInviteCount}</div>}
      </button>
    )
  }
  const className = cx(PaneClasses[Pane.FOOTER], { hasFocus: isCurrFocus })
  const onClick = () => {
    // console.log(`container: ${PaneClasses[Pane.FOOTER]}`)
    dispatch(activateNextPane({ pane: Pane.FOOTER }))
  }
  return (
    <div className={className} onClick={onClick}>
      <div className="footerLeft">
        {!isPlaying && (
          <button id="info" className="footerButton" aria-label="help" onClick={triggerHelp}>
            <FontAwesomeIcon
              size="lg"
              className="img"
              icon={faQuestionCircle as IconProp}
              title="Help"
            />
            <span>{HOW_TO_PROMPT}</span>
          </button>
        )}
        <button className="footerButton" aria-label="background" onClick={onBackgroundSettings}>
          <FontAwesomeIcon icon={faImage as IconProp} title="Switch Background" />
        </button>
        <button className="footerButton" aria-label="theme" onClick={onToggleLightDarkTheme}>
          <FontAwesomeIcon
            size={theme === 0 ? 'lg' : 'sm'}
            className={theme === 0 ? 'img' : 'imgSm'}
            icon={(theme === 0 ? faSun : faMoon) as IconProp}
            title="Switch Theme"
          />
        </button>
        <div className="blendMode">
          <button className="footerButton" aria-label="theme" onClick={onNextBgBlendMode}>
            <FontAwesomeIcon
              size={theme === 0 ? 'lg' : 'sm'}
              className={theme === 0 ? 'img' : 'imgSm'}
              icon={faMagic as IconProp}
              title="Switch Effect"
            />
          </button>
          <span>{blendMode}</span>
        </div>
        <button className="footerButton" onClick={onHideLyricsToggle}>
          <FontAwesomeIcon
            icon={(isShowLyrics ? faEye : faEyeSlash) as IconProp}
            title={`${isShowLyrics ? 'Hide' : 'Show'} Lyrics`}
            size={theme === 0 ? 'lg' : 'sm'}
          />
        </button>
        {_getTextSizeControl()}
      </div>
      <div className="footerCenter">{!isPlaying && <ModeSwitch />}</div>
      <div className="footerRight">
        {!isPlaying && (
          <button onClick={onToggleTimer}>
            <FontAwesomeIcon icon={faHourglassEnd as IconProp} title="Break Timer" />
          </button>
        )}
        {_getVolumeControl()}
        {!isFullscreen && !isPlaying && _getUserBadge()}
        {!isFullscreen && !isPlaying && _getLogout()}
      </div>
    </div>
  )
}

export default Footer
