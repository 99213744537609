import { createSlice } from '@reduxjs/toolkit'
import {
  getTrackText,
  getTrackTiming,
  getTrackInfo,
  setAudio,
} from '../actions/track-loading/get-track-resources'
import processTrack from '../actions/track-loading/processTrack'
import clearSession from '../actions/track-loading/clearSession'
import loadTrack from '../actions/track-loading/loadTrack'
import { LoadStatus } from '../types'

const { IDLE, LOADING, SUCCESS, ERROR } = LoadStatus
type LoadingState = {
  clearing: LoadStatus
  track: LoadStatus
  info: LoadStatus
  text: LoadStatus
  timing: LoadStatus
  audio: LoadStatus
  processing: LoadStatus
}
const initialState: LoadingState = {
  clearing: IDLE,
  track: IDLE,
  info: IDLE,
  text: IDLE,
  timing: IDLE,
  audio: IDLE,
  processing: IDLE,
}
const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearSession.pending, (state, action) => {
      state.clearing = LOADING
      state.info = IDLE
      state.track = IDLE
      state.text = IDLE
      state.audio = IDLE
      state.timing = IDLE
      state.processing = IDLE
    })
    builder.addCase(clearSession.fulfilled, (state, action) => {
      state.clearing = SUCCESS
      state.info = LOADING
    })
    builder.addCase(loadTrack.rejected, (state, action) => {
      // TODO: add for other phases?
      state.info = ERROR
    })
    builder.addCase(getTrackInfo.pending, (state, action) => {
      state.info = LOADING
      state.clearing = IDLE
    })
    builder.addCase(getTrackInfo.fulfilled, (state, action) => {
      state.info = SUCCESS
    })
    builder.addCase(loadTrack.pending, (state, action) => {
      state.track = LOADING
      state.info = IDLE
    })
    builder.addCase(loadTrack.fulfilled, (state, action) => {
      state.track = SUCCESS
    })
    builder.addCase(getTrackText.pending, (state, action) => {
      state.text = LOADING
    })
    builder.addCase(getTrackText.fulfilled, (state, action) => {
      state.text = SUCCESS
    })
    builder.addCase(setAudio.pending, (state, action) => {
      state.audio = LOADING
    })
    builder.addCase(setAudio.fulfilled, (state, action) => {
      state.audio = SUCCESS
    })
    builder.addCase(getTrackTiming.pending, (state, action) => {
      state.timing = LOADING
    })
    builder.addCase(getTrackTiming.fulfilled, (state, action) => {
      state.timing = SUCCESS
    })
    builder.addCase(processTrack.pending, (state, action) => {
      state.processing = LOADING
      state.info = IDLE // needed, but why is setting above not enough?
      state.track = IDLE
      state.text = IDLE
      state.audio = IDLE
      state.timing = IDLE
    })
    builder.addCase(processTrack.fulfilled, (state, action) => {
      state.processing = SUCCESS
    })
  },
})

export default loadingSlice
