import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Pane } from '../constants/constants'
import { RootState } from './index'

type FocusState = {
  currentFocus: Pane
}
const initialState: FocusState = {
  currentFocus: Pane.PLAY,
}

const focus = createSlice({
  name: 'focus',
  initialState,
  reducers: {
    setFocus: (state, action: PayloadAction<Pane>) => {
      return {
        ...state,
        currentFocus: action.payload,
      }
    },
  },
})

export const selectIsFocus = (pane: Pane) => (state: RootState) => {
  return state.focus.currentFocus === pane
}

export default focus
