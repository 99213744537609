import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  BLEND_MODES,
  DEFAULT_BACKGROUNDS,
  MINIMUM_VIZ_SWITCH_PART_DURATION,
  VIZ_SWITCH_IN_DELAY_SECS,
  VIZ_SWITCH_OUT_EARLY_SECS,
} from '../constants/constants'
import { RootState } from '../reducers'
import sessionSlice from '../reducers/sessionSlice'
import { selectImagePath } from '../selectors/current-play-selectors'
import {
  selectCurrentBackgroundBlendMode,
  selectCurrentBackgroundIndex,
  selectCurrentVisualizationSlug,
} from '../selectors/session-selectors'
import vizBuilder from '../services/LyricVizBuilder'
import player from '../services/Player'
import { VizType } from '../types'

type SectionInfo = {
  prevPart: any
  startPart: any
  endPart: any
}
const getEmptySections = (sections: any) => {
  const emptySections = sections.reduce((acc: [], section: any, index: number) => {
    if (section.lines.length) {
      return acc
    }
    const prevSection = index > 0 ? sections[index - 1] : null
    const nextSection = index + 1 < sections.length ? sections[index + 1] : null
    return [...acc, { prevPart: prevSection, startPart: section, endPart: nextSection }]
  }, [])

  return emptySections
}

export const setDefaultVisualization = createAsyncThunk<void, VizType, { state: RootState }>(
  'playAction/setViz',
  (vizSlug, { dispatch, getState }) => {
    dispatch(sessionSlice.actions.setDefaultVisualizationSlug(vizSlug))
    vizBuilder.switchVisualization(vizSlug)
    dispatch(scheduleVizSwitches())
  }
)

const scheduleVizSwitches = createAsyncThunk<void, void, { state: RootState }>(
  'playAction/scheduleVizSwitches',
  (_, { dispatch, getState }) => {
    const { isVizSwitchingEnabled: isTurningOn } = getState().settings
    const { trackDuration } = player // TODO: get duration from state!
    const defaultVizSlug = selectCurrentVisualizationSlug(getState())
    const alternateVizSlug = defaultVizSlug === 'spiral' ? 'wave' : 'spiral'
    const switchIn = () => {
      vizBuilder.switchViz({ isSwitchingIn: true, vizSlug: alternateVizSlug })
    }
    const switchOut = () => {
      vizBuilder.switchViz({ isSwitchingIn: false, vizSlug: defaultVizSlug })
    }
    const partsToSchedule: SectionInfo[] = getEmptySections(vizBuilder.sections)
    let lastStart = -1
    
    partsToSchedule.forEach(({ prevPart, startPart, endPart }) => {
      let startTime = startPart.referenceTime
      if (startTime === 0 && prevPart) {
        if (prevPart.lines.length) {
          const lastline = prevPart.lines[prevPart.lines.length - 1]
          if (lastline.words.length) {
            const lastWord = lastline.words[lastline.words.length - 1]
            if (lastWord.referenceTime) {
              startTime = lastWord.referenceTime
              startPart.referenceTime = startTime
            }
          }
        }
      }
      if (startTime <= lastStart) {
        console.log('ignoring out-of-order empty section start', startPart)
        return
      }
      const endTime = endPart ? endPart.referenceTime : trackDuration
      const partLength = endTime - startTime
      if (partLength < MINIMUM_VIZ_SWITCH_PART_DURATION) {
        // console.log('ignoring too-short empty section', startPart)
        return
      }
      lastStart = startTime

      if (isTurningOn) {
        // console.log('turning on start viz for', startPart)
        player.setPartEvent({
          eventCallback: switchIn,
          part: startPart,
          useReferenceTime: true,
          deltaTime: -VIZ_SWITCH_IN_DELAY_SECS,
        })
      } else {
        // console.log('resetting start viz for', startPart)
        player.resetPartTiming(startPart)
      }

      if (endPart) {
        if (isTurningOn) {
          player.setPartEvent({
            eventCallback: switchOut,
            part: endPart,
            useReferenceTime: true,
            deltaTime: VIZ_SWITCH_OUT_EARLY_SECS,
          })
        } else {
          player.resetPartTiming(endPart)
        }
      }
    })
    vizBuilder.isVizSwitchingScheduled = isTurningOn
  }
)

export const nextBackgroundImage = createAsyncThunk<
  void,
  boolean | undefined,
  { state: RootState }
>('session/nextBackgroundImage', (isPreferRemoteImage = false, { dispatch, getState }) => {
  const state = getState()
  const currentBackgroundIndex = selectCurrentBackgroundIndex(state)
  const remoteImagePath = selectImagePath(state)
  if (isPreferRemoteImage) {
    dispatch(sessionSlice.actions.setCurrentBackgroundIndex(DEFAULT_BACKGROUNDS.length))
    return
  }
  const numBackgrounds = DEFAULT_BACKGROUNDS.length + (remoteImagePath ? 1 : 0)
  let nextIndex = -1
  while (nextIndex < 0 || nextIndex >= numBackgrounds || nextIndex === currentBackgroundIndex) {
    nextIndex = Math.floor(Math.random() * numBackgrounds)
  }
  dispatch(sessionSlice.actions.setCurrentBackgroundIndex(nextIndex))
})

export const nextBackgroundEffect = createAsyncThunk<void, void, { state: RootState }>(
  'session/nextBackgroundEffect',
  (_, { dispatch, getState }) => {
    const state = getState()
    const currentIndex = selectCurrentBackgroundBlendMode(state)
    const nextIndex = (currentIndex + 1) % BLEND_MODES.length
    dispatch(sessionSlice.actions.setCurrentBackgroundBlendMode(nextIndex))
  }
)

export default scheduleVizSwitches
