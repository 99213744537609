import cx from 'classnames'
import React from 'react'

import HeaderContainer from './HeaderContainer'
import InteractionView from './InteractionView'
import PlayControls from './PlayControls'
import { PlayActions, SettingsActions } from '../../types'
import { SimpleSounds } from '../widgets/SimpleSounds'

type Props = {
  isShowSocial: boolean
  actions: {
    playActions: PlayActions
    settingsActions: SettingsActions
  }
}
const PlayArea = ({ actions, isShowSocial }: Props) => {
  const { playActions, settingsActions } = actions
  const className = cx('playArea', { isShowSocial })
  return (
    <div className={className}>
      <HeaderContainer settingsActions={settingsActions} />
      <InteractionView />
      <PlayControls playActions={playActions} settingsActions={settingsActions} />
      <SimpleSounds />
    </div>
  )
}

export default PlayArea
