import { pathOr } from 'ramda'

import { RootState } from '../reducers'
import { selectCurrentPlaylistSlug, selectCurrentTrackInfo } from './current-play-selectors'
import { selectCurrentBlaster, selectCurrentMatchSlug } from './session-selectors'
import { PlayStatus } from '../types'
import { defaultTrackPlayStatus } from '../util/score-utils'

export const selectCurrentScore = (state: RootState): PlayStatus => {
  const playerName = selectCurrentBlaster(state)
  const matchSlug = selectCurrentMatchSlug(state)
  const playlistSlug = selectCurrentPlaylistSlug(state)
  const trackInfo = selectCurrentTrackInfo(state)
  const defaultPlayStatus = defaultTrackPlayStatus(trackInfo)
  return pathOr(
    defaultPlayStatus,
    [playerName, matchSlug, playlistSlug, trackInfo.slug],
    state.scoreStatus
  )
}
