import { pathOr } from 'ramda'

import { RootState } from '../reducers'
import { which } from '../reducers/socialSlice'

export type SidebarPathPayload = {
  isLeft?: boolean
  username?: string
  matchSlug?: string
  playlistSlug?: string
  trackSlug?: string
}

const selectIsContainerActive =
  ({ username, matchSlug, playlistSlug, trackSlug, isLeft }: SidebarPathPayload) =>
  (state: RootState) => {
    const path = [which(isLeft)]
    if (matchSlug) {
      path.push(matchSlug)
      if (username) {
        path.push('players')
        path.push(username)
        if (playlistSlug) {
          path.push('playlists')
          path.push(playlistSlug)
          if (trackSlug) {
            path.push('tracks')
            path.push(trackSlug)
          }
        }
      }
    }
    path.push('isActive')
    return pathOr(false, path, state.social)
  }

const selectIsMatchExpanded =
  ({ matchSlug = '', isLeft }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(false, [which(isLeft), matchSlug, 'isExpanded'], state.social)

const selectIsShowMatchPlayers =
  ({ matchSlug = '', isLeft }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(isLeft, [which(isLeft), matchSlug, 'isShowPlayers'], state.social) // Left side defaults to true

const selectIsUserMatchExpanded =
  ({ username = '', matchSlug = '', isLeft }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(false, [which(isLeft), matchSlug, 'players', username, 'isExpanded'], state.social)

const selectIsUserPlaylistExpanded =
  ({ username = '', matchSlug = '', playlistSlug = '', isLeft }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [which(isLeft), matchSlug, 'players', username, 'playlists', playlistSlug, 'isExpanded'],
      state.social
    )

const selectIsUserTrackExpanded =
  ({
    username = '',
    matchSlug = '',
    playlistSlug = '',
    trackSlug = '',
    isLeft,
  }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [
        which(isLeft),
        matchSlug,
        'players',
        username || '',
        'playlists',
        playlistSlug || '',
        'tracks',
        trackSlug || '',
        'isExpanded',
      ],
      state.social
    )

// const selectIsShowUserMatchActiveScore =
//   ({ username, matchSlug }: MatchPath) =>
//   (state: RootState) =>
//     pathOr(false, [matchSlug, 'players', username, 'isShowActiveScore'], state.social)
// const selectIsShowUserPlaylistActiveScore =
//   ({ username, matchSlug, playlistSlug }: PlaylistPath) =>
//   (state: RootState) =>
//     pathOr(
//       false,
//       [matchSlug, 'players', username, 'playlists', playlistSlug, 'isShowActiveScore'],
//       state.social
//     )

const selectIsShowUserTrackActiveScore =
  ({
    username = '',
    matchSlug = '',
    playlistSlug = '',
    trackSlug = '',
    isLeft,
  }: SidebarPathPayload) =>
  (state: RootState) =>
    pathOr(
      false,
      [
        which(isLeft),
        matchSlug,
        'players',
        username || '',
        'playlists',
        playlistSlug || '',
        'tracks',
        trackSlug || '',
        'isShowActiveScore',
      ],
      state.social
    )

export {
  selectIsMatchExpanded,
  selectIsShowMatchPlayers,
  selectIsUserMatchExpanded,
  selectIsUserPlaylistExpanded,
  selectIsUserTrackExpanded,
  selectIsShowUserTrackActiveScore,
  selectIsContainerActive,
}
