import { createSlice } from '@reduxjs/toolkit'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    isShowTimes: false,
    isShowSections: true,
    isShowSectionAnimation: true,
    isShowLyrics: true,
    isVisualPulse: false,
    isAudioPulse: false,
    isOptionKey: false,
    isVizSwitchingEnabled: true,
    isNoviceAssistEnabled: false,
  },
  reducers: {
    setIsShowTimes: (state, action) => {
      state.isShowTimes = action.payload
    },
    setIsShowLyrics: (state, action) => {
      state.isShowLyrics = action.payload
    },
    setIsShowSections: (state, action) => {
      state.isShowSections = action.payload
    },
    setShowSectionAnimation: (state, action) => {
      state.isShowSectionAnimation = action.payload
    },
    setIsVizSwitchingEnabled: (state, action) => {
      state.isVizSwitchingEnabled = action.payload
    },
    setIsNoviceAssistEnabled: (state, action) => {
      state.isNoviceAssistEnabled = action.payload
    },
    setIsVisualPulse: (state, action) => {
      state.isVisualPulse = action.payload
    },
    setIsAudioPulse: (state, action) => {
      state.isAudioPulse = action.payload
    },
    setIsOptionKey: (state, action) => {
      state.isOptionKey = action.payload
    },
  },
})

export default settingsSlice
