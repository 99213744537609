import React, { useEffect } from 'react'

import { useAppSelector } from '../../../hooks'
import { selectCurrentUsername } from '../../../selectors/session-selectors'
import { GuestPlayer, ScoreRank, UserMatchStatusMap } from '../../../types'
import Util from '../../../util/util'
import PlayerEditModal from './PlayerEditModal'
import cx from 'classnames'

type Props = {
  players: UserMatchStatusMap
  leaderboard: string[]
  compoundMatchSlug: string
}
type GuestPlayerItem = GuestPlayer & {
  id: string
  isCurrent: boolean
  topScore: number
  topScoreRank: ScoreRank
}

const GuestPlayers = ({ players, leaderboard: currentGuestPlayers, compoundMatchSlug }: Props) => {
  const currentUsername = useAppSelector(selectCurrentUsername)
  const [guestPlayers, setGuestPlayers] = React.useState<GuestPlayerItem[]>([])
  const [isGuestPlayersInited, setIsGuestPlayersInited] = React.useState(false)
  const [selectedPlayerInfo, setSelectedPlayerInfo] = React.useState<GuestPlayer | null>(null)

  useEffect(() => {
    if (!isGuestPlayersInited) {
      const activeGuestPlayersList = currentGuestPlayers.map((compoundPlayer) => {
        const [username, player] = compoundPlayer.split('/')
        const {
          name,
          matchScore: { topScore, topScoreRank },
        } = players[username][player]
        return {
          name,
          slug: player,
          id: compoundPlayer,
          isCurrent: username === currentUsername && player !== currentUsername,
          topScore,
          topScoreRank,
        }
      })
      setGuestPlayers(activeGuestPlayersList)
      setIsGuestPlayersInited(true)
    }
  }, [currentGuestPlayers, isGuestPlayersInited, currentUsername, players])

  const _displayPlayer = (
    { id, name, slug, topScore, topScoreRank, isCurrent }: GuestPlayerItem,
    playerIndex: number
  ) => {
    const onPlayerClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (isCurrent) {
        setSelectedPlayerInfo({ slug, name })
      }
      event.preventDefault() // otherwise list will get onChange
    }
    const titleClassname = cx('title', { isCurrent: isCurrent })
    return (
      <div key={id} data-id={id} className="sortable-list-item player">
        <div className="left">
          <div className="rank">{playerIndex + 1}</div>
          <div className={titleClassname}>
            <div onClick={onPlayerClick}>
              <div>{name || slug}</div>
              <div className="id">{id}</div>
            </div>
          </div>
        </div>
        <div className={`${topScoreRank} score`}>{Util.numberWithCommas(topScore)}</div>
      </div>
    )
  }

  const getPlayerEditModal = () => {
    if (selectedPlayerInfo === null) {
      return false
    }
    const onClose = () => {
      setIsGuestPlayersInited(false)
      setSelectedPlayerInfo(null)
    }
    return (
      <PlayerEditModal
        matchSlug={compoundMatchSlug}
        player={selectedPlayerInfo}
        onClose={onClose}
      />
    )
  }
  const newPlayer = () => {
    const newPlayer = {
      slug: `player-${currentGuestPlayers.length}`,
      name: `Player ${currentGuestPlayers.length}`,
    }
    setSelectedPlayerInfo(newPlayer)
  }
  return (
    <>
      <div className="players">
        <label>Leaderboard:</label>
        <div className="playersDisplay">{guestPlayers.map(_displayPlayer)}</div>
        <button onClick={newPlayer}>New Player...</button>
      </div>
      {getPlayerEditModal()}
    </>
  )
}

export default GuestPlayers
