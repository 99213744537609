import { mergeDeepRight } from 'ramda'
import { createSlice } from '@reduxjs/toolkit'

type SidebarStatus = {
  isActive: boolean
  isExpanded: boolean
  isShowActiveScore: boolean
}
export interface SidebarState {
  left: {
    [matchSlug: string]: SidebarStatus & {
      players: {
        [username: string]: SidebarStatus & {
          playlists: {
            [playlistSlug: string]: SidebarStatus & {
              tracks: {
                [trackSlug: string]: SidebarStatus
              }
            }
          }
        }
      }
    }
  }
  right: {
    [matchSlug: string]: SidebarStatus & {
      players: {
        [username: string]: SidebarStatus & {
          playlists: {
            [playlistSlug: string]: SidebarStatus & {
              tracks: {
                [trackSlug: string]: SidebarStatus
              }
            }
          }
        }
      }
    }
  }
}
const initialState: SidebarState = {
  left: {},
  right: {},
}

export const which = (isLeft = false): string => (isLeft ? 'left' : 'right')

const socialSlice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    toggleMatchExpanded: (state, action) => {
      const { matchSlug, isExpanded, isLeft } = action.payload
      const updatedMatch = { [which(isLeft)]: { [matchSlug]: { isExpanded } } }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleShowMatchPlayers: (state, action) => {
      const { matchSlug, isShowPlayers, isLeft } = action.payload
      const updatedMatch = { [which(isLeft)]: { [matchSlug]: { isShowPlayers } } }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleUserExpanded: (state, action) => {
      const { username, matchSlug, isExpanded, isLeft } = action.payload
      const updatedMatch = {
        [which(isLeft)]: { [matchSlug]: { players: { [username]: { isExpanded } } } },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    togglePlaylistExpanded: (state, action) => {
      const { username, matchSlug, playlistSlug, isExpanded, isLeft } = action.payload
      const updatedMatch = {
        [which(isLeft)]: {
          [matchSlug]: {
            players: {
              [username]: {
                playlists: {
                  [playlistSlug]: { isExpanded },
                },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleTrackExpanded: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, isExpanded, isLeft } = action.payload
      const updatedMatch = {
        [which(isLeft)]: {
          [matchSlug]: {
            players: {
              [username]: {
                playlists: {
                  [playlistSlug]: {
                    tracks: {
                      [trackSlug]: { isExpanded },
                    },
                  },
                },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    toggleTrackShowActiveScore: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, isShowActiveScore, isLeft } =
        action.payload
      const updatedMatch = {
        [which(isLeft)]: {
          [matchSlug]: {
            players: {
              [username]: {
                playlists: {
                  [playlistSlug]: {
                    tracks: {
                      [trackSlug]: { isShowActiveScore },
                    },
                  },
                },
              },
            },
          },
        },
      }
      return mergeDeepRight(state, updatedMatch)
    },
    clearActiveTrack: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, isLeft } = action.payload
      if (!username || !matchSlug || !playlistSlug || !trackSlug) {
        return state
      }
      const updatedMatch = {
        [which(isLeft)]: {
          [matchSlug]: {
            isActive: false,
            players: {
              [username]: {
                isActive: false,
                playlists: {
                  [playlistSlug]: {
                    isActive: false,
                    tracks: {
                      [trackSlug]: {
                        isActive: false,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedMatch)
      return result
    },
    revealTrack: (state, action) => {
      const { username, matchSlug, playlistSlug, trackSlug, owner, isLeft } = action.payload
      const isOwned = username === owner
      const updatedMatch = {
        [which(isLeft)]: {
          [matchSlug]: {
            isActive: true,
            isExpanded: true,
            isShowActiveScore: isOwned,
            players: {
              [username]: {
                isActive: true,
                isExpanded: true,
                isShowActiveScore: isOwned,
                playlists: {
                  [playlistSlug]: {
                    isActive: true,
                    isExpanded: true,
                    isShowActiveScore: isOwned, // TODO: or if user has saved timing
                    tracks: {
                      [trackSlug]: {
                        isActive: true,
                        isExpanded: true,
                        isShowActiveScore: isOwned,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
      const result = mergeDeepRight(state, updatedMatch)
      return result
    },
  },
})

export default socialSlice
