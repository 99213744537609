import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../reducers'
import settingsSlice from '../reducers/settingsSlice'
import player from '../services/Player'
import scheduleVizSwitches from './viz-switch'
import scheduleSectionAnimations from './section-animation'

const toggleShowSections = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>('playAction/toggleShowSections', (isShow: boolean, { dispatch, getState }) => {
  player.toggleSections(isShow)
  dispatch(settingsSlice.actions.setIsShowSections(isShow))
})

const toggleShowSectionAnimation = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>('playAction/toggleShowSectionAnimation', (isShowSectionAnimation, { dispatch, getState }) => {
  dispatch(settingsSlice.actions.setShowSectionAnimation(isShowSectionAnimation))
  dispatch(scheduleSectionAnimations())
})

const toggleVizSwitchingEnabled = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>('playAction/toggleVizSwitchingEnabled', (isVizSwitchingEnabled, { dispatch, getState }) => {
  dispatch(settingsSlice.actions.setIsVizSwitchingEnabled(isVizSwitchingEnabled))
  dispatch(scheduleVizSwitches())
})

const toggleNoviceAssistEnabled = createAsyncThunk<
  void,
  boolean,
  { state: RootState; dispatch: AppDispatch }
>(
  'playAction/toggleNoviceAssistEnabled',
  (isNoviceAssistEnabled: boolean, { dispatch, getState }) => {
    player.toggleNoviceAssist(isNoviceAssistEnabled)
    dispatch(settingsSlice.actions.setIsNoviceAssistEnabled(isNoviceAssistEnabled))
  }
)

export { toggleShowSections, toggleNoviceAssistEnabled, toggleVizSwitchingEnabled, toggleShowSectionAnimation }
