import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  defaultDirtyState,
  defaultMatchScore,
  defaultPlaylistInfo,
  defaultTrackInfo,
} from '../constants/constants'
import { TrackInfo, PlaylistInfo, PlayStatus, DirtyState, ScoreDelta } from '../types'

const defaultScoreDelta = (): ScoreDelta => ({
  newValue: 0,
  newRank: 'bad',
})
export interface CurrentPlay {
  trackInfo: TrackInfo //ExtendedTrackInfo
  playlistInfo: PlaylistInfo
  trackDuration: number
  isPlaying: boolean
  clockSeconds: number
  playStatus: PlayStatus
  isScoreDirty: boolean
  isPlayable: boolean
  isEditable: boolean
  dirtyState: DirtyState
  localAudioPath: string
  localImagePath: string
  localImageData: ArrayBuffer | string | null
  lyrics: string
  lrcLines: string[] | null
  isShowCountdownClock: boolean
  scoreDelta: ScoreDelta
}
const initialState: CurrentPlay = {
  trackInfo: defaultTrackInfo,
  playlistInfo: defaultPlaylistInfo,
  trackDuration: 0.0,
  isPlaying: false,
  clockSeconds: 0.0,
  playStatus: defaultMatchScore(),
  isScoreDirty: false,
  isPlayable: false,
  isEditable: false,
  dirtyState: defaultDirtyState,
  localAudioPath: 'none',
  localImagePath: 'none',
  localImageData: null,
  lyrics: '',
  lrcLines: null,
  isShowCountdownClock: false,
  scoreDelta: defaultScoreDelta(),
}
const currentPlaySlice = createSlice({
  name: 'currentPlay',
  initialState,
  reducers: {
    setPlaylistInfo: (state: CurrentPlay, action) => {
      state.playlistInfo = { ...action.payload }
    },
    setTrackInfo: (state: CurrentPlay, action: PayloadAction<TrackInfo>) => {
      state.trackInfo = { ...action.payload }
    },
    setTimedWordCount: (state: CurrentPlay, action: PayloadAction<number>) => {
      state.trackInfo.timedWordCount = action.payload
    },
    setLyrics: (state: CurrentPlay, action: PayloadAction<string>) => {
      state.lyrics = action.payload
    },
    setLRClines: (state: CurrentPlay, action: PayloadAction<string[] | null>) => {
      state.lrcLines = action.payload
    },
    setIsPlaying: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload
    },
    setTrackDuration: (state: CurrentPlay, action: PayloadAction<number>) => {
      state.trackDuration = action.payload
    },
    setClockSeconds: (state: CurrentPlay, action: PayloadAction<number>) => {
      state.clockSeconds = action.payload
    },
    setPlayStatus: (state: CurrentPlay, action: PayloadAction<PlayStatus>) => {
      state.playStatus = action.payload
    },
    updateMaxScore: (state, action) => {
      const newPlayStatus = {
        ...state.playStatus,
        maxScore: action.payload,
      }
      state.playStatus = newPlayStatus
    },
    // updateScoreClock: (state, action) => {
    //   const { scoreSeconds, numLaps } = action.payload
    //   const newPlayStatus = {
    //     ...state.playStatus,
    //     scoreSeconds,
    //     numLaps,
    //   }
    //   state.playStatus = newPlayStatus
    // },
    setIsScoreDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isScoreDirty = action.payload
    },
    setIsPlayable: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isPlayable = action.payload
    },
    setIsEditable: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload
    },
    setLocalAudioPath: (state: CurrentPlay, action: PayloadAction<string>) => {
      state.localAudioPath = action.payload
    },
    setLocalImagePath: (state: CurrentPlay, action: PayloadAction<string>) => {
      state.localImagePath = action.payload
    },
    setLocalImageData: (state: CurrentPlay, action: PayloadAction<ArrayBuffer | string | null>) => {
      state.localImageData = action.payload
    },
    setDirtyState: (state: CurrentPlay, action: PayloadAction<DirtyState>) => {
      state.dirtyState = { ...action.payload }
    },
    setIsInfoDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isInfoDirty = action.payload
    },
    setIsAudioDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isAudioDirty = action.payload
    },
    setIsImageDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isImageDirty = action.payload
    },
    setIsLyricsDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isLyricsDirty = action.payload
    },
    setIsTimingDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isTimingDirty = action.payload
    },
    setIsShowCountdownClock: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isShowCountdownClock = action.payload
    },
    setScoreDelta: (state: CurrentPlay, action: PayloadAction<ScoreDelta>) => {
      state.scoreDelta = action.payload
    },
  },
})

export default currentPlaySlice
