import {
  faBackward,
  faForward,
  faPause,
  faPlay,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import cx from 'classnames'
import React, { createRef, useEffect, MouseEvent, ChangeEvent } from 'react'

import doSave from '../../actions/save'
import { activateNextPane, togglePlay } from '../../actions/play-actions'
import loadNextTrack from '../../actions/track-loading/loadNextTrack'
import { BLAST_BUTTON_TITLE, Pane, PaneClasses } from '../../constants/constants'
import { selectIsFocus } from '../../reducers/focusSlice'
import modalsSlice from '../../reducers/modalsSlice'
import {
  selectCurrentScoreDelta,
  selectCurrentTrackSlug,
  selectIsNeedSyncing,
} from '../../selectors/current-play-selectors'
import { selectMode } from '../../selectors/session-selectors'
import player from '../../services/Player'
import { isMobile as getIsMobile } from '../../util/track-utils'
import { PlayActions, SettingsActions } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'

type Props = {
  playActions: PlayActions
  settingsActions: SettingsActions
}

const PlayControls = ({ playActions }: Props) => {
  const isMobile = getIsMobile()
  const [isLeftBlastButtonPressed, setIsLeftBlastButtonPressed] = React.useState(false)
  const [isRightBlastButtonPressed, setIsRightBlastButtonPressed] = React.useState(false)
  const setIsButtonPressed = ({ isLeft, isPressed }: { isLeft: boolean; isPressed: boolean }) => {
    if (isLeft) {
      setIsLeftBlastButtonPressed(isPressed)
    } else {
      setIsRightBlastButtonPressed(isPressed)
    }
  }
  const dispatch = useAppDispatch()
  const { blast, rewindToStart, rewindTo, clear, forget } = playActions
  const mode = useAppSelector(selectMode)
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.PLAY_CONTROLS))
  const isNeedSyncing = useAppSelector(selectIsNeedSyncing)
  const isTrackLoaded = !!useAppSelector(selectCurrentTrackSlug)
  const isVizMode = mode === 'viz'
  const isEditMode = mode === 'edit'
  const {
    trackDuration,
    isPlaying,
    clockSeconds,
    isScoreDirty,
    playStatus: { doneCount },
  } = useAppSelector((state) => state.currentPlay)
  const hasTiming = doneCount > 0
  const isOptionKey = useAppSelector((state) => state.settings.isOptionKey)
  const { newRank: currentWordRank } = useAppSelector(selectCurrentScoreDelta)

  useEffect(() => {
    const onKeyDownOrUp = (event: KeyboardEvent, isKeyDown: boolean) => {
      // support flashing CMD keys, but only when playing, because highlight will get stuck
      // if CMD key is being used for actual cmd shortcut that switches focus. TODO: fix or drop support?
      switch (event.keyCode) {
        case 91: // LEFT CMD key
          setIsButtonPressed({ isLeft: true, isPressed: isKeyDown && isPlaying })
          break
        case 93: // RIGHT CMD key
          setIsButtonPressed({ isLeft: false, isPressed: isKeyDown && isPlaying })
          break
        case 16: // SHIFT key
          const isLeft = event.location === KeyboardEvent.DOM_KEY_LOCATION_LEFT
          setIsButtonPressed({ isLeft, isPressed: isKeyDown })
          break
        default:
      }
    }
    const onKeyDown = (event: KeyboardEvent) => {
      onKeyDownOrUp(event, true)
    }
    const onKeyUp = (event: KeyboardEvent) => {
      onKeyDownOrUp(event, false)
    }
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [isPlaying])

  const onBlastClick = ({
    event,
    isPressed,
    isLeft,
  }: {
    event: MouseEvent
    isPressed: boolean
    isLeft: boolean
  }) => {
    if (isPlaying && !isPressed) {
      blast()
    }
    setIsButtonPressed({ isLeft, isPressed })
    event.preventDefault()
  }
  const onLeftBlastDown = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: true, isLeft: true })
  }
  const onLeftBlastUp = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: false, isLeft: true })
  }
  const onRightBlastDown = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: true, isLeft: false })
  }
  const onRightBlastUp = (event: MouseEvent) => {
    onBlastClick({ event, isPressed: false, isLeft: false })
  }

  const onPlayClick = () => dispatch(togglePlay())
  const onRewindClick = () => rewindToStart()
  const onScrubberInput = (event: ChangeEvent<HTMLInputElement>) =>
    rewindTo(parseFloat(event.target.value))
  const onClearClick = (event: MouseEvent) => (event.altKey ? forget() : clear())
  const onSaveClick = (event: MouseEvent) => {
    if (isEditMode && !event.altKey) {
      dispatch(modalsSlice.actions.toggleSyncModal(true))
    } else {
      dispatch(doSave({ isDownload: event.altKey }))
    }
  }
  const onPrevClick = () => {
    dispatch(loadNextTrack({ isPrevious: true }))
  }
  const onNextClick = () => {
    dispatch(loadNextTrack({}))
  }

  const leftBlastButtonClassName = cx('blastButton', {
    hidden: isVizMode,
    blasting: isLeftBlastButtonPressed,
    mobile: isMobile,
    left: true,
    [`${currentWordRank}1`]: true,
  })
  const rightBlastButtonClassName = cx('blastButton', {
    hidden: isVizMode,
    blasting: isRightBlastButtonPressed,
    mobile: isMobile,
    right: true,
    [`${currentWordRank}1`]: true,
  })
  const clearButtonClassName = cx({ dirty: hasTiming })
  const exportButtonClassName = cx({ dirty: isScoreDirty || isNeedSyncing, hidden: isMobile })
  const isClearButtonEnabled = !isVizMode && (isScoreDirty || hasTiming || isOptionKey) // TODO: test...
  const isExportButtonEnabled = isScoreDirty || isOptionKey || isEditMode
  const playText = isPlaying ? 'Pause' : 'Play'
  const playIcon = (
    <FontAwesomeIcon icon={(isPlaying ? faPause : faPlay) as IconProp} title={playText} />
  )
  // const clockText = '-:-:-';
  // const durationText = '-:-:-'; // TODO: support displaying this while track is loading
  const clockRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = clockRef.current
    if (elem) {
      player.clock = elem
    }
  })
  const scrubberRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = scrubberRef.current
    if (elem) {
      player.scrubber = elem
    }
  })
  const durationRef = createRef<HTMLInputElement>()
  React.useEffect(() => {
    const elem = durationRef.current
    if (elem) {
      player.duration = elem
    }
  })
  const className = cx(PaneClasses[Pane.PLAY_CONTROLS], { hasFocus: isCurrFocus })
  const onClick = () => {
    // console.log(`container: ${PaneClasses[Pane.PLAY_CONTROLS]}`)
    dispatch(activateNextPane({ pane: Pane.PLAY_CONTROLS }))
  }

  return (
    <div className={className} onClick={onClick}>
      <div className="playUi">
        <div className="mobileControls">
          <button
            className={leftBlastButtonClassName}
            onMouseDown={onLeftBlastDown}
            onMouseUp={onLeftBlastUp}
            title={BLAST_BUTTON_TITLE}
            tabIndex={-1}
          />
        </div>
        <div className="playControls">
          <div>
            <input ref={clockRef} type="text" className="timer" readOnly />
            <input
              ref={scrubberRef}
              className="scrubber"
              type="range"
              step="0.2"
              min="0"
              max={trackDuration}
              onChange={onScrubberInput}
              onInput={onScrubberInput}
              disabled={!isTrackLoaded}
            />
            <input ref={durationRef} type="text" className="timer" readOnly />
          </div>
          <div>
            <button onClick={onPrevClick} aria-label="Previous Track" disabled={!isTrackLoaded}>
              <FontAwesomeIcon icon={faBackward as IconProp} title="Previous Track" />
            </button>
            <button
              onClick={onRewindClick}
              aria-label="restart"
              disabled={!isPlaying && clockSeconds === 0}
            >
              <FontAwesomeIcon icon={faUndoAlt as IconProp} title="Rewind to Start" />
            </button>
            <button id="play" aria-label={playText} onClick={onPlayClick} disabled={!isTrackLoaded}>
              {playIcon}
            </button>
            <button onClick={onNextClick} aria-label="Next Track" disabled={!isTrackLoaded}>
              <FontAwesomeIcon icon={faForward as IconProp} title="Next Track" />
            </button>
            {!player.isVizMode && (
              <button
                className={exportButtonClassName}
                onClick={onSaveClick}
                disabled={!isExportButtonEnabled}
              >
                {isOptionKey ? 'Export' : isEditMode ? 'Sync' : 'Save'}
              </button>
            )}
            {!player.isVizMode && (
              <button
                className={clearButtonClassName}
                onClick={onClearClick}
                disabled={!isClearButtonEnabled}
              >
                {isOptionKey ? 'Forget' : 'Clear'}
              </button>
            )}
          </div>
        </div>
        <div className="mobileControls">
          <button
            className={rightBlastButtonClassName}
            onMouseDown={onRightBlastDown}
            onMouseUp={onRightBlastUp}
            title={BLAST_BUTTON_TITLE}
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  )
}

export default PlayControls
