import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AudioData, AudioDataMap, LocalTiming, TimingMap } from '../types'

type LocalAuthoringState = {
  tracks: AudioDataMap
  timings: TimingMap
}
const initialState: LocalAuthoringState = {
  tracks: {},
  timings: {},
}
const localAuthoringSlice = createSlice({
  name: 'localAuthoring',
  initialState,
  reducers: {
    addOrUpdateTrack: (
      state: LocalAuthoringState,
      action: PayloadAction<{ slug: string; trackInfo: AudioData }>
    ) => {
      const { slug, trackInfo } = action.payload
      state.tracks[slug] = trackInfo
    },
    addOrUpdateTiming: (
      state: LocalAuthoringState,
      action: PayloadAction<{ slug: string; timingInfo: LocalTiming }>
    ) => {
      const { slug, timingInfo } = action.payload
      state.timings[slug] = timingInfo
    },
    removeTiming: (state: LocalAuthoringState, action: PayloadAction<string>) => {
      const slug = action.payload
      delete state.timings[slug]
    },
    removeTrack: (state: LocalAuthoringState, action: PayloadAction<string>) => {
      const slug = action.payload
      delete state.tracks[slug]
    },
  },
})

export default localAuthoringSlice
