import { createSlice } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'

const scoreStatusSlice = createSlice({
  name: 'scoreStatus',
  initialState: {},
  reducers: {
    updateScore: (state, action) => {
      const { player, matchSlug, playlistSlug, trackSlug, playStatus } = action.payload
      const newScore = {
        [player]: {
          [matchSlug]: {
            [playlistSlug]: {
              [trackSlug]: playStatus,
            },
          },
        },
      }
      const result = mergeDeepRight(state, newScore)
      return result
    },
  },
})

export default scoreStatusSlice
