import { Section } from '../../types'

const lineToWordsRegExp = /[<[]\d\d:\d\d\.\d\d\d?[>\]]([^<>]*)/g
const wordTimingRegExp = /[<[](\d\d):(\d\d\.\d\d\d?)[>\]]\[?([^[\]]*)\]?/

const importLRC = ({
  lrcLines: lines = [],
  asReference,
  sections,
}: {
  lrcLines: string[]
  asReference: boolean
  sections: Section[]
}) => {
  let numTimedWords = 0
  let sectionIndex = 0
  let sectionLineIndex = -1
  let sectionWordIndex = 0
  const timeKey = asReference ? 'referenceTime' : 'time'
  for (let i = 0; i < lines.length; i++) {
    const rawLine = lines[i]
    const wordMatches = rawLine.match(lineToWordsRegExp)
    if (!wordMatches) {
      // console.log('skipping line ' + (i + 1) + ' [' + rawLine + ']')
      continue
    }

    for (let j = 0; j < wordMatches.length; j++) {
      const wordMatch = wordMatches[j]
      const wordTimingMatch = wordMatch.match(wordTimingRegExp)
      if (!wordTimingMatch) {
        console.log("couldn't parse word timing: " + wordMatch)
        continue
      }
      const wordTime = parseInt(wordTimingMatch[1]) * 60 + parseFloat(wordTimingMatch[2]) // minutes plus fractional seconds
      const word = wordTimingMatch[3].trim()
      if (sectionLineIndex === -1) {
        const sectionName = sections[sectionIndex].label.trim()
        if (sectionName === word) {
          // TODO: fails for multi-word section names?
          sections[sectionIndex][timeKey] = wordTime
          sectionLineIndex = 0
        } else {
          throw new Error('section not found: ' + sectionName)
        }
      } else {
        if (sectionLineIndex >= sections[sectionIndex].lines.length) {
          throw new Error('line not identified')
        }
        if (sectionWordIndex >= sections[sectionIndex].lines[sectionLineIndex].words.length) {
          throw new Error('word not identified')
        }
        const sectionWord =
          sections[sectionIndex].lines[sectionLineIndex].words[sectionWordIndex].label
        if (sectionWord === word) {
          sections[sectionIndex].lines[sectionLineIndex].words[sectionWordIndex][timeKey] = wordTime
          if (sectionWordIndex === 0) {
            sections[sectionIndex].lines[sectionLineIndex][timeKey] = wordTime
          }
          // if (!isReference && wordTime > 0) {
          if (wordTime > 0) {
            numTimedWords++
          }
          sectionWordIndex++
        } else {
          throw new Error(
            'line ' + i + ': word not found: ' + sectionWord + ' // instead found: ' + word
          ) // TODO: handle multi-word phrases defined in LRC?
        }
      }
      if (sectionLineIndex < sections[sectionIndex].lines.length) {
        if (sectionWordIndex >= sections[sectionIndex].lines[sectionLineIndex].words.length) {
          sectionLineIndex++
          sectionWordIndex = 0
        }
      }
      if (sectionLineIndex >= sections[sectionIndex].lines.length) {
        sectionIndex++
        sectionLineIndex = -1
        sectionWordIndex = 0
      }
    }
  }
  return numTimedWords
}

export default importLRC
