import React, { ChangeEvent, useState } from 'react'

import { addOrUpdateGuestPlayer } from '../../../actions/social/leaderboard-actions'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectGuestPlayers } from '../../../selectors/match-selectors'
import { GuestPlayer } from '../../../types'
import Util from '../../../util/util'
import CloseIcon from '../../widgets/CloseIcon'
import { TextInputWithLabel } from '../../widgets/TextInputWithLabel'

type Props = { matchSlug: string; player: GuestPlayer; onClose: () => void }
const PlayerEditModal = ({ matchSlug, player, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const currentGuestPlayers = useAppSelector(selectGuestPlayers(matchSlug))
  const { slug: playerSlug, name } = player
  const [managedPlayerSlug, setManagedPlayerSlug] = useState(playerSlug)
  const [managedPlayerName, setManagedPlayerName] = useState(name)
  const [playerNameStatus, setPlayerNameStatus] = useState('')
  const [isDirty, setIsDirty] = useState(false)
  const addPlayer = () => {
    const playerToUpdate: GuestPlayer = { slug: managedPlayerSlug, name: managedPlayerName }
    dispatch(addOrUpdateGuestPlayer({ compoundSlug: matchSlug, player: playerToUpdate }))
    onClose()
  }
  const isNameAvailable = (newSlug: string, newName: string): string => {
    const isValid = newSlug.length >= 3
    const isInUse =
      newSlug !== playerSlug && Object.keys(currentGuestPlayers).find((slug) => slug !== newSlug)
    const newStatus = !isValid ? 'must be at least 3 characters long' : isInUse ? 'in use' : ''
    return newStatus
  }
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value
    const newSlug = playerSlug || Util.sluggify(newName).toLowerCase()
    const newStatus = isNameAvailable(newSlug, newName)
    setManagedPlayerName(newName)
    setManagedPlayerSlug(newSlug)
    setIsDirty(!newStatus)
    setPlayerNameStatus(newStatus)
  }
  const title = playerSlug ? 'Player Info' : 'New Player'
  return (
    <div className="modalContainer nameChangeModal playerEditModal">
      <CloseIcon onClose={onClose} />
      <div className="modalHeading">{title}</div>
      <TextInputWithLabel
        id="blasterId"
        label="Player Id"
        value={managedPlayerSlug}
        isReadOnly={true}
      />
      <TextInputWithLabel
        id="blasterName"
        label="Player Name"
        value={managedPlayerName}
        onChange={onChange}
        onBlur={onChange}
      />
      <button disabled={!isDirty} onClick={addPlayer}>
        {playerSlug ? 'Update' : 'Create'}
      </button>
      <div className="status">{playerNameStatus}</div>
    </div>
  )
}

export default PlayerEditModal
