import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, createRef, KeyboardEvent, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {
  confirmUsersExist,
  createOrUpdateMatchInvite,
  deleteMatchInvite,
  inviteUsersToMatch,
} from '../../actions/social/leaderboard-actions'
import { useAppDispatch } from '../../hooks'
import { MinimalInviteInfo } from '../../types'
import Util from '../../util/util'
import CloseIcon from '../widgets/CloseIcon'
import {
  ControlledTextInputWithLabel,
  DivWithLabel,
  SelectWithLabel,
  TextInputWithLabel,
} from '../widgets/TextInputWithLabel'

const placeholder = 'Add usernames separated by spaces, e.g. "@user1 @user2".'
const inviteValidHoursMenu = [
  { value: '1', name: '1 hour' },
  { value: '24', name: '1 day' },
  { value: `${24 * 7}`, name: '1 week' },
  { value: `${24 * 7 * 30}`, name: '1 month' },
  { value: `${24 * 7 * 30 * 6}`, name: '6 months' },
]
const inviteExtensionMenu = [{ value: '0', name: ' ' }, ...inviteValidHoursMenu]
const getExpirationMillis = (validForHours: number) => Date.now() + validForHours * 60 * 60 * 1000
const defaultPrompt = 'the dog ate my lyrics...'
type Props = {
  onClose: () => void
  currUserId: string
  matchOwner: string
  matchSlug: string
  existingInvite: MinimalInviteInfo | null
}
const PlayerInviteModal = ({
  onClose,
  currUserId,
  matchOwner,
  matchSlug,
  existingInvite,
}: Props) => {
  const {
    slug: initialInviteKey,
    expires = -1,
    created = Date.now(),
    prompt = defaultPrompt,
  } = existingInvite || {}
  const [inviteKey, setInviteKey] = useState(initialInviteKey || '')
  const isNew = expires < 0
  const isExpired = !isNew && expires < Date.now()
  const defaultMenuIndex = isNew ? 2 : 0
  const inviteHoursMenu = isNew ? inviteValidHoursMenu : inviteExtensionMenu
  const defaultHoursValue = parseInt(inviteHoursMenu[defaultMenuIndex].value)
  const fullInviteKey = `${matchOwner}-${matchSlug}-${inviteKey}`
  const dispatch = useAppDispatch()
  const [invitees, setInvitees] = useState<string[]>([])
  const [inviteError, setInviteError] = useState('')
  const [isCopied, setIsCopied] = useState<boolean>()
  const [inviteValidForHours, setInviteValidForHours] = useState(defaultHoursValue)
  const [inviteExpiration, setInviteExpiration] = useState(
    isNew ? getExpirationMillis(defaultHoursValue) : expires
  )
  const expirationDate = Util.formattedTimeStamp(inviteExpiration)
  const [isInviteModified, setIsInviteModified] = useState(isNew)
  const inviteListRef = createRef<HTMLTextAreaElement>()
  React.useEffect(() => {
    const inviteListElem = inviteListRef.current
    if (inviteListElem) {
      inviteListElem.focus()
    }
  })

  const onValidForChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newValidForHours = parseInt(event.target.value)
    const expirationMillis = getExpirationMillis(newValidForHours)
    setInviteExpiration(expirationMillis)
    setInviteValidForHours(newValidForHours)
    setIsInviteModified(true) // TODO: unless restored to original?
  }

  const onCreateOrUpdateInvite = () => {
    const slug = inviteKey || Util.generateId()
    const inviteInfo: MinimalInviteInfo = {
      slug,
      created,
      expires: inviteExpiration,
      prompt,
      // TODO: add invitees?
    }
    dispatch(createOrUpdateMatchInvite({ matchSlug, inviteInfo }))
    setIsInviteModified(false)
    setInviteKey(slug)
  }
  const onInvite = async () => {
    const confirmationMap = await dispatch(confirmUsersExist(invitees)).unwrap()
    const missingUsers = invitees.filter((invitee) => {
      const isCurrentUser = invitee === currUserId
      const exists = confirmationMap[invitee]
      return isCurrentUser || !exists // TODO: prevents accidental send to self, but fix messaging
    })
    if (missingUsers.length) {
      const missingUsersText = missingUsers.join(' ')
      setInviteError(missingUsersText)
      return
    }
    const inviteInfo = {
      slug: inviteKey,
      created,
      expires: inviteExpiration,
      prompt,
      // TODO: add invitees?
    }
    dispatch(
      inviteUsersToMatch({
        inviteInfo,
        matchOwner,
        matchSlug,
        invitees,
      })
    )
    onClose()
  }
  const onCopy = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }
  const EditSection = () => {
    const onDelete = () => {
      dispatch(deleteMatchInvite({ matchSlug, inviteSlug: inviteKey }))
      // TODO: handle error?
      onClose()
    }
    const canEdit = currUserId === matchOwner
    const getEditButtons = () => {
      return (
        <div>
          <button disabled={!isInviteModified} onClick={onCreateOrUpdateInvite}>
            Update
          </button>
          <button onClick={onDelete}>Delete</button>
        </div>
      )
    }
    const getKeySection = () => {
      const getCreateButton = () => (
        <button className="chatNewTrackButton" onClick={onCreateOrUpdateInvite}>
          Create
        </button>
      )
      const getCopyWidget = () => (
        <>
          <CopyToClipboard text={fullInviteKey} onCopy={onCopy}>
            <button className="copy">
              <FontAwesomeIcon icon={faCopy as IconProp} title="Copy Invitation Code" />
            </button>
          </CopyToClipboard>
          {isCopied && <div className="copyConfirmation">copied!</div>}
        </>
      )
      return (
        <div className="link">
          {isNew && !inviteKey ? (
            <DivWithLabel label="Code" children={getCreateButton()} />
          ) : (
            <TextInputWithLabel id="matchUrl" label="Code" value={fullInviteKey} isReadOnly />
          )}
          {!isInviteModified && getCopyWidget()}
        </div>
      )
    }
    return (
      <div className="editSection">
        <ControlledTextInputWithLabel
          id="expires"
          label={`Expire${isExpired && !inviteValidForHours ? 'd' : 's'}`}
          value={expirationDate}
          isReadOnly
        />
        {canEdit && (
          <SelectWithLabel
            id="validFor"
            label={`${isExpired ? 'Extend' : 'Valid'} for`}
            itemArray={inviteHoursMenu}
            value={inviteValidForHours}
            onChange={onValidForChanged}
          />
        )}
        {getKeySection()}
        {canEdit && !isNew && getEditButtons()}
      </div>
    )
  }
  const DirectInviteSection = () => {
    const onInviteesChanged = (
      event: ChangeEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLTextAreaElement>
    ) => {
      const rawInvitees = inviteListRef.current?.value
      setInvitees(rawInvitees ? rawInvitees.split(' ') : [])
      event.stopPropagation()
    }
    const isSendDisabled = !invitees.length || !inviteKey
    return (
      <div className="directInviteSection">
        <label htmlFor="inviteList">'Blaster In-App Invite</label>
        <textarea
          id="inviteList"
          ref={inviteListRef}
          rows={4}
          onBlur={onInviteesChanged}
          onKeyDown={onInviteesChanged}
          placeholder={placeholder}
        />
        <button disabled={isSendDisabled} onClick={onInvite}>
          Send
        </button>
        {inviteError && (
          <div className="inviteError">
            Please correct the following invitees:&nbsp;<span>{inviteError}</span>
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="modalContainer playerInviteModal">
      <CloseIcon onClose={onClose} />
      <div className="modalHeading">Match Invitation</div>
      <div className="modalBody">
        {EditSection()}
        {DirectInviteSection()}
      </div>
    </div>
  )
}

export default PlayerInviteModal
