import React from 'react'

import { Rank } from '../../constants/constants'
import { useAppSelector } from '../../hooks'
import { ScoreProgressBar } from './ProgressBar'
import { selectCurrentScore } from '../../selectors/score-status-selectors'

const ScoreView = () => {
  const playStatus = useAppSelector(selectCurrentScore)
  const { counters } = playStatus
  const isShowBest = false // TODO: decide when/if to turn on
  return (
    <div className="scoreView">
      <ScoreProgressBar
        scoreInfo={playStatus}
        isHeader={true}
        isShowRank={true}
        isUseTopScore={false}
      />
      <div className="scoreCountersWrapper">
        {Object.values(Rank).map((rank) => {
          const key = `counter-${rank}`
          return (
            <div key={key}>
              <input className={rank} type="text" tabIndex={-1} readOnly value={counters[rank]} />
            </div>
          )
        })}
      </div>
      {isShowBest && (
        <div className="topScore">
          <div>best</div>
          <ScoreProgressBar
            scoreInfo={playStatus}
            isUseTopScore={true}
            isShowRank={true}
            isActive={false}
            isMini
          />
        </div>
      )}
    </div>
  )
}

export default ScoreView
