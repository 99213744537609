import getState from '../../reducers/'
import AnimatingVizVisitor from './AnimatingVizVisitor'
import { Line, ModelPart, Point, Section, VizVisitorBaseProps, Word } from '../../types'

class PolarVisitorBase extends AnimatingVizVisitor {
  degrees: number
  theta: number

  constructor(props: VizVisitorBaseProps) {
    super(props)
    this.degrees = 0
    this.theta = 0
  }

  tick(part?: ModelPart) {
    super.tick(part)
  }

  vizWillUpdate(offset: Point) {
    super.vizWillUpdate(offset)
    this.degrees = 0
  }

  getRadius() {
    return 1
  }

  visitSection(section: Section) {
    super.visitSection(section)
  }

  visitLine(line: Line, section: Section) {
    super.visitLine(line, section)
  }

  visitWord(word: Word, line: Line, section: Section) {
    this.degrees += this.step
    this.theta = (this.degrees * Math.PI) / 180
    const r = this.getRadius()
    const x = -this.zoom * r * Math.cos(this.theta) + this.offset.x
    const y = -this.zoom * r * Math.sin(this.theta) + this.offset.y

    if (word.elem) {
      word.elem.style.top = y + 'px'
      word.elem.style.left = x + 'px'
      // word.elem.style.transform = 'rotate(15deg)';
      word.elem.style.zIndex = String(this.degrees % 360)
    }
    this.setWordColor(word, line, section)
  }
}
type FlowerVisitorProps = VizVisitorBaseProps & {
  thetaMultiplier: number
  exponent: number
}

class FlowerVisitor extends PolarVisitorBase {
  thetaMultiplier: number
  exponent: number
  constructor(props: FlowerVisitorProps) {
    super(props)

    this.thetaMultiplier = props.thetaMultiplier
    this.exponent = props.exponent

    this.controls.petals = {
      type: 'text',
      label: 'Petals',
      initialValue: this.thetaMultiplier * 2,
      step: 1,
      min: 1,
      max: 20,
    }

    this.controls.xFactor = {
      type: 'text',
      label: 'X Factor',
      initialValue: this.exponent * 1000,
      step: 10,
      min: 8000,
      max: 10000,
    }
  }

  vizWillUpdate(offset: Point) {
    super.vizWillUpdate(offset)
    const {
      xFactor: { initialValue: xFactor },
      petals: { initialValue: petals },
    } = getState().vizParams.controls
    this.exponent = (xFactor as number) / 1000
    this.thetaMultiplier = (petals as number) / 2
  }

  tick(part?: ModelPart) {
    super.tick(part)
    const { xFactor: xFactorControl, petals: petalsControl } = getState().vizParams.controls
    {
      const { max, step, isAnimateEnabled } = xFactorControl
      if (isAnimateEnabled) {
        this.exponent += (step as number) / 1000

        if (Math.abs(this.exponent) >= (max as number) / 1000) {
          this.updateControlStep('xFactor', -step)
        }
        this.updateControlValue('xFactor', this.exponent * 1000)
      }
    }
    {
      const { max, step, isAnimateEnabled } = petalsControl
      if (isAnimateEnabled) {
        this.thetaMultiplier += (step as number) / 2

        if (Math.abs(this.thetaMultiplier) >= (max as number) / 2) {
          this.updateControlStep('petals', -step)
        }
        this.updateControlValue('step', this.thetaMultiplier * 2)
      }
    }
  }

  getRadius() {
    return Math.pow(Math.cos(this.thetaMultiplier * Math.pow(this.theta, this.exponent)), 2)
    // return Math.pow(Math.cos(this.thetaMultiplier * this.theta), this.exponent);
  }
}

class SpiralVisitor extends PolarVisitorBase {
  getRadius() {
    return this.theta
  }
}

export { FlowerVisitor, SpiralVisitor }
