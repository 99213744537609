import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import modalsSlice from '../../reducers/modalsSlice'
import {
  selectCurrentLyrics,
  selectCurrentTrackSlug,
  selectDirtyState,
} from '../../selectors/current-play-selectors'
import vizBuilder from '../../services/LyricVizBuilder'
import player from '../../services/Player'
import { updateLocalLyrics, updateLocalTiming } from './update-local-track'

const doSplit = createAsyncThunk<
  void,
  { isNeedUserInput: boolean },
  { state: RootState; dispatch: AppDispatch }
>('load/doSplit', ({ isNeedUserInput }, { dispatch, getState }) => {
  player.stop()
  dispatch(currentPlaySlice.actions.setIsPlayable(false))
  dispatch(currentPlaySlice.actions.setLRClines(null))
  player.initUI(!isNeedUserInput)
  player.recalc()

  if (isNeedUserInput) {
    dispatch(modalsSlice.actions.toggleTrackInfo(true))
  } else {
    const state = getState()
    const trackSlug = selectCurrentTrackSlug(state) // TODO: might be empty if no song picked. FIX: disallow, or get from lyrics?
    const lyrics = selectCurrentLyrics(state)
    const { isLyricsDirty } = selectDirtyState(state)
    const { wordCount, timedWordCount: prevTimedWordCount } = vizBuilder
    dispatch(updateLocalLyrics(trackSlug, lyrics, wordCount, isLyricsDirty))
    const { currentLRC } = player
    const { timedWordCount: currTimedWordCount } = vizBuilder
    if (prevTimedWordCount || currTimedWordCount) {
      player.timedWordCount = currTimedWordCount
      const timingLines = currentLRC.split(/[\r\n]+/g)
      dispatch(currentPlaySlice.actions.setIsPlayable(true))
      dispatch(currentPlaySlice.actions.setLRClines(timingLines))
      dispatch(updateLocalTiming(trackSlug, currentLRC, currTimedWordCount))
    }
  }
})

export default doSplit
