import { createAsyncThunk } from '@reduxjs/toolkit'

import { DEFAULT_LYRICS, defaultDirtyState, Pane } from '../../constants/constants'
import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import focusSlice from '../../reducers/focusSlice'
import {
  selectLocalTrack,
  selectLocalTrackInfoForState,
} from '../../selectors/local-authoring-selectors'
import {
  selectCurrentBlaster,
  selectIsCurrentOwnedMatch,
  selectPrefs,
} from '../../selectors/session-selectors'
import { isMobile } from '../../util/track-utils'
import switchUserMode from '../switchUserMode'
import { localAudioSwitched } from '../authoring/update-local-track'
import { ModeType, TrackInfo } from '../../types'
import doSplit from '../authoring/doSplit'
import { switchActiveBlaster } from '../social/leaderboard-actions'

const trackLoaded = createAsyncThunk<
  void,
  {
    trackInfo: TrackInfo
    txt?: string
    timing?: string
    isLocal?: boolean
    isNew?: boolean
  },
  { state: RootState; dispatch: AppDispatch }
>(
  'load/trackLoaded',
  ({ trackInfo, isLocal = false, txt = '', timing, isNew = false }, { dispatch, getState }) => {
    return new Promise<void>((resolve) => {
      // TODO: why a promise?
      const state = getState()
      const currentBlaster = selectCurrentBlaster(state)
      const isCurrentOwnedMatch = selectIsCurrentOwnedMatch(state)
      const isEditable: boolean = isNew || (isCurrentOwnedMatch && isLocal && !isMobile())
      const getMode = () => {
        if (isNew) {
          return 'edit'
        }
        const { defaultMode } = selectPrefs(state)
        const [modePref, lastMode] = defaultMode.split('-')
        // @ts-ignore
        const requestedMode: ModeType = modePref === 'last' ? lastMode : modePref
        const isEditRequest = requestedMode === 'edit' || !timing
        return isEditRequest && !isEditable ? 'play' : requestedMode
      }
      const mode = getMode()
      dispatch(switchUserMode(mode)).then(() => {
        const { slug } = trackInfo
        let currentPlayTrackInfo = trackInfo
        let lyrics = txt
        let dirtyState = defaultDirtyState
        let isNeedUserInput = false
        if (isLocal) {
          const localTrack = slug ? selectLocalTrack(slug)(state) : null
          if (localTrack) {
            currentPlayTrackInfo = selectLocalTrackInfoForState(slug)(state)
            const {
              remotePath,
              localPath,
              lyrics: localLyrics = '',
              dirtyState: localDirtyState,
            } = localTrack
            lyrics = localLyrics
            dirtyState = localDirtyState || defaultDirtyState
            if (remotePath) {
              dispatch(localAudioSwitched({ remoteURL: remotePath, isInitialLoad: true }))
            } else if (localPath) {
              // TODO: local path support for desktop
            } else {
              isNeedUserInput = mode === 'edit'
            }
          } else {
            isNeedUserInput = true
            lyrics = DEFAULT_LYRICS
          }
        }
        dispatch(currentPlaySlice.actions.setDirtyState(dirtyState))
        dispatch(currentPlaySlice.actions.setIsEditable(isEditable))
        dispatch(currentPlaySlice.actions.setTrackInfo(currentPlayTrackInfo))
        dispatch(currentPlaySlice.actions.setLyrics(lyrics))
        dispatch(
          switchActiveBlaster({ newBlasterSlug: currentBlaster, newlyLoadedTrackInfo: trackInfo })
        )
        if (isLocal) {
          dispatch(doSplit({ isNeedUserInput }))
        }
        dispatch(focusSlice.actions.setFocus(Pane.PLAY))
        resolve()
      })
    })
  }
)

export default trackLoaded
