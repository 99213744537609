import React, { ChangeEvent } from 'react'
import ModalBackdrop from './ModalBackdrop'

import { RELEASE_NAME, TERMS_OF_USE_URL } from '../../constants/constants'
import UserManager from '../../services/UserManager'

class TermsOfUseModal extends React.Component {
  state = { userHasAgreedToTermsOfUse: false }
  componentDidMount() {
    this.setState({ userHasAgreedToTermsOfUse: UserManager.userHasAgreedToTermsOfUse })
  }

  _updateSettings(event: ChangeEvent<HTMLInputElement>) {
    const userHasAgreed = event.target.checked
    UserManager.termsOfUse = userHasAgreed
    this.setState({ userHasAgreedToTermsOfUse: userHasAgreed })
  }

  render() {
    const { userHasAgreedToTermsOfUse } = this.state

    return (
      <ModalBackdrop>
        <div className="termsOfUseModal">
          <div className="modalHeading">Welcome!</div>
          <p>
            Please read and agree to {RELEASE_NAME}'s alpha program terms of use before continuing!
          </p>
          <iframe title="terms" src={TERMS_OF_USE_URL} />
          <div className="modalFooter">
            <div>
              <div>
                <input
                  type="checkbox"
                  id="agree-to-terms"
                  checked={userHasAgreedToTermsOfUse}
                  onChange={this._updateSettings.bind(this)}
                />
                <label htmlFor="agree-to-terms">I have read and agree</label>
              </div>
            </div>
          </div>
        </div>
      </ModalBackdrop>
    )
  }
}

export default TermsOfUseModal
