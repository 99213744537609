import React, { ChangeEvent, KeyboardEvent } from 'react'

import { useAppSelector, useAppDispatch } from '../../hooks'
import vizParametersSlice from '../../reducers/vizParametersSlice'
import { incrementingKeyHandler } from '../../util/key-handlers'
import CloseIcon from '../widgets/CloseIcon'

type Props = {
  onClose: () => void
}
const VizParamModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch()
  const controlKey = useAppSelector((state) => state.vizParams.activeControlKey)
  const control = useAppSelector((state) => state.vizParams.controls[controlKey])
  if (!control) {
    return null
  }
  const { label, step, min, max, isAnimateEnabled, isAnimateRandom } = control
  const escapeAction = () => dispatch(vizParametersSlice.actions.setActiveControlKey(null))
  const controlParamUpdated = (paramKey: string, value: string | boolean | number) => {
    dispatch(vizParametersSlice.actions.updateControlParameter({ controlKey, paramKey, value }))
  }
  const updateCheckboxParam = (event: ChangeEvent<HTMLInputElement>, paramKey: string) => {
    const value = event.target.checked
    controlParamUpdated(paramKey, value)
  }
  const updateFloatParam = (event: ChangeEvent<HTMLInputElement>, paramKey: string) => {
    const value = parseFloat(event.currentTarget.value)
    if (!isNaN(value)) {
      controlParamUpdated(paramKey, value)
    }
  }
  const updateIsAnimateEnabled = (event: ChangeEvent<HTMLInputElement>) => {
    updateCheckboxParam(event, 'isAnimateEnabled')
  }
  const updateIsAnimateRandom = (event: ChangeEvent<HTMLInputElement>) => {
    updateCheckboxParam(event, 'isAnimateRandom')
  }
  const updateParamStep = (event: ChangeEvent<HTMLInputElement>) => {
    updateFloatParam(event, 'step')
  }
  const updateParamMin = (event: ChangeEvent<HTMLInputElement>) => {
    updateFloatParam(event, 'min')
  }
  const updateParamMax = (event: ChangeEvent<HTMLInputElement>) => {
    updateFloatParam(event, 'max')
  }
  const onStepKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    const updateControlValue = (value: number) => {
      controlParamUpdated('step', value)
    }
    incrementingKeyHandler(event, updateControlValue, escapeAction)
  }
  const onMinKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    const updateControlValue = (value: number) => {
      controlParamUpdated('min', value)
    }
    incrementingKeyHandler(event, updateControlValue, escapeAction)
  }
  const onMaxKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    const updateControlValue = (value: number) => {
      controlParamUpdated('max', value)
    }
    incrementingKeyHandler(event, updateControlValue, escapeAction)
  }
  return (
    <div id="vizParamSettingsContainer" className="vizParamSettings">
      <CloseIcon onClose={onClose} isMini />
      <fieldset>
        <legend>
          <input type="checkbox" checked={isAnimateEnabled} onChange={updateIsAnimateEnabled} />
          Animate <span className="paramName">{label}</span>
        </legend>
        <div className="viz-param-settings">
          Random:{' '}
          <input type="checkbox" checked={isAnimateRandom} onChange={updateIsAnimateRandom} />
          Step:{' '}
          <input type="text" value={step} onChange={updateParamStep} onKeyDown={onStepKeydown} />
          Min: <input type="text" value={min} onChange={updateParamMin} onKeyDown={onMinKeydown} />
          Max: <input type="text" value={max} onChange={updateParamMax} onKeyDown={onMaxKeydown} />
        </div>
      </fieldset>
    </div>
  )
}

export default VizParamModal
